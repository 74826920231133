import React from 'react'
import Text from '../textStyling/Text';
import TestCheckbox from './TestCheckbox';
import {QuestionState} from '../../screens/TestScreen';

interface Props {
    id: string;
    question: string;
    questionState: QuestionState;
    handleChange: (id: string, value: 1 | -1) => void;
}

const TestQuestion: React.FC<Props> = ({question, id, questionState, handleChange}) => (
    <>
        {/*Mobile*/}
        <div className="lg:hidden xl:hidden">
            <div className="flex flex-row justify-between mb-8 text-white items-center">
                <div className="mr-6 items-center">
                    <Text color="text-white" fat>{question}</Text>
                </div>
                <div className="flex justify-between items-center">
                    <TestCheckbox
                        className="mr-3"
                        value={questionState[id] === 1}
                        setValue={() => handleChange(id, 1)}
                    />
                    <TestCheckbox negative value={questionState[id] === -1}
                                  setValue={() => handleChange(id, -1)}
                    />
                </div>
            </div>
        </div>
        {/*Desktop*/}
        <div
            className="hidden lg:flex xl:flex lg:flex-row xl:flex-row lg:justify-between xl:justify-between lg:mb-8 xl:mb-8 lg:text-white xl:text-white lg:items-center xl:items-center">
            <div className="mr-6 items-center w-2/3">
                <Text color="text-white" two>{question}</Text>
            </div>
            <div className="flex justify-between items-center">
                <TestCheckbox
                    className="mr-8"
                    value={questionState[id] === 1}
                    setValue={() => handleChange(id, 1)}
                />
                <TestCheckbox negative value={questionState[id] === -1}
                              setValue={() => handleChange(id, -1)}
                />
            </div>
        </div>
    </>
)

export default TestQuestion;