//React
  import React, { Fragment, useState, useEffect } from 'react'
//Hooks 
//Components, Icons and Props
import CustomMenu from '../layout/Menu/CustomMenu'
import Footer from "../layout/FooterNew"
import BreadcrumbNavigation from '../layout/Menu/BreadcrumbNavigation'
import { INavigation, INavigationMainItem, setMainMenuIdActive, MAIN_MENU_IDS } from "../layout/Menu/CustomMenu"
import MatchingComparison from "../layout/MatchingComparison"
import { MailIcon, QuestionMarkCircleIcon, ChevronLeftIcon, CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import useCollapse from 'react-collapsed'
import { useParams } from "react-router"
import { IUserData } from '../../hooks/useUpdateProfile'
import { UseGetProfile } from '../../hooks/useGetProfile'
import { UseGetMatchingEmployee } from '../../hooks/useGetMatchingEmployee'
import { useUserContext } from '../../firebase/FirebaseContext'
import { transformToDataObject, ICompetenceData } from '../../helpers/transform'
import LoadingScreenNew from "../../components/layout/LoadingScreenNew"
import { Link } from 'react-router-dom';
import { EStatus } from '../../hooks/useGetProfiles'
import { IMatchingEmployee } from '../../hooks/useGetMatchingEmployees'
import { scoreEmployees } from '../../helpers/scoreEmployees'
//useTranslation
import { useTranslation } from "react-i18next"
import SiteNotFoundScreen from "../../screens/SiteNotFoundScreen"
import { Timestamp } from 'firebase/firestore'
import { Listbox, Transition } from '@headlessui/react'
import { useChangeEmployeeStatus } from '../../hooks/useChangeEmployeeStatus'


const CompanyMatchingComparisonView: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  const { t, i18n } = useTranslation(['companyMatching', 'departments']);
  interface ParamTypes {
    uid: string,
    profileid: string
  }

  const changeEmployeeStatus = useChangeEmployeeStatus()

  const publishingOptions = [
    { id: EStatus.NOTHING, name: t('companyRecruitingJobProfile:candidateStatus.NOTHING'), current: false },
    { id: EStatus.PRE_SELECTED, name: t('companyRecruitingJobProfile:candidateStatus.PRE_SELECTED'), current: false },
    { id: EStatus.INTERVIEW, name: t('companyRecruitingJobProfile:candidateStatus.INTERVIEW'), current: false },
    { id: EStatus.SELECTED, name: t('companyRecruitingJobProfile:candidateStatus.SELECTED'), current: false },
    { id: EStatus.REJECTED, name: t('companyRecruitingJobProfile:candidateStatus.REJECTED'), current: false },
  ]

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  const { profileid, uid } = useParams<ParamTypes>()


  const {companyUser} = useUserContext()

  let testGroups = companyUser!.testGroups

  const [profileCompetenceData, setProfileCompetenceData] = useState<IUserData>({})
  const [userCompetenceData, setUserCompetenceData] = useState<IUserData>({})
  const [userName, setUserName] = useState('')
  const [userAdditionalInfos, setUserAdditionalInfos] = useState('')
  const [profileName, setProfileName] = useState('')
  const [score, setScore] = useState('')
  const [userMail, setUserMail] = useState<string>('')

  const [selected, setSelected] = useState({name: '', current: true })

  const handleChangeEmployeeStatus = (e: any) => {
    setSelected(e)
    
    if(employee) {
      if(employee.status === e.id)
        return

      let newStatus = { date: new Timestamp(Math.floor(Number(Date.now()) / 1000), 0), status: e.id }
      employee.statusArr.push(newStatus)
      employee.status = e.id
      changeEmployeeStatus(employee.id, newStatus, profileid)
      //console.log(employee.id, newStatus, profileid)

    }
  }

  const [employee, setEmployee] = useState<IMatchingEmployee | undefined>()

  const { resultLocalesStandard } = useUserContext()
  const standardLanguage = i18n.language.includes('de') ? 'standardDE' : 'standardEN'

  let competenceData: ICompetenceData | null = null;

  if(resultLocalesStandard) {
    competenceData = transformToDataObject(resultLocalesStandard[standardLanguage])
  }

  //console.log(competenceData)

  useEffect(() => {
    const requestProfile = async () => {
      try {
        const getProfile = UseGetProfile()
        const getAllMatchingEmployee = UseGetMatchingEmployee()
        let profile = await getProfile(profileid)
        let matchEmployee = await getAllMatchingEmployee(uid, testGroups)
        let profileOption = publishingOptions.find(element => element.id === matchEmployee.status)
        if(profileOption)
          setSelected(profileOption!)
        else
          setSelected(publishingOptions[0])
        if(profile && matchEmployee) {
          setProfileName(profile.name)
          setProfileCompetenceData(profile.data)
          setUserCompetenceData(matchEmployee.data)
          setUserName(matchEmployee.firstName+' '+matchEmployee.lastName)
          if(matchEmployee.department && matchEmployee.jobTitle) {
            setUserAdditionalInfos(matchEmployee?.jobTitle +' | '+ t('departments:'+String(matchEmployee.department)))
          }
          setEmployee(matchEmployee)
          setUserMail(String(matchEmployee.email))
          //console.log('matchEmployee', matchEmployee)
          let scoredEmployee = scoreEmployees(profile, [matchEmployee])
          setScore(scoredEmployee[0].score)
        }
      } catch (err) {
        console.log(err)
      }
    }
    requestProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profileid, uid, testGroups, t])

  setMainMenuIdActive(mainNavItems, MAIN_MENU_IDS.DASHBOARD_COMPANY)

  const breadCrumbHome = {
  
    name: 'home',
    href: '/company/matching',
    current: false
  }

  const breadCrumbPaths: INavigationMainItem[] = [
    {
      name: profileName,
      href: '/company/matching/'+profileid,
      current: true
    },
    {
      name: t('comparison.headlineBreadcrumbTwo'),
      href: '/company/matching/comparison/'+profileid+'/'+uid,
      current: true
    }
  ]

const [ isExpanded, setExpanded ] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
  function handleOnClick() {
      setExpanded(!isExpanded);
  }

  if(companyUser && companyUser!.empoloyeeAccess !== true) {
    return (
      <>
        <SiteNotFoundScreen />
      </>
    )
  }
  
  return competenceData ? (
    <>
      <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
      <div className="py-10">
        <header className="mt-16">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="pt-2 pb-4">
              <BreadcrumbNavigation homePath={breadCrumbHome} currentPaths={breadCrumbPaths}></BreadcrumbNavigation>
            </div>
            <div className="grid grid-cols-2">
              <div className='flex items-center pb-2'>
                <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal pb-2">{userName}</h1>
                <p className="text-2xl text-uw-teal font-medium"></p>
              </div>
            <div className="mt-5 flex xl:mt-0 xl:ml-4 place-content-end">


            <span className="hidden sm:block ml-3">
              <a
                href={'mailto:'+userMail+'?subject=Job '+profileName}>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <MailIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  {t('companyRecruitingIndividualComparison:button')}
                </button>
              </a>
              </span>

            <div className="sm:ml-3 relative">
                <Listbox value={selected} onChange={handleChangeEmployeeStatus}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="sr-only">Change published status</Listbox.Label>
                      <div className="relative">
                        <div className="inline-flex shadow-sm rounded-md divide-x divide-white">
                          <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-white">
                            <div className="relative inline-flex items-center bg-uw-teal py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              <p className="ml-2.5 text-sm font-medium">{selected.name}</p>
                            </div>
                            <Listbox.Button className="relative inline-flex items-center bg-uw-teal p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-gray-600">
                              <span className="sr-only">Change published status</span>
                              <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                            </Listbox.Button>
                          </div>
                        </div>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="origin-top-right absolute left-0 mt-2 -mr-1 z-10 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0">
                            {publishingOptions.map((option) => (
                              <Listbox.Option
                                key={option.name}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'text-white bg-uw-teal' : 'text-gray-900',
                                    'cursor-default select-none relative p-4 text-sm'
                                  )
                                }
                                value={option}
                              >
                                {({ selected, active }) => (
                                  <div className="flex flex-col">
                                    <div className="flex justify-between">
                                      <p className={selected ? 'font-semibold' : 'font-normal'}>{option.name}</p>
                                      {selected ? (
                                        <span className={active ? 'text-white' : 'text-uw-teal'}>
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </div>
                                    
                                  </div>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
              </div>
            

            
              
            </div>
          </div>
        </header>
        <main>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

          <div className="grid-span-3 grid grid-cols-10">
                <div className="flex items-center text-sm text-gray-500 col-span-2">
               <p className="text-sm text-gray-600">{userAdditionalInfos}</p>
              </div>
            </div>


            <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
              <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                <div className="ml-4 mt-2">
                </div>
              </div>
            </div>

            <div className="relative">
              <div className="absolute right-0 mr-9 text-2xl text-uw-teal font-medium box-border divide-x divide-uw-teal rounded-b-lg px-4 py-4 bg-gray-100">
              { score } %
              </div>
            </div>

            <div className='flex items-center mt-10'>
              <h2 className="lg:text-2xl text-xl font-medium leading-tight text-uw-teal pb-2">{t('companyRecruitingIndividualComparison:headline')}</h2>
              <div {...getToggleProps({onClick: handleOnClick})} className="flex justify-start pl-2">
                <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400 mb-2" aria-hidden="true" />
              </div>
            </div>
            <div {...getCollapseProps()}>
              <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3">
                <div className="flex text-md text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">
                    {t('comparison.infoText')}
                  </span>
                </div>
              </div>
            </div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[2].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[25]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[2]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[35]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[5].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[19]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[6]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[38]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[29]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[7].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[27]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[20]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[3].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[9]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[3]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[37]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[31]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[26]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[21]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[17]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[13]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[15]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[4].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[4]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[36]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[5]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[30]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[23]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[34]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[8].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[10]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[33]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[28]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[24]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[14]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[12]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[6].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[7]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[39]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[8]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[1].name}</h3>
            </div>
              <MatchingComparison competenceData={competenceData.trait[1]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[32]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[22]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[18]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[16]} userData={userCompetenceData} profileData={profileCompetenceData} />
              <MatchingComparison competenceData={competenceData.trait[11]} userData={userCompetenceData} profileData={profileCompetenceData} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pt-8"> 
              <Link to={'/company/matching/'+profileid}>
                <button
                  type="button"
                  className="items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-bg-teal bg-white hover:bg-gray-200 focus:outline-none">
                  <ChevronLeftIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                </button>
              </Link>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  ) : (<><LoadingScreenNew /></>)
}

export default CompanyMatchingComparisonView