import React from 'react'
import CompanyIndividualComparisonView from "../components/companyDashbaord/CompanyIndividualComparisonView"
import { INavigation } from "../components/layout/Menu/CustomMenu"


const CompanyIndividualComparison: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  return (
    <>
      <CompanyIndividualComparisonView mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
    </>
  )
}

export default CompanyIndividualComparison