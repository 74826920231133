import React from 'react'
import classnames from 'classnames'

interface Props {
    backgroundWhite?: boolean
    borderColor?: boolean
    className?: string
    disabled?: boolean
    onClick?: any
    title: string
    testSize?: boolean
    type: "submit" | "button"
}

const Button: React.FC<Props> = ({backgroundWhite, borderColor, className, disabled, onClick, title, testSize, type}) => {

    const baseStyle = "text-center py-3 rounded-full cursor-pointer"
    const buttonSize = testSize ? "w-180px" : "w-48 lg:w-250px xl:w-250px"

    return (
        type === "button" ?
            <button {...{onClick}} className={classnames(
                baseStyle, buttonSize, className, 'font-text leading-button text-button font-medium shadow-contactButton',
                {
                    "bg-white text-uw-teal": backgroundWhite && !borderColor
                },
                {
                    "bg-white border-1.5 border-uw-teal text-uw-teal": backgroundWhite && borderColor
                },
                {
                    "bg-uw-teal text-white": !backgroundWhite
                },
                {
                    "cursor-default": disabled
                }
            )} disabled={disabled} type={type}>{title} </button>
            :
            <input
                {...{onClick}}
                className={classnames(
                    baseStyle, buttonSize, className, 'font-text leading-button text-button font-medium shadow-contactButton',
                    {
                        "bg-white text-uw-teal": backgroundWhite && !borderColor
                    },
                    {
                        "bg-white border-1.5 border-uw-teal text-uw-teal": backgroundWhite && borderColor
                    },
                    {
                        "bg-uw-teal text-white": !backgroundWhite
                    },
                    {
                        "cursor-default": disabled
                    }
                )}
                disabled={disabled}
                value={title}
                type="submit"
            />

    )
}

export default Button;
