import "core-js/stable";
import "regenerator-runtime/runtime";
import 'cross-fetch/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n/index'
import App from './App';
import './css/style.css';
import { FirebaseProvider } from "./firebase/FirebaseContext";
import { BrowserRouter } from "react-router-dom";

const app = (
  <BrowserRouter>
    <FirebaseProvider>
      <App />
    </FirebaseProvider>
  </BrowserRouter>
)

ReactDOM.render(
  <React.StrictMode>
    {app}
  </React.StrictMode>
  , document.getElementById('root')
);


