//React
import React from "react"
import { useTranslation } from "react-i18next";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
//Firebase
import { auth } from "../../firebase/Firebase";
import { useUserContext } from "../../firebase/FirebaseContext";
import SiteNotFoundScreen from "../../screens/SiteNotFoundScreen";
//Layout
import LoadingScreen from "../layout/LoadingScreenNew";
import { INavigation, INavigationMainItem, MAIN_MENU_IDS } from "../layout/Menu/CustomMenu";
import { UserRoles } from "../../firebase/UserIntefaces"





interface ComponentProps {
  Component?: React.ComponentType<RouteComponentProps>,
  path: string,
  exact?: boolean,
  requiredRoles: string[]
}

interface ComponentNavProps {
  ComponentNav?: React.ComponentType<INavigation>,
  path: string,
  exact?: boolean,
  requiredRoles: string[]
}

const AuthRoute: React.FC<ComponentProps | ComponentNavProps> = ({
  Component,
  ComponentNav,
  path,
  exact = false,
  requiredRoles,
}: Partial<ComponentProps & ComponentNavProps>): JSX.Element => {

  const isAuthed = auth.currentUser
  const { currentUser, isLoggedIn } = useUserContext()


  const {i18n, t} = useTranslation('menu')
  const en = i18n.language.includes('en') ? '/en' : ''
  const mainNavItemsConfig:INavigationMainItem[] = [
    { id: MAIN_MENU_IDS.DASHBOARD_COMPANY, name: t('main.dashboard'), href: '/company/dashboard', current: false, roles: [UserRoles.COMPANY, UserRoles.COMPANY_AND_RECRUITING] },
    { id: MAIN_MENU_IDS.DASHBOARD_RECRUITING, name: t('main.recruitingDashboard'), href: '/recruiting/dashboard', current: false, roles: [UserRoles.RECRUITING, UserRoles.RECRUITING_DEMO, UserRoles.COMPANY_AND_RECRUITING] },
    //{ id: MAIN_MENU_IDS.REQUIREMENT_PROFILES, name: t('main.requirementProfiles'), href: '/company/requirementprofiles', current: false, roles: [UserRoles.COMPANY, UserRoles.COMPANY_AND_RECRUITING] },
    //{ id: MAIN_MENU_IDS.COMPANY_MATCHING, name: t('main.matching'), href: '/company/matching', current: false, roles: [UserRoles.COMPANY, UserRoles.COMPANY_AND_RECRUITING] },
    { id: MAIN_MENU_IDS.CONTACT, name: t('main.contact'), href: '/company/contact', current: false, roles: [UserRoles.RECRUITING, UserRoles.RECRUITING_DEMO, UserRoles.COMPANY, UserRoles.COMPANY_AND_RECRUITING] }
  ]

  let mainNavItems: any = []
  if(currentUser) {
    mainNavItemsConfig.forEach((element) => {
      if(element.roles?.includes(currentUser!.role)) {
        mainNavItems.push(element)
      }
    })
  }
  

  const userItems = {
    name: 'name',
    email: 'email',
    imageUrl: 'imgUrl',
  }

  const metaNavItems = [
    { name: t('meta.settings'), href: '/company/settings' },
    { name: t('meta.faq'), href: '/company/faq' },
    { name: t('meta.dataPrivacy'), href: 'https://luksit.de/datenschutz'+en+'/#luksit-app'},       //Link in neuem Tab öffnen und englischen Link integrieren https://luksit.de/datenschutz/en/
    { name: t('meta.legalDisclosure'), href: 'https://luksit.de/impressum'+en},     //Link in neuem Tab öffnen und englischen Link integrieren https://luksit.de/impressum/en/
    { name: t('meta.logout'), href: '/logout' }
  ]

  let userHasRequiredRole: boolean = false

  if (currentUser) {
    userHasRequiredRole = requiredRoles!.includes(currentUser.role)
  }

  let routeComponent: JSX.Element | undefined = undefined

  /*
  if (loading) {
    routeComponent = <LoadingScreen />
  }
  */

  if (!isLoggedIn)
    routeComponent = <SiteNotFoundScreen />
  else {
    if (!currentUser) {
      routeComponent = <LoadingScreen />
    }
    else if (Component) {

      /*console.log('####', currentUser!.role)
      switch (currentUser!.role) {
        case 'EMPLOYEE':
          <Redirect to='/employee' />
          break
        case 'COMPANY':
          <Redirect to='/company/dashboard' />
          break
      }*/

      routeComponent =
        <Route
          exact={exact}
          path={path}
          render={
            (props: RouteComponentProps) =>
              isAuthed && userHasRequiredRole ? (
                <Component {...props} />
              ) : (
                <Redirect to='/site-not-found' />
              )
          }
        />
    }
    else if (ComponentNav) {
      routeComponent =
        <Route
          exact={exact}
          path={path}
          render={
            (props: RouteComponentProps) =>
              isAuthed && userHasRequiredRole ? (
                <ComponentNav
                  {...props}
                  mainNavItems={mainNavItems}
                  metaNavItems={metaNavItems}
                  userItems={userItems}
                />
              ) : (
                <Redirect to='/site-not-found' />
              )
          }
        />
    }
  }

  return routeComponent!
}

export default AuthRoute

