//React
import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom';
//Components & Layout
import { IEmployee } from '../../components/layout/Employee/Employee'
import CustomMenu from '../../components/layout/Menu/CustomMenu'
import EmployeeList from "../layout/Employee/EmployeesList"
import EmployeeListHeadingCards from "../layout/Employee/EmployeeListHeadingCards"
//import CompanySubHeader from '../layout/companySubHeader'
import StatItems from "../layout/StatItems"
import Footer from "../layout/FooterNew"
import { INavigation, setMainMenuIdActive, MAIN_MENU_IDS } from "../layout/Menu/CustomMenu"
//Hooks
import { useGetAllEmployees } from '../../hooks/useGetAllEmployees'
import { useUserContext } from "../../firebase/FirebaseContext"


import CompanyRegistrationConfirmationScreen from '../../screens/CompanyRegistrationConfirmationScreen';

const CompanyDashboardView: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  const [allEmployees] = useGetAllEmployees();
  const [filteredEmployees, setFilteredEmployees] = useState<IEmployee[] | null>(allEmployees);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState([true, false, false])

  setMainMenuIdActive(mainNavItems, MAIN_MENU_IDS.DASHBOARD_COMPANY)

  useEffect(() => {
    const isTestDone = (employee: IEmployee) => {
      let testDone: boolean = false
      if (employee.test && employee.test.testDone) {
        testDone = true
      } else if (!employee.test) {
        testDone = false
      }
      return testDone
    }

    if (search && allEmployees) {
      const hasEmail = (employee: IEmployee) => {
        if (employee.email) {
          const searchByEmail = employee.email.toLowerCase().includes(search.toLowerCase())
          return searchByEmail
        }
      }
      setFilteredEmployees(allEmployees.filter((employee) => {
        const searchByFirstName = employee.firstName.toLowerCase().includes(search.toLowerCase())
        const searchByLastName = employee.lastName.toLowerCase().includes(search.toLowerCase())

        if (active[1]) {
          if (hasEmail(employee))
            return isTestDone(employee)
          return (searchByFirstName || searchByLastName) && isTestDone(employee)
        }
        if (active[2]) {
          if (hasEmail(employee)) {
            return !isTestDone(employee)
          }
          return (searchByFirstName || searchByLastName) && !isTestDone(employee)
        }
        if (hasEmail(employee)) {
          return hasEmail(employee)
        }
        return (searchByFirstName || searchByLastName)

      }));
    } else if (allEmployees) {
      setFilteredEmployees(allEmployees.filter((employee) => {
        if (active[1])
          return isTestDone(employee)
        if (active[2])
          return !isTestDone(employee)
        return employee
      }));
    }

  }, [search, allEmployees, active]);

  const { t } = useTranslation(['employeeListHeadingCards', 'employeeList'])

  const tabs = [
    { name: t('employeeListHeadingCards:filterCards.showAll'), href: '?tab=all', current: active[0], onClick: () => { setActive([true, false, false]) } },
    { name: t('employeeListHeadingCards:filterCards.showTestDone'), href: '?tab=done', current: active[1], onClick: () => { setActive([false, true, false]) } },
    { name: t('employeeListHeadingCards:filterCards.showTestOpen'), href: '?tab=open', current: active[2], onClick: () => { setActive([false, false, true]) } },
  ]

  const {companyUser} = useUserContext()
  //console.log(companyUser)
  if(companyUser && companyUser!.active !== true) {
    return <CompanyRegistrationConfirmationScreen />
  }

  return (
    <>
      <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
      <div className="py-10">
        <header className="mt-16">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 flex flex-row">
            <Link to="/company/dashboard">
              <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal">Dashboard</h1>
            </Link>
            <span className="lg:text-3xl text-2xl font-medium leading-tight text-uw-teal mr-2 ml-2">|</span>
            <Link to="/company/matching">
              <p className="lg:text-3xl text-2xl font-bold leading-tight text-gray-300 hover:text-uw-teal hover:underline">Matching</p>
            </Link>
          </div>
        </header>
        <main>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="px-4 py-4 sm:px-0">
              <StatItems />
            </div>
            <div className="px-4 py-4 sm:px-0">
              <EmployeeListHeadingCards
                headline={t('employeeListHeadingCards:headline.employeeListHeadline')}
                tabs={tabs}
                setSearch={setSearch}
                setActive={setActive}
              />
            </div>
            <div className="px-4 sm:px-0">
              <EmployeeList employees={filteredEmployees} />
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  )
}

export default CompanyDashboardView
