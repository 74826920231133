
//React
import React from 'react'
//Components
import RecruitingDashboardView from "../components/recruitingDashboard/RecruitingDashboardView"
import { INavigation } from "../components/layout/Menu/CustomMenu"


const RecruitingDashboard: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  return (
    <RecruitingDashboardView mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
  )
}

export default RecruitingDashboard