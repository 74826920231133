//React
//import React from 'react'
import React, { useState, useEffect } from 'react'

//UseTranslation
import { useTranslation } from "react-i18next"
//FirebaseContext
import { useUserContext } from '../../../firebase/FirebaseContext'

import LoadingScreenNew from "../../layout/LoadingScreenNew"
import { IUserData } from '../../../hooks/useUpdateProfile'
import MatchingComparison from "../../layout/MatchingComparison"
import { transformToDataObject, ICompetenceData } from '../../../helpers/transform'
import { UseGetMatchingEmployee } from '../../../hooks/useGetMatchingEmployee'
import IndividualResultsBar from "../../layout/IndividualResultsBar"
import { ChevronLeftIcon } from '@heroicons/react/solid'

const EmployeeResultsDevelopment: React.FC = () => {

  window.scrollTo({top: 0, behavior: 'smooth'});

  const [userCompetenceData, setUserCompetenceData] = useState<IUserData>({})
  const { i18n } = useTranslation(['employeeResults', 'basics'])
  const { employeeUser, resultLocalesStandard } = useUserContext()
  const standardLanguage = i18n.language.includes('de') ? 'standardDE' : 'standardEN'

  let backLink = ''
  if(employeeUser?.role==='CANDIDATE') {
    backLink = '/candidate'
  } else {
    backLink = '/employee'
  }

  useEffect(() => {
    const requestProfile = async () => {
      try {
        const getAllMatchingEmployee = UseGetMatchingEmployee()
        let matchEmployee = await getAllMatchingEmployee(employeeUser?.uid, employeeUser?.testGroups)
        if(matchEmployee) {
          setUserCompetenceData(matchEmployee.data)
        }
      } catch (err) {
        console.log(err)
      }
    }
    requestProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[employeeUser])


  let competenceData: ICompetenceData | null = null;

  let profileCompetenceData: any = []

  if(resultLocalesStandard) {
    competenceData = transformToDataObject(resultLocalesStandard[standardLanguage])
  }

    return competenceData ? (
      <>
          <main>
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

            <div className="mt-8 mb-8">
              <IndividualResultsBar active={[true, false]} employeeView={true}></IndividualResultsBar>
            </div>
              <div className="pb-2 mb-4 pt-2">
                <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[2].name}</h3>
              </div>
                <MatchingComparison competenceData={competenceData.trait[25]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[2]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[35]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
              <div className='pb-5 border-b border-gray-200 mb-4'></div>
  
              <div className="pb-2 mb-4 pt-4">
                <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[5].name}</h3>
              </div>
                <MatchingComparison competenceData={competenceData.trait[19]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[6]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[38]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[29]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
              <div className='pb-5 border-b border-gray-200 mb-4'></div>
  
              <div className="pb-2 mb-4 pt-4">
                <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[7].name}</h3>
              </div>
                <MatchingComparison competenceData={competenceData.trait[27]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[20]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
              <div className='pb-5 border-b border-gray-200 mb-4'></div>
  
              <div className="pb-2 mb-4 pt-4">
                <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[3].name}</h3>
              </div>
                <MatchingComparison competenceData={competenceData.trait[9]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[3]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[37]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[31]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[26]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[21]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[17]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[13]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[15]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
              <div className='pb-5 border-b border-gray-200 mb-4'></div>
  
              <div className="pb-2 mb-4 pt-4">
                <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[4].name}</h3>
              </div>
                <MatchingComparison competenceData={competenceData.trait[4]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[36]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[5]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[30]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[23]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[34]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
              <div className='pb-5 border-b border-gray-200 mb-4'></div>
  
              <div className="pb-2 mb-4 pt-4">
                <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[8].name}</h3>
              </div>
                <MatchingComparison competenceData={competenceData.trait[10]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[33]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[28]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[24]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[14]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[12]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
              <div className='pb-5 border-b border-gray-200 mb-4'></div>
  
              <div className="pb-2 mb-4 pt-4">
                <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[6].name}</h3>
              </div>
                <MatchingComparison competenceData={competenceData.trait[7]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[39]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[8]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
              <div className='pb-5 border-b border-gray-200 mb-4'></div>
  
              <div className="pb-2 mb-4 pt-4">
                <h3 className="text-xl leading-6 font-medium text-uw-teal">{competenceData.category[1].name}</h3>
              </div>
                <MatchingComparison competenceData={competenceData.trait[1]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[32]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[22]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[18]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[16]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />
                <MatchingComparison competenceData={competenceData.trait[11]} userData={userCompetenceData} profileData={profileCompetenceData} employeeView={true} />

                <div className="pt-8 pb-8"> 
                <a href={backLink}>
                  <button
                    type="button"
                    className="items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-bg-teal bg-white hover:bg-gray-200 focus:outline-none">
                    <ChevronLeftIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                  </button>
                </a>
              </div>
            </div>
          </main>
      </>
    ) : (<><LoadingScreenNew /></>)
  
}
export default EmployeeResultsDevelopment;
