import React from 'react'

const FooterNew: React.FC = () => {

    return (
        <>
            <footer>
                <div className="py-10 center">
                    <p>&nbsp;</p>
                </div>
            </footer>
        </>
    )
}

export default FooterNew;