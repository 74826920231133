import React from 'react'
import EmployeeCardSubPart from "./EmployeeCardSubPart";
import classnames from "classnames"
import Text from "../../textStyling/Text";
import {useTranslation} from "react-i18next";

interface IProps {
    mainTitles: Array<string>
    showCard: boolean
    titles: Array<Array<string>>
    texts: Array<Array<string>>
}

const EmployeeCardBody: React.FC<IProps> = ({mainTitles, showCard, titles, texts}) => {

    const {t} = useTranslation('employeeCard')

    const uuidv1 = require('uuid/v1')

    const generateSubParts = () => {

        let subPart: Array<object> = []

        for (let i = 0; i < mainTitles.length; i++) {
            subPart.push(
                <EmployeeCardSubPart key={uuidv1()} lastCard={!(i < mainTitles.length - 1)} mainCardOpened={showCard}
                                     mainTitle={mainTitles[i]}
                                     subTitles={titles[i]}
                                     texts={texts[i]}/>
            )
        }
        return subPart
    }

    return (
        <>
            <div className={classnames({
                "hidden": !showCard
            })}>
                <div className="mt-8 mb-6 ml-2">
                    <Text fat color="text-uw-teal">
                        {t('body.headline')}
                    </Text>
                </div>
                {generateSubParts()}
            </div>
        </>
    )
}

export default EmployeeCardBody
