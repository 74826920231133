import React from 'react'
import H2 from "../textStyling/H2";
import {DotLoader} from "react-spinners";
import {useTranslation} from "react-i18next";

export const LoadingScreenNew: React.FC = () => {

    const {t} = useTranslation('basics')

    return (
        <>
            <div className="h-screen flex justify-center">
                <div className="flex flex-col align-middle justify-center text-center">
                    <div className="hidden lg:block xl:block lg:mb-16 xl:mb-16">
                        <H2 color="uw-teal">
                            {t('basics:validation.validationOne')}
                            <br/>
                            {t('basics:validation.validationTwo')}
                        </H2>
                    </div>
                    <div className="mt-12 flex justify-center">
                        <DotLoader color="#008080" loading size={150}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoadingScreenNew;
