// React
import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import { Redirect } from "react-router"
// Components
import Container from '../components/layout/Container'
import H1 from '../components/textStyling/H1'
import Text from "../components/textStyling/Text"
import Button from "../components/layout/Button"
import H2 from "../components/textStyling/H2"

const TestConfirmationScreenCandidate: React.FC = () => {

  window.scrollTo({top: 0, behavior: 'smooth'});

  const [logout, setLogout] = useState(false)
  const { t } = useTranslation(['testConfirmationCandidate', 'basics'])

  return (
    <>
      {logout && <Redirect push to="/logout" />}
      <Container background="white" headerFooter logout>
        <div className="mb-10 lg:mb-24 xl:mb-24 mt-12 lg:mt-32 xl:mt-32 lg:w-3/5 xl:w-3/5">
          <H1 color="uw-teal">{t('headline')}</H1>
        </div>
        <div className="lg:flex xl:flex lg:flex-row xl:flex-row lg:justify-between xl:justify-between">
          <div className="w-full">
            <H2 color="uw-teal">
              {t('nextSteps')}
            </H2>
          </div>
          <div className="lg:flex xl:flex lg:flex-col xl:flex-col lg:ml-56 xl:ml-56">
            <div
              className="hidden lg:flex xl:flex lg:flex-col xl:flex-col">
              <Text color="text-uw-grey" one>{t('secondHeadlinePartOne')}</Text>
              <br />
            </div>
            <div className="lg:hidden xl:hidden">
              <Text color="text-uw-grey">{t('secondHeadlinePartOne')}</Text>
              <br />
            </div>
            <div className="lg:flex xl:flex lg:flex-row xl:flex-row lg:items-start xl:justify-start lg:mt-6 xl:mt-6">
              <div className="mt-2 mb-12 max-w-sm">
                <Button backgroundWhite={false} borderColor onClick={setLogout}
                  title={t('logoutButton')}
                  type="button" />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-12" />
      </Container>
    </>
  )
}

export default TestConfirmationScreenCandidate;
