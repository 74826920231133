import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import React from 'react'
import { IProfile } from '../../hooks/useGetProfiles'
import { SearchIcon } from '@heroicons/react/outline'
//useTranslation
import { useTranslation } from "react-i18next"

interface IProfileSelect extends IProfile {
    current: boolean
}

interface IProfilesSelect {
    profiles: IProfileSelect[] | null | any,
    setIscoProfileId?: any,
    handleSearch?: any
  }

const IscoProfileItems: React.FC<IProfilesSelect> = ({ profiles, setIscoProfileId, handleSearch }) => {

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    const { t } = useTranslation(['companyRequirementProfiles']);

    const [selected, setSelected] = useState()

    const selectProfile = (event: any) => {
        setSelected(event.name)
        setIscoProfileId(event.id)
    }

    return (

    <Listbox value={selected} onChange={selectProfile}>
        {({ open }) => (
        <>
            <Listbox.Label className="sr-only">Change published status</Listbox.Label>
            <div className="relative flex">
            <div className="inline-flex flex-1 shadow-sm rounded-md divide-x divide-uw-teal">
                <div className="relative z-0 inline-flex flex-1 shadow-sm rounded-md divide-x divide-uw-teal">
                <div className="relative inline-flex flex-1 items-center bg-white py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-uw-teal">
                    {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                    <p className="ml-2.5 text-sm font-medium">{(selected)?selected:t('general.selectTemplate')}</p>
                </div>
                <Listbox.Button className="relative inline-flex items-center bg-uw-teal p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-gray-600 focus:outline-none focus:z-10 focus:ring-uw-teal">
                    <span className="sr-only">Change required profile</span>
                    <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                </Listbox.Button>
                </div>
            </div>

            <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Listbox.Options className="origin-top-right w-full mt-10 absolute z-10 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="relative text-uw-teal ">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="block w-full pl-10 pr-5 py-3 leading-5 focus:outline-none sm:text-sm"
                      placeholder={t('general.searchField')}
                      type="search"
                      onChange={(event) => handleSearch(event)}
                    />
                  </div>
                {profiles.map((profile: IProfileSelect, profileIdx: any) => (
                    <Listbox.Option
                    key={profileIdx}
                    className={({ active }) =>
                        classNames(
                        active ? 'text-white bg-uw-teal' : 'text-gray-900',
                        'cursor-default select-none relative p-4 text-sm'
                        )
                    }
                    value={{name: profile.name, id: profile.id}}
                    >
                    {({ selected, active }) => (
                        <div className="flex flex-col">
                        <div className="flex justify-between">
                            <p className={selected ? 'font-semibold' : 'font-normal'}>{profile.name}</p>
                            {selected ? (
                            <span className={active ? 'text-white' : 'text-uw-teal'}>
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                            ) : null}
                        </div>
                        </div>
                    )}
                    </Listbox.Option>
                ))}
                </Listbox.Options>
            </Transition>
            </div>
        </>
        )}
    </Listbox>
    )
}

export default IscoProfileItems