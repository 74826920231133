//React
import React, { useState } from 'react'
//Hooks 
import { useFormFieldState } from "../../hooks/useFormFieldState"
import { useFormTextareaFieldState } from "../../hooks/useFormTextareaFieldState"
import { useFormSelectFieldState } from "../../hooks/useFormSelectFieldState"
import { useSubmitCompanyContactForm } from "../../hooks/useSubmitCompanyContactForm";
//Components, Icons and Props
import Select from "react-select"
import CustomMenu from '../layout/Menu/CustomMenu'
import Footer from "../layout/FooterNew"
import BreadcrumbNavigation from '../layout/Menu/BreadcrumbNavigation'
import { INavigation, INavigationMainItem, setMainMenuIdActive, MAIN_MENU_IDS } from "../layout/Menu/CustomMenu"
import { ExclamationCircleIcon } from '@heroicons/react/solid'
//useTranlation
import { useTranslation } from "react-i18next"
//Firebase
import { useUserContext } from '../../firebase/FirebaseContext'


const CompanyOffer: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  //useCompanyName
  const { user, companyUser } = useUserContext()

  setMainMenuIdActive(mainNavItems, MAIN_MENU_IDS.CONTACT)
  const { t } = useTranslation(['companyContact', 'basics']);

  const packages = [
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
    { value: '>100', label: '>100' }
  ]

  const breadCrumbHome = {
    name: 'home',
    href: '/company/dashboard',
    current: false
  }

  const breadCrumbPaths: INavigationMainItem[] = [
    {
      name: t('companyContact:texts.headlineBreadcrumb'),
      href: '/company/contact/',
      current: true
    }
  ]

  const submit = useSubmitCompanyContactForm()

  const [contactSubject, setContactSubject] = useFormFieldState('')
  const [contactSubjectError, setContactSubjectError] = useState(false)
  const [contactMessage, setContactMessage] = useFormTextareaFieldState('')
  const [contactMessageError, setContactMessageError] = useState(false)
  const [contactNewLicence, setContactNewLicence] = useFormSelectFieldState('')
  const [resSubmit, setResSubmit] = useState(true)

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (contactSubject !== "") {
      setContactSubjectError(false)
    } else {
      setContactSubjectError(true)
    }
    if (contactMessage !== "") {
      setContactMessageError(false)
    } else {
      setContactMessageError(true)
    }

    if (contactSubject === "" || contactMessage === "") {
      return
    }

    let subject = 'Kontaktanfrage'
    let email = ''

    if (user && user.email !== null) {
      email = user.email
    }

    if (companyUser && companyUser !== null) {

      subject = `Kontakt | ${companyUser?.company.name} | ${companyUser?.contact.firstName} ${companyUser?.contact.lastName}`

      try {
        //setResSubmit(false)
        await submit(email, subject, {
          firstName: companyUser!.contact.firstName,
          lastName: companyUser!.contact.lastName,
          subject: contactSubject,
          message: contactMessage,
          newLicence: contactNewLicence
        })
        setResSubmit(false)
      }
      catch (error) {
        setResSubmit(true)
      }
    }
  }

  return (
    <>
      <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
      <div className="py-10">
        <header className="mt-16">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="pt-2 pb-4">
              <BreadcrumbNavigation homePath={breadCrumbHome} currentPaths={breadCrumbPaths}></BreadcrumbNavigation>
            </div>
            <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal pb-10">{t('companyContact:texts.headline')}</h1>
          </div>
        </header>
        <main>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row justify-between mb-2 lg:mb-8">
              <div className="max-w-sm md:mr-8 mb-8 md:mb-0">
                <div className="">
                  <p className="font-bold lg:leading-textOneTwoDesktop sm:text-base text-uw-teal lg:text-2xl">{t('companyContact:texts.textOne')}</p><br></br>
                  <p className="font-regular lg:leading-textOneTwoDesktop sm:text-base text-uw-teal lg:text-2xl">{t('companyContact:texts.textTwo')}</p><br></br>
                  <a href="mailto:support@skilldetektor.de" className="font-regular lg:leading-textOneTwoDesktop sm:text-base text-uw-teal underline lg:text-xl">support@skilldetektor.de</a>
                </div>
              </div>
              <div className="md:w-57%">
                {/* Kontaktformular */}
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                  <section aria-labelledby="contact-form">
                    <form>
                      {resSubmit === true ? (
                        <div className="shadow-xl sm:rounded-md sm:overflow-hidden">
                          <div className="bg-uw-lightTeal py-6 px-4 sm:p-6">
                            <div>
                              <h2 id="contact-form" className="text-xl mt-2 leading-6 font-medium text-gray-800">
                                {t('companyContact:form.headline')}
                              </h2>
                              {/*<p className="mt-6 text-sm text-gray-500">
                                {t('companyContact:form.descriptionTextOne')}
                              </p>*/}
                            </div>
                            <div>
                              <p className="mt-6 text-sm text-gray-500">
                                {t('companyContact:form.descriptionTextTwo')}
                              </p>
                            </div>
                            <div className="mt-4 grid grid-cols-4 gap-6">
                              <div className="col-span-4 sm:col-span-2">
                                <label htmlFor="new-licenses" className="block text-sm font-medium text-gray-700">
                                  {t('companyContact:form.newLicenses')}
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <Select
                                    name="newLicenses"
                                    placeholder={t('companyContact:form.selectLicense')}
                                    id="newLicenses"
                                    options={packages}
                                    onChange={option => setContactNewLicence(option)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <p className="mt-6 text-sm text-gray-500">
                                {t('companyContact:form.descriptionTextThree')}
                              </p>
                            </div>
                            <div className="mt-4 grid grid-cols-4 gap-6">
                              <div className="col-span-4 sm:col-span-2">
                                <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                                  {t('companyContact:form.subject')}
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <input
                                    type="text"
                                    name="subject"
                                    id="subject"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                    aria-invalid="true"
                                    aria-describedby="subject-error"
                                    required
                                    onChange={setContactSubject}
                                  />
                                  <div className={(contactSubjectError === true ? 'absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none' : 'absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none hidden')}>
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                  </div>
                                </div>
                                <div>
                                  <p className={(contactSubjectError === true ? 'mt-2 text-sm text-red-600' : 'mt-2 text-sm text-red-600 hidden')} id="surname-error">
                                    {t('companyContact:form.subjectError')}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="mt-6 grid grid-cols-2 gap-6">
                              <div className="col-span-4 sm:col-span-2">
                                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                                  {t('companyContact:form.message')}
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                  <textarea
                                    name="message"
                                    id="message"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                                    aria-invalid="true"
                                    aria-describedby="message-error"
                                    required
                                    onChange={setContactMessage}
                                  ></textarea>
                                  <div className={(contactMessageError === true ? 'absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none' : 'absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none hidden')}>
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                  </div>
                                </div>
                                <div>
                                  <p className={(contactMessageError === true ? 'mt-2 text-sm text-red-600' : 'mt-2 text-sm text-red-600 hidden')} id="surname-error">
                                    {t('companyContact:form.messageError')}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="px-2 py-3 mt-4 text-right sm:px-6">
                              <button
                                type="submit"
                                onClick={submitHandler}
                                className="bg-uw-teal border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                              >
                                {t('companyContact:form.formButton')}
                              </button>
                            </div>
                          </div>
                        </div>)
                        : (
                          <div className="bg-uw-lightTeal py-6 px-4 sm:p-6">
                            <div>
                              <h2 id="contact-form" className="text-xl mt-2 leading-6 font-medium text-gray-800">
                                {t('companyContact:form.headline')}
                              </h2>
                              <p className="mt-6 text-sm text-gray-500">
                                {t('companyContact:form.descriptionSucess')}
                              </p>
                            </div>
                          </div>
                        )
                      }
                    </form>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  )
}

export default CompanyOffer