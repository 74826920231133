//React
import React from 'react'
import { DotLoader } from "react-spinners"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
//Components & Layout & Style
import Card from "./EmployeeCard"
import H2 from "../../textStyling/H2"
import { IEmployee } from '../../layout/Employee/Employee'
//Hook
import { useGetEmployee } from '../../../hooks/useGetEmployee'
//Firebase
import { useUserContext } from '../../../firebase/FirebaseContext'

const EmployeeResultsCompany: React.FC = () => {

  const { resultLocalesStrengthsAndRisks, resultLocalesStandard } = useUserContext()

  const { uid } = useParams<Record<string, string | undefined>>();
  const user: IEmployee | null | undefined = useGetEmployee(uid);
  const { t, i18n } = useTranslation(["employeeResults", 'basics'])
  const strengthAndRiskLanguage = i18n.language.includes('de') ? 'strengthsAndRisksDE' : 'strengthsAndRisksEN'
  const standardLanguage = i18n.language.includes('de') ? 'standardDE' : 'standardEN'

  const generateStrengthsAndRisksData = (competencesIndex: number, points: number) => {
    let data: Array<Array<string>> = []
    if (resultLocalesStrengthsAndRisks !== null) {
      for (let i = 0; i < 5; i++) {
        switch (i) {
          case 0:
            data.push(
              [resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.leading.strengths${points}`], resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.leading.risks${points}`]]
            )
            break
          case 1:
            data.push([resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.team.strengths${points}`], resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.team.risks${points}`]]
            )
            break
          case 2:
            data.push(
              [resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.learning.strengths${points}`], resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.learning.risks${points}`]]
            )
            break
          case 3:
            data.push([resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.motivation.text${points}`], resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.motivation.text${points}`]]
            )
            break
          case 4:
            data.push([resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.development.text${points}`], resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.development.text${points}`]]
            )
            break
        }
      }
    }
    return data
  }

  const generateResults = () => {
    if (user === null || user.role !== 'COMPANY' || user.test === undefined || resultLocalesStrengthsAndRisks === null) {
      return (<div>
        <hr className="border border-uw-teal" />
        <div className="flex flex-1 justify-center mb-8 mt-10">
          <H2 color="uw-teal">
            {t('basics:validation.validationOne')}
            <br />
            {t('basics:validation.validationTwo')}
          </H2>
        </div>
        <div className="flex flex-1 justify-center mb-8">
          <DotLoader color="#006666" loading size={80} />
        </div>
        <hr className="border border-uw-teal" />
      </div>)
    }
    let results: Array<Array<string>> = []
    for (let key in user.test.results) {
      results.push(
        [key, user.test.results[key]]
      )
    }

    return (
      <div>
        <Card
          cardPoints={results[1][1] as unknown as number}
          cardHeadTitle={resultLocalesStandard![standardLanguage]["g8t.category2.name"]}
          cardHeadText={resultLocalesStrengthsAndRisks![strengthAndRiskLanguage]["g8t.category2.paragraphs.general.text1"]}
          cardSubPartTexts={generateStrengthsAndRisksData(2, results[1][1] as unknown as number)}
          chartExplanations={[resultLocalesStandard![standardLanguage]["g8t.trait25.name"], resultLocalesStandard![standardLanguage]["g8t.trait2.name"], resultLocalesStandard![standardLanguage]["g8t.trait35.name"]]}
          chartPoints={[results[25][1] as unknown as number, results[19][1] as unknown as number, results[36][1] as unknown as number]}
          chartText={[resultLocalesStandard![standardLanguage][`g8t.trait25.level${results[25][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait2.level${results[19][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait35.level${results[36][1] as unknown as number}`]]}
          key={`card 2`}
        />
        <div className="mb-4" />
        <Card
          cardPoints={results[4][1] as unknown as number}
          cardHeadTitle={resultLocalesStandard![standardLanguage]["g8t.category5.name"]}
          cardHeadText={resultLocalesStrengthsAndRisks![strengthAndRiskLanguage]["g8t.category5.paragraphs.general.text1"]}
          cardSubPartTexts={generateStrengthsAndRisksData(5, results[4][1] as unknown as number)}
          chartExplanations={[resultLocalesStandard![standardLanguage]["g8t.trait19.name"], resultLocalesStandard![standardLanguage]["g8t.trait6.name"], resultLocalesStandard![standardLanguage]["g8t.trait38.name"], resultLocalesStandard![standardLanguage]["g8t.trait29.name"]]}
          chartPoints={[results[18][1] as unknown as number, results[43][1] as unknown as number, results[39][1] as unknown as number, results[29][1] as unknown as number]}
          chartText={[resultLocalesStandard![standardLanguage][`g8t.trait19.level${results[18][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait6.level${results[43][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait38.level${results[39][1] as unknown as number}`],
          resultLocalesStandard![standardLanguage][`g8t.trait29.level${results[29][1] as unknown as number}`]]}
          key={`card 5`}
        />
        <div className="mb-4" />
        <Card
          cardPoints={results[6][1] as unknown as number}
          cardHeadTitle={resultLocalesStandard![standardLanguage]["g8t.category7.name"]}
          cardHeadText={resultLocalesStrengthsAndRisks![strengthAndRiskLanguage]["g8t.category7.paragraphs.general.text1"]}
          cardSubPartTexts={generateStrengthsAndRisksData(7, results[6][1] as unknown as number)}
          chartExplanations={[resultLocalesStandard![standardLanguage]["g8t.trait27.name"], resultLocalesStandard![standardLanguage]["g8t.trait20.name"]]}
          chartPoints={[results[27][1] as unknown as number, results[20][1] as unknown as number]}
          chartText={[resultLocalesStandard![standardLanguage][`g8t.trait27.level${results[27][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait20.level${results[20][1] as unknown as number}`]]}
          key={`card 7`}
        />
        <div className="mb-4" />
        <Card
          cardPoints={results[2][1] as unknown as number}
          cardHeadTitle={resultLocalesStandard![standardLanguage]["g8t.category3.name"]}
          cardHeadText={resultLocalesStrengthsAndRisks![strengthAndRiskLanguage]["g8t.category3.paragraphs.general.text1"]}
          cardSubPartTexts={generateStrengthsAndRisksData(3, results[2][1] as unknown as number)}
          chartExplanations={[resultLocalesStandard![standardLanguage]["g8t.trait9.name"], resultLocalesStandard![standardLanguage]["g8t.trait3.name"], resultLocalesStandard![standardLanguage]["g8t.trait37.name"], resultLocalesStandard![standardLanguage]["g8t.trait31.name"], resultLocalesStandard![standardLanguage]["g8t.trait26.name"], resultLocalesStandard![standardLanguage]["g8t.trait21.name"], resultLocalesStandard![standardLanguage]["g8t.trait17.name"], resultLocalesStandard![standardLanguage]["g8t.trait13.name"], resultLocalesStandard![standardLanguage]["g8t.trait15.name"]]}
          chartPoints={[results[46][1] as unknown as number, results[30][1] as unknown as number, results[38][1] as unknown as number, results[32][1] as unknown as number, results[26][1] as unknown as number, results[21][1] as unknown as number, results[16][1] as unknown as number, results[12][1] as unknown as number, results[14][1] as unknown as number]}
          chartText={[resultLocalesStandard![standardLanguage][`g8t.trait9.level${results[46][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait3.level${results[30][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait37.level${results[38][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait31.level${results[32][1] as unknown as number}`],
          resultLocalesStandard![standardLanguage][`g8t.trait26.level${results[26][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait21.level${results[21][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait17.level${results[16][1] as unknown as number}`],
          resultLocalesStandard![standardLanguage][`g8t.trait13.level${results[12][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait15.level${results[14][1] as unknown as number}`]]}
          key={`card 3`}
        />
        <div className="mb-4" />
        <Card
          cardPoints={results[3][1] as unknown as number}
          cardHeadTitle={resultLocalesStandard![standardLanguage]["g8t.category4.name"]}
          cardHeadText={resultLocalesStrengthsAndRisks![strengthAndRiskLanguage]["g8t.category4.paragraphs.general.text1"]}
          cardSubPartTexts={generateStrengthsAndRisksData(4, results[3][1] as unknown as number)}
          chartExplanations={[resultLocalesStandard![standardLanguage]["g8t.trait4.name"], resultLocalesStandard![standardLanguage]["g8t.trait36.name"], resultLocalesStandard![standardLanguage]["g8t.trait34.name"], resultLocalesStandard![standardLanguage]["g8t.trait30.name"], resultLocalesStandard![standardLanguage]["g8t.trait23.name"], resultLocalesStandard![standardLanguage]["g8t.trait5.name"]]}
          chartPoints={[results[41][1] as unknown as number, results[37][1] as unknown as number, results[35][1] as unknown as number, results[31][1] as unknown as number, results[23][1] as unknown as number, results[42][1] as unknown as number]}
          chartText={[resultLocalesStandard![standardLanguage][`g8t.trait4.level${results[41][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait36.level${results[37][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait34.level${results[35][1] as unknown as number}`],
          resultLocalesStandard![standardLanguage][`g8t.trait30.level${results[31][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait23.level${results[23][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait5.level${results[42][1] as unknown as number}`]]}
          key={`card 4`}
        />
        <div className="mb-4" />
        <Card
          cardPoints={results[7][1] as unknown as number}
          cardHeadTitle={resultLocalesStandard![standardLanguage]["g8t.category8.name"]}
          cardHeadText={resultLocalesStrengthsAndRisks![strengthAndRiskLanguage]["g8t.category8.paragraphs.general.text1"]}
          cardSubPartTexts={generateStrengthsAndRisksData(8, results[7][1] as unknown as number)}
          chartExplanations={[resultLocalesStandard![standardLanguage]["g8t.trait10.name"], resultLocalesStandard![standardLanguage]["g8t.trait33.name"], resultLocalesStandard![standardLanguage]["g8t.trait28.name"], resultLocalesStandard![standardLanguage]["g8t.trait24.name"], resultLocalesStandard![standardLanguage]["g8t.trait14.name"], resultLocalesStandard![standardLanguage]["g8t.trait12.name"]]}
          chartPoints={[results[9][1] as unknown as number, results[34][1] as unknown as number, results[28][1] as unknown as number, results[24][1] as unknown as number, results[13][1] as unknown as number, results[11][1] as unknown as number]}
          chartText={[resultLocalesStandard![standardLanguage][`g8t.trait10.level${results[9][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait33.level${results[34][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait28.level${results[28][1] as unknown as number}`],
          resultLocalesStandard![standardLanguage][`g8t.trait24.level${results[24][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait14.level${results[13][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait12.level${results[11][1] as unknown as number}`]]}
          key={`card 8`}
        />
        <div className="mb-4" />
        <Card
          cardPoints={results[5][1] as unknown as number}
          cardHeadTitle={resultLocalesStandard![standardLanguage]["g8t.category6.name"]}
          cardHeadText={resultLocalesStrengthsAndRisks![strengthAndRiskLanguage]["g8t.category6.paragraphs.general.text1"]}
          cardSubPartTexts={generateStrengthsAndRisksData(6, results[5][1] as unknown as number)}
          chartExplanations={[resultLocalesStandard![standardLanguage]["g8t.trait7.name"], resultLocalesStandard![standardLanguage]["g8t.trait39.name"], resultLocalesStandard![standardLanguage]["g8t.trait8.name"]]}
          chartPoints={[results[44][1] as unknown as number, results[40][1] as unknown as number, results[45][1] as unknown as number]}
          chartText={[resultLocalesStandard![standardLanguage][`g8t.trait7.level${results[44][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait39.level${results[40][1] as unknown as number}`],
          resultLocalesStandard![standardLanguage][`g8t.trait8.level${results[45][1] as unknown as number}`]]}
          key={`card 6`}
        />
        <div className="mb-4" />
        <Card
          cardPoints={results[0][1] as unknown as number}
          cardHeadTitle={resultLocalesStandard![standardLanguage]["g8t.category1.name"]}
          cardHeadText={resultLocalesStrengthsAndRisks![strengthAndRiskLanguage]["g8t.category1.paragraphs.general.text1"]}
          cardSubPartTexts={generateStrengthsAndRisksData(1, results[0][1] as unknown as number)}
          chartExplanations={[resultLocalesStandard![standardLanguage]["g8t.trait1.name"], resultLocalesStandard![standardLanguage]["g8t.trait32.name"], resultLocalesStandard![standardLanguage]["g8t.trait22.name"], resultLocalesStandard![standardLanguage]["g8t.trait18.name"], resultLocalesStandard![standardLanguage]["g8t.trait16.name"], resultLocalesStandard![standardLanguage]["g8t.trait11.name"]]}
          chartPoints={[results[8][1] as unknown as number, results[33][1] as unknown as number, results[22][1] as unknown as number, results[17][1] as unknown as number, results[15][1] as unknown as number, results[10][1] as unknown as number]}
          chartText={[resultLocalesStandard![standardLanguage][`g8t.trait1.level${results[8][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait32.level${results[33][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait22.level${results[22][1] as unknown as number}`],
          resultLocalesStandard![standardLanguage][`g8t.trait18.level${results[17][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait16.level${results[15][1] as unknown as number}`], resultLocalesStandard![standardLanguage][`g8t.trait11.level${results[10][1] as unknown as number}`]]}
          key={`card 1`}
        />
      </div>
    )
  }

  return (
    <>
      {generateResults()}
    </>
  )
}

export default EmployeeResultsCompany
