//React
import React, { useEffect, useState } from 'react'
//Styles & Container
import H1 from "../../textStyling/H1"
import Text from "../../textStyling/Text"
import H2 from "../../textStyling/H2"
import Container from "../../layout/Container"
//EmployeeResults
import EmployeeResultsDevelopment from "./EmployeeResultsDevelopment"
//UseTranlation
import { useTranslation } from "react-i18next"
//FirebaseContext
import { useUserContext } from '../../../firebase/FirebaseContext'


const DesktopDevelopmentView: React.FC = () => {

  const { employeeUser } = useUserContext()

  const { t } = useTranslation(['resultsEmployeeOverallScreen', 'basics'])

  //useTestDate hook
  const [testDate, setTestDate] = useState<string | null>(null)

  useEffect(() => {
    if (employeeUser?.test !== undefined) {
      setTestDate(employeeUser.test.testDate)
    }
  }, [employeeUser])

  const bodyContentGreenBackground = (
    <>
      <div className="flex flex-row justify-between md:mt-32 md:mb-24 mb-6 mt-16">
        <div className="">
          <H1 color="white">
            {`${t("headlineOne")} ${employeeUser?.firstName},`}
            <br />
            {t("headlineTwo")}
          </H1>
        </div>
        <div className="hidden md:flex md:flex-col md:mr-16">
          <div className="mb-4">
            <Text color="text-white" four opacity>
              {t('testDate')}
            </Text>
          </div>
          <div className="flex justify-center">
            <Text color="text-white" four style={{ className: "font-semibold" }}>
              {testDate}
            </Text>
          </div>
        </div>
      </div>
      <div className="block md:flex md:flex-row md:justify-between md:mb-24">
        <div className="max-w-sm mr-12">
          <H2 color="white">
            {t('secondHeadline')}
          </H2>
        </div>
        <div className="md:w-57% mt-4 md:mt-0">
          <Text color="text-white" three>
            {t("subHeadline")}
          </Text>
        </div>
      </div>
    </>
  )

  const bodyContent = (
    <>
      <EmployeeResultsDevelopment />
    </>
  )

  return (
    <>
      <div className="sm:block md:block lg:block xl:block">
        <Container
          background="white"
          bodyContentGreenBackground={bodyContentGreenBackground}
          bodyContentSecond={bodyContent}
          headerFooter
          logout
          greenBackground
          greenBackgroundLessUpperPart
        />
      </div>
    </>
  )
}

export default DesktopDevelopmentView