//React
import React from 'react'
import { useTranslation } from "react-i18next"
import { DotLoader } from "react-spinners"
import { useParams } from "react-router"
//EmployeeOuterCard & Layout & Styles
import EmployeeOuterCard from "./EmployeeOuterCard"
import H2 from "../../textStyling/H2"
import { IState } from "./EmployeeOuterCard"
import { IEmployee } from '../../layout/Employee/Employee'
//Hooks
import { useGetEmployee } from '../../../hooks/useGetEmployee'
//Firebase
import { useUserContext } from '../../../firebase/FirebaseContext'

const EmployeeResultsCompany: React.FC<any> = () => {

  const { resultLocalesStrengthsAndRisks, resultLocalesStandard } = useUserContext()

  const { uid } = useParams<Record<string, string | undefined>>();
  const user: IEmployee | null | undefined = useGetEmployee(uid);
  const { t, i18n } = useTranslation(['employeeResults', 'resultsCompanyOverallScreen', 'basics'])
  const strengthAndRiskLanguage = i18n.language.includes('de') ? 'strengthsAndRisksDE' : 'strengthsAndRisksEN'
  const standardLanguage = i18n.language.includes('de') ? 'standardDE' : 'standardEN'

  const generateStrengthsAndRisksData = (competencesIndex: number, points: number) => {
    let data: Array<IState> = []
    if (resultLocalesStrengthsAndRisks !== null) {
      for (let i = 0; i < 5; i++) {
        switch (i) {
          case 0:
            data.push({
              title: t('card.strengthsAndRisksTitles')[0],
              explanationText: [resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.leading.strengths${points}`], resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.leading.risks${points}`]]
            })
            break
          case 1:
            data.push({
              title: t('card.strengthsAndRisksTitles')[1],
              explanationText: [resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.team.strengths${points}`], resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.team.risks${points}`]]
            })
            break
          case 2:
            data.push({
              title: t('card.strengthsAndRisksTitles')[2],
              explanationText: [resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.learning.strengths${points}`], resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.learning.risks${points}`]]
            })
            break
          case 3:
            data.push({
              title: t('card.strengthsAndRisksTitles')[3],
              explanationText: [resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.motivation.text${points}`], resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.motivation.text${points}`]]
            })
            break
          case 4:
            data.push({
              title: t('card.strengthsAndRisksTitles')[4],
              explanationText: [resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.development.text${points}`], resultLocalesStrengthsAndRisks[strengthAndRiskLanguage][`g8t.category${competencesIndex}.paragraphs.development.text${points}`]]
            })
            break
        }
      }
    }
    return data
  }

  const generateResults = () => {
    //if ((!user || user === null) && user.role !== 'COMPANY' || user.test === undefined || resultLocalesStrengthsAndRisks === null || resultLocalesStandard === null) {
    if (user === null || user.test === undefined || resultLocalesStrengthsAndRisks === null || resultLocalesStandard === null) {
      return (
        <div className="h-screen flex justify-center">
          <div className="flex flex-col align-middle justify-center text-center">
            <div className="hidden lg:block xl:block lg:mb-16 xl:mb-16">
              <H2 color="uw-teal">
                {t('basics:validation.validationOne')}
                <br />
                {t('basics:validation.validationTwo')}
              </H2>
            </div>
            <div className="mt-12 flex justify-center">
              <DotLoader color="#008080" loading size={150} />
            </div>
          </div>
        </div>
      )
    }

    let results = user.test.results

    return (
      <>
        <EmployeeOuterCard
          cardTitle={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category2.name"]}
          innerCompetencesData={[
            { title: resultLocalesStandard[standardLanguage]["g8t.trait25.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait25.level${results['g8t_trait25'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait25.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait25.low"], points: results['g8t_trait25'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait2.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait2.level${results['g8t_trait2'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait2.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait2.low"], points: results['g8t_trait2'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait35.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait35.level${results['g8t_trait35'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait35.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait35.low"], points: results['g8t_trait35'] as unknown as number },
          ]}
          innerStrengthsAndRisksData={generateStrengthsAndRisksData(2, results['g8t_category2'] as unknown as number)}
          points={results['g8t_category2'] as unknown as number}
          titleDescription={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category2.paragraphs.general.text1"]}
        />
        <div className="mb-12" />
        <EmployeeOuterCard
          cardTitle={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category5.name"]}
          innerCompetencesData={[
            { title: resultLocalesStandard[standardLanguage]["g8t.trait19.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait19.level${results['g8t_trait19'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait19.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait19.low"], points: results['g8t_trait19'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait6.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait6.level${results['g8t_trait6'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait6.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait6.low"], points: results['g8t_trait6'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait38.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait38.level${results['g8t_trait38'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait38.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait38.low"], points: results['g8t_trait38'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait29.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait29.level${results['g8t_trait29'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait29.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait29.low"], points: results['g8t_trait29'] as unknown as number }
          ]}
          innerStrengthsAndRisksData={generateStrengthsAndRisksData(5, results['g8t_category5'] as unknown as number)}
          points={results['g8t_category5'] as unknown as number}
          titleDescription={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category5.paragraphs.general.text1"]}
        />
        <div className="mb-12" />
        <EmployeeOuterCard
          cardTitle={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category7.name"]}
          innerCompetencesData={[
            { title: resultLocalesStandard[standardLanguage]["g8t.trait27.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait27.level${results['g8t_trait27'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait27.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait27.low"], points: results['g8t_trait27'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait20.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait20.level${results['g8t_trait20'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait20.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait20.low"], points: results['g8t_trait20'] as unknown as number },
          ]}
          innerStrengthsAndRisksData={generateStrengthsAndRisksData(7, results['g8t_category7'] as unknown as number)}
          points={results['g8t_category7'] as unknown as number}
          titleDescription={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category7.paragraphs.general.text1"]}
        />
        <div className="mb-12" />
        <EmployeeOuterCard
          cardTitle={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category3.name"]}
          innerCompetencesData={[
            { title: resultLocalesStandard[standardLanguage]["g8t.trait9.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait9.level${results['g8t_trait9'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait9.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait9.low"], points: results['g8t_trait9'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait3.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait3.level${results['g8t_trait3'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait3.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait3.low"], points: results['g8t_trait3'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait37.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait37.level${results['g8t_trait37'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait37.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait37.low"], points: results['g8t_trait37'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait31.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait31.level${results['g8t_trait31'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait31.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait31.low"], points: results['g8t_trait31'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait26.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait26.level${results['g8t_trait26'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait26.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait26.low"], points: results['g8t_trait26'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait21.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait21.level${results['g8t_trait21'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait21.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait21.low"], points: results['g8t_trait21'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait17.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait17.level${results['g8t_trait17'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait17.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait17.low"], points: results['g8t_trait17'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait13.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait13.level${results['g8t_trait13'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait13.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait13.low"], points: results['g8t_trait13'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait15.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait15.level${results['g8t_trait15'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait15.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait15.low"], points: results['g8t_trait15'] as unknown as number }
          ]}
          innerStrengthsAndRisksData={generateStrengthsAndRisksData(3, results['g8t_category3'] as unknown as number)}
          points={results['g8t_category3'] as unknown as number}
          titleDescription={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category3.paragraphs.general.text1"]}
        />
        <div className="mb-12" />
        <EmployeeOuterCard
          cardTitle={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category4.name"]}
          innerCompetencesData={[
            { title: resultLocalesStandard[standardLanguage]["g8t.trait4.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait4.level${results['g8t_trait4'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait4.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait4.low"], points: results['g8t_trait4'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait36.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait36.level${results['g8t_trait36'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait36.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait36.low"], points: results['g8t_trait36'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait5.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait5.level${results['g8t_trait5'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait5.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait5.low"], points: results['g8t_trait5'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait30.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait30.level${results['g8t_trait30'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait30.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait30.low"], points: results['g8t_trait30'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait23.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait23.level${results['g8t_trait23'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait23.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait23.low"], points: results['g8t_trait23'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait34.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait34.level${results['g8t_trait34'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait34.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait34.low"], points: results['g8t_trait34'] as unknown as number }
          ]}
          innerStrengthsAndRisksData={generateStrengthsAndRisksData(4, results['g8t_category4'] as unknown as number)}
          points={results['g8t_category4'] as unknown as number}
          titleDescription={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category4.paragraphs.general.text1"]}
        />
        <div className="mb-12" />
        <EmployeeOuterCard
          cardTitle={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category8.name"]}
          innerCompetencesData={[
            { title: resultLocalesStandard[standardLanguage]["g8t.trait10.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait10.level${results['g8t_trait10'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait10.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait10.low"], points: results['g8t_trait10'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait33.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait33.level${results['g8t_trait33'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait33.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait33.low"], points: results['g8t_trait33'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait28.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait28.level${results['g8t_trait28'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait28.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait28.low"], points: results['g8t_trait28'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait24.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait24.level${results['g8t_trait24'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait24.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait24.low"], points: results['g8t_trait24'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait14.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait14.level${results['g8t_trait14'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait14.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait14.low"], points: results['g8t_trait14'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait12.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait12.level${results['g8t_trait12'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait12.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait12.low"], points: results['g8t_trait12'] as unknown as number }
          ]}
          innerStrengthsAndRisksData={generateStrengthsAndRisksData(8, results['g8t_category8'] as unknown as number)}
          points={results['g8t_category8'] as unknown as number}
          titleDescription={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category8.paragraphs.general.text1"]}
        />
        
        <div className="mb-12" />
        <EmployeeOuterCard
          cardTitle={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category6.name"]}
          innerCompetencesData={[
            { title: resultLocalesStandard[standardLanguage]["g8t.trait7.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait7.level${results['g8t_trait7'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait7.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait7.low"], points: results['g8t_trait7'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait39.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait39.level${results['g8t_trait39'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait39.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait39.low"], points: results['g8t_trait39'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait8.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait8.level${results['g8t_trait8'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait8.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait8.low"], points: results['g8t_trait8'] as unknown as number }
          ]}
          innerStrengthsAndRisksData={generateStrengthsAndRisksData(6, results['g8t_category6'] as unknown as number)}
          points={results['g8t_category6'] as unknown as number}
          titleDescription={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category6.paragraphs.general.text1"]}
        />
        <div className="mb-12" />
        <EmployeeOuterCard
          cardTitle={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category1.name"]}
          innerCompetencesData={[
            { title: resultLocalesStandard[standardLanguage]["g8t.trait1.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait1.level${results['g8t_trait1'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait1.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait1.low"], points: results['g8t_trait1'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait32.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait32.level${results['g8t_trait32'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait32.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait32.low"], points: results['g8t_trait32'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait22.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait22.level${results['g8t_trait22'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait22.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait22.low"], points: results['g8t_trait22'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait18.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait18.level${results['g8t_trait18'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait18.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait18.low"], points: results['g8t_trait18'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait16.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait16.level${results['g8t_trait16'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait16.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait16.low"], points: results['g8t_trait16'] as unknown as number },
            { title: resultLocalesStandard[standardLanguage]["g8t.trait11.name"], explanationText: resultLocalesStandard[standardLanguage][`g8t.trait11.level${results['g8t_trait11'] as unknown as number}`], traitLevelHigh: resultLocalesStandard[standardLanguage]["g8t.trait11.high"], traitLevelLow: resultLocalesStandard[standardLanguage]["g8t.trait11.low"], points: results['g8t_trait11'] as unknown as number }
          ]}
          innerStrengthsAndRisksData={generateStrengthsAndRisksData(1, results['g8t_category1'] as unknown as number)}
          points={results['g8t_category1'] as unknown as number}
          titleDescription={resultLocalesStrengthsAndRisks[strengthAndRiskLanguage]["g8t.category1.paragraphs.general.text1"]}
        />
        <div className="mb-12" />
      </>
    )
  }

  return (
    <>
      {generateResults()}
    </>
  )

}

export default EmployeeResultsCompany

