//React
import React, { useState, useEffect } from 'react'
//Hooks 
//Components, Icons and Props
import CustomMenu from '../layout/Menu/CustomMenu'
import Footer from "../layout/FooterNew"
import BreadcrumbNavigation from '../layout/Menu/BreadcrumbNavigation'
import { INavigation, INavigationMainItem, setMainMenuIdActive, MAIN_MENU_IDS } from "../layout/Menu/CustomMenu"
import RequirementProfileCompetenciesActive from "../layout/RequirementProfileCompetenciesActive"
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import useCollapse from 'react-collapsed'
import LoadingScreenNew from "../../components/layout/LoadingScreenNew"
//useTranslation
import { useTranslation } from "react-i18next"
//Firebase
import { useUserContext } from '../../firebase/FirebaseContext'
import { transformToDataObject, ICompetenceData } from '../../helpers/transform'
import { useFormFieldState } from '../../hooks/useFormFieldState'
import { useHistory } from "react-router-dom"
import { useUpdateProfile, IProfile, IUserData } from '../../hooks/useUpdateProfile'
import { UseGetProfile } from '../../hooks/useGetProfile'
import { useParams } from "react-router"
import { useDeleteProfile } from '../../hooks/useDeleteProfile'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

const CompanyRequirementProfileEditView: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  setMainMenuIdActive(mainNavItems, MAIN_MENU_IDS.REQUIREMENT_PROFILES)

  interface ParamTypes {
    uid: string
  }
  const { uid } = useParams<ParamTypes>()

  const { t, i18n } = useTranslation(['companyRequirementProfiles']);
  const { resultLocalesStandard } = useUserContext()
  const standardLanguage = i18n.language.includes('de') ? 'standardDE' : 'standardEN'

  

  let history = useHistory()

  const [profile, setProfile] = useState<IProfile>({name: '', description: '', data:{}})
  const [userCompetenceData, setUserCompetenceData] = useState<IUserData>({})
  const [profileTemplate, setProfileTemplate] = useState('')
  const [profileName, setProfileName]               = useFormFieldState('')
  const [profileDescription, setProfileDescription] = useFormFieldState('')
  const [profileNameError, setProfileNameError] = useState(false)
  const [profileDescriptionError, setProfileDescriptionError] = useState(false)

  const breadCrumbHome = {
  
    name: 'home',
    href: '/company/dashboard',
    current: false
  }

  const breadCrumbPaths: INavigationMainItem[] = [
    {
      name: profileName,
      href: '/company/matching/'+uid,
      current: true
    },
    {
      name: t('editProfile.headlineBreadcrumbTwo'),
      href: '/company/matching/editprofile/'+uid,
      current: true
    }
  ]

  //console.log(userCompetenceData)

  useEffect(() => {
    const requestProfile = async () => {
      try {
        const getProfile = UseGetProfile()
        let profile = await getProfile(uid)
        if(profile) {
          setUserCompetenceData(profile.data)
          setProfile(profile)
          setProfileName(profile.name)
          setProfileDescription(profile.description)
          setProfileTemplate(profile.template)
        }
      } catch (err) {
        console.log(err)
      }
    }
    requestProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[uid])


  const handleCompetenceOnChange = (event: any) => {
    event.preventDefault()
    if(userCompetenceData[event.target.title] && userCompetenceData[event.target.title] === event.target.id) {
      let tempUserCompetenceData: IUserData = userCompetenceData;
      delete tempUserCompetenceData[event.target.title]
      setUserCompetenceData(Object.assign({}, tempUserCompetenceData))
    } else {
      let tempUserCompetenceData: IUserData = userCompetenceData;
      tempUserCompetenceData![event.target.title] = event.target.id;
      setUserCompetenceData(Object.assign({}, tempUserCompetenceData))
      //console.log('competenceData', userCompetenceData, tempUserCompetenceData)
      //console.log(userCompetenceData);
    }
  }


  const updateProfile = useUpdateProfile()
  const handleUpdateProfile = async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()

      if(profileName !== '') {
        setProfileNameError(false)
      } else {
        setProfileNameError(true)
      }

      if(profileDescription !== '') {
        setProfileDescriptionError(false)
      } else {
        setProfileDescriptionError(true)
      }

      if (profileName === "" || profileDescription === "") {
        window.scrollTo({top: 0, behavior: 'smooth'});
        return
      }

      try {
          let pName = (profileName==='')?profile.name:profileName;
          let pDescription = (profileDescription==='')?profile.description:profileDescription;
          await updateProfile(uid, userCompetenceData, pName, pDescription, Date.now())
          history.push('/company/matching/'+uid)
      } catch (err) {
          console.log(err)
          return
      }
  }

  const deleteProfile = useDeleteProfile()
  const handleDeleteProfile = async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      try {
          await deleteProfile(uid)
          history.push('/company/matching/'+uid)
      } catch (err) {
          console.log(err)
          return
      }
  }

  const [ isExpanded, setExpanded ] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
  function handleOnClick() {
      setExpanded(!isExpanded);
  }

  let competenceData: ICompetenceData | null = null;

  if(resultLocalesStandard) {
    competenceData = transformToDataObject(resultLocalesStandard[standardLanguage])
    //console.log(competenceData)
  }
  
  return competenceData ? (
    <>
      <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
      <div className="py-10">
        <header className="mt-16">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="pt-2 pb-4">
              <BreadcrumbNavigation homePath={breadCrumbHome} currentPaths={breadCrumbPaths}></BreadcrumbNavigation>
            </div>
            <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal pb-2">{t('editProfile.headline')}</h1>
          </div>
        </header>
        <main>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

          <div className="mt-6">
              <p className=" text-sm font-small text-gray-600">
              { (profileTemplate)? t('editProfile.profileTemplate') + profileTemplate : '' }
              </p>
            </div>

            {/* Input Felder*/}
            <div className="flex flex-col md:flex-row justify-between mb-2 lg:mb-8">
              <div className="w-1/2 mt-6 pr-8">
                <label htmlFor="name" className="block text-md font-medium text-gray-600">
                {t('newProfile.labelName')}
                </label>
                <div className="mt-2 border-b border-gray-300 focus-within:border-uw-teal">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={profileName}
                    onChange={setProfileName}
                    className="block w-full border-0 border-b text-uw-teal border-transparent focus:border-uw-teal focus:ring-0 sm:text-md text-xl"
                  />
                </div>
                <div className={(profileNameError === true ? 'absolute' : 'absolute hidden')}>
                  <ExclamationCircleIcon className="h-5 w-5 mt-4 text-red-500" aria-hidden="true" />
                </div>
                <div>
                  <p className={(profileNameError === true ? 'text-sm mt-4 ml-6 text-red-600' : 'text-sm mt-4 ml-6 text-red-600 hidden')} id="surname-error">
                    {t('errors.profileName')}
                  </p>
                </div>
              </div>

              <div className="w-1/2 mt-6">
                <label htmlFor="description" className="block text-md font-medium text-gray-600">
                {t('newProfile.labelDescription')}
                </label>
                <div className="mt-2 border-b border-gray-300 focus-within:border-uw-teal">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={profileDescription}
                    onChange={setProfileDescription}
                    className="block w-full border-0 border-b text-uw-teal border-transparent focus:border-uw-teal focus:ring-0 sm:text-md text-xl"
                  />
                </div>
                <div className={(profileDescriptionError === true ? 'absolute' : 'absolute hidden')}>
                  <ExclamationCircleIcon className="h-5 w-5 mt-4 text-red-500" aria-hidden="true" />
                </div>
                <div>
                  <p className={(profileDescriptionError === true ? 'text-sm mt-4 ml-6 text-red-600' : 'text-sm mt-4 ml-6 text-red-600 hidden')} id="surname-error">
                    {t('errors.profileName')}
                  </p>
                </div>
              </div>
            </div>



            <div className="py-4 sm:py-5 flex items-center">
              <h2 className="text-2xl font-medium text-uw-teal">{t('newProfile.headlineTwo')}</h2>
              <div {...getToggleProps({onClick: handleOnClick})} className="flex justify-start pl-2">
                <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </div>
            <div {...getCollapseProps()}>
              <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3">
                <div className="flex text-md text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">
                    {t('newProfile.infoText')}
                  </span>            
                </div>    
              </div>
            </div>
             
            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[2].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[25]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[2]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[35]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[5].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[19]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[6]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[38]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[29]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[7].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[27]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[20]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[3].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[9]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[3]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[37]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[31]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[26]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[21]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[17]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[13]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[15]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[4].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[4]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[36]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[5]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[30]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[23]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[34]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[8].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[10]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[33]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[28]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[24]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[14]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[12]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[6].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[7]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[39]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[8]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[1].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[1]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[32]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[22]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[18]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[16]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[11]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="flex justify-end pt-8"> 
            <button
              type="button"
              onClick={handleDeleteProfile}
              className="items-center px-4 py-2 mr-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-red-600 bg-white hover:text-white hover:bg-red-600 focus:outline-none"
            >
              {t('editProfile.buttonDeleteProfile')}
            </button>
            <button
              type="button"
              onClick={handleUpdateProfile}
              className="items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-uw-teal hover:bg-gray-600 focus:outline-none"
            >
              {t('newProfile.buttonSaveProfile')}
            </button>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  ) : (<><LoadingScreenNew /></>)
}

export default CompanyRequirementProfileEditView