import React from 'react'

interface Props {
    height?: string
    width?: string
}

const IconRight: React.FC<Props> = ({height, width}) => {
    return (
        <svg width={width !== undefined ? width : "19px"} height={height !== undefined ? height : "17px"} viewBox="0 0 19 17" version="1.1">
            <title>Path Copy 5</title>
            <desc>Created with Sketch.</desc>
            <g id="Mobil" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                <g id="Test_mobil-Design" transform="translate(-220.000000, -28.000000)" stroke="#7ED321" strokeWidth="3">
                    <path d="M221.506888,36.7753578 C225.525479,40.9442802 227.645436,43.0191046 227.86676,42.9998307 C228.088084,42.9803779 230.968126,38.3137676 236.506888,28.9999998" id="Path-Copy-5" transform="translate(229.006888, 35.999915) rotate(5.000000) translate(-229.006888, -35.999915) "></path>
                </g>
            </g>
        </svg>
    )
}

export default IconRight