import React from 'react'
import CustomMenu from '../layout/Menu/CustomMenu'
import Footer from "../layout/FooterNew"
import { INavigation, INavigationMainItem, setMainMenuIdActive } from "../layout/Menu/CustomMenu"
import BreadcrumbNavigation from '../layout/Menu/BreadcrumbNavigation'
import {useTranslation} from "react-i18next"
import H2 from "../textStyling/H2";
import FaqDropdown from "../layout/FaqDropdown";


const CompanyFaqView: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

    const { t } = useTranslation(['companyFaq', 'basics'])
    const titlesPartOne = t('faqScreen.questionsPartOne') as Array<string>
    const textsPartOne = t('faqScreen.answersPartOne') as Array<Array<string>>
    const subtitlesPartOne = t('faqScreen.subTitlesPartOne') as Array<Array<string>>
    const titlesPartTwo = t('faqScreen.questionsPartTwo') as Array<string>
    const subtitlesPartTwo = t('faqScreen.subtitlesPartTwo') as Array<Array<string>>
    const textsPartTwo = t('faqScreen.answersPartTwo') as Array<Array<string>>
    const titlesPartThree = t('faqScreen.questionsPartThree') as Array<string>
    const subtitlesPartThree = t('faqScreen.subtitlesPartThree') as Array<Array<string>>
    const textsPartThree = t('faqScreen.answersPartThree') as Array<Array<string>>
    const titlesPartFour = t('faqScreen.questionsPartFour') as Array<string>
    const subtitlesPartFour = t('faqScreen.subtitlesPartFour') as Array<Array<string>>
    const textsPartFour = t('faqScreen.answersPartFour') as Array<Array<string>>

    setMainMenuIdActive(mainNavItems)

    const breadCrumbHome = {
        name: 'home',
        href: '/company/dashboard',
        current: false
    }

    const breadCrumbPaths: INavigationMainItem[] = [
        {
            name: t('faqScreen.headlineBreadcrumb'),
            href: '/company/faq/',
            current: true
        }
    ]

    return (
            <>
                <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} /> 
                <div className="py-10">
                    <header className="mt-16">
                        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="pt-2 pb-4">
                                <BreadcrumbNavigation homePath={ breadCrumbHome } currentPaths={ breadCrumbPaths }></BreadcrumbNavigation>
                            </div>
                            <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal pb-10">{ t('faqScreen.headline') }</h1>
                        </div>
                    </header>
                    <main className="mt-10">
                        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

                            <div className="lg:flex xl:flex lg:flex-col xl:flex-col">
                                <div className="lg:flex xl:flex lg:flex-row xl:flex-row lg:mb-16 xl:mb-16">
                                    <div className="mb-8 lg:mb-0 xl:mb-0 lg:pt-4 xl:pt-4 lg:w-4/12 xl:w-4/12">
                                        <H2 color="uw-teal">
                                            {t("faqScreen.questionsPartOneHeadline")}
                                        </H2>
                                    </div>
                                    <div
                                        className="lg:flex xl:flex lg:flex-col xl:flex-col lg:flex-grow xl:flex-grow lg:w-8/12 xl:w-8/12">
                                        <hr className="border-uw-teal border w-full"/>
                                        {titlesPartOne.map((title, index) => (
                                           <FaqDropdown key={index} headline={title} subTitles={subtitlesPartOne[index]} texts={textsPartOne[index]}/>
                                        ))}
                                    </div>
                                </div>
                                <div className="lg:flex xl:flex lg:flex-row xl:flex-row">
                                    <div className="my-8 lg:my-0 xl:my-0 lg:pt-4 xl:pt-4 lg:w-4/12 xl:w-4/12">
                                        <H2 color="uw-teal">
                                            {t("faqScreen.questionsPartTwoHeadline")}
                                        </H2>
                                    </div>
                                    <div
                                        className="lg:flex xl:flex lg:flex-col xl:flex-col lg:flex-grow xl:flex-grow lg:w-8/12 xl:w-8/12 mb-24">
                                        <hr className="border-uw-teal border w-full"/>
                                        {titlesPartTwo.map((title, index) => (
                                            <FaqDropdown key={index} headline={title} subTitles={subtitlesPartTwo[index]} texts={textsPartTwo[index]}/>
                                        ))}
                                    </div>
                                </div>
                                <div className="lg:flex xl:flex lg:flex-row xl:flex-row">
                                    <div className="my-8 lg:my-0 xl:my-0 lg:pt-4 xl:pt-4 lg:w-4/12 xl:w-4/12">
                                        <H2 color="uw-teal">
                                            {t("faqScreen.questionsPartThreeHeadline")}
                                        </H2>
                                    </div>
                                    <div
                                        className="lg:flex xl:flex lg:flex-col xl:flex-col lg:flex-grow xl:flex-grow lg:w-8/12 xl:w-8/12 mb-24">
                                        <hr className="border-uw-teal border w-full"/>
                                        {titlesPartThree.map((title, index) => (
                                            <FaqDropdown key={index} headline={title} subTitles={subtitlesPartThree[index]} texts={textsPartThree[index]}/>
                                        ))}
                                    </div>
                                </div>
                                <div className="lg:flex xl:flex lg:flex-row xl:flex-row">
                                    <div className="my-8 lg:my-0 xl:my-0 lg:pt-4 xl:pt-4 lg:w-4/12 xl:w-4/12">
                                        <H2 color="uw-teal">
                                            {t("faqScreen.questionsPartFourHeadline")}
                                        </H2>
                                    </div>
                                    <div
                                        className="lg:flex xl:flex lg:flex-col xl:flex-col lg:flex-grow xl:flex-grow lg:w-8/12 xl:w-8/12 mb-24">
                                        <hr className="border-uw-teal border w-full"/>
                                        {titlesPartFour.map((title, index) => (
                                            <FaqDropdown key={index} headline={title} subTitles={subtitlesPartFour[index]} texts={textsPartFour[index]}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        </main>
                </div>
                <Footer />
            </>
            )
}

export default CompanyFaqView;