import React from 'react'
import { PieChart, Pie } from 'recharts';

interface Props {
    points: number
}

const EmployeeInnerCardStatisticsChart: React.FC<Props> = ({points}) => {

    let data = [
        { name: 'Group A', value: 300, className: "" }, { name: 'Group B', value: 300, className: "" },
        { name: 'Group C', value: 300, className: "" }, { name: 'Group D', value: 300, className: "" },
        { name: 'Group E', value: 300, className: "" }
    ];

    const polishData = () => {
        for(let i = 0; i < data.length; i++) {
            if (i+1 !== points) {
                data[i].className = "opacity-2"
            }
        }
        return data
    }

    return (
        <PieChart width={200} height={150} margin={{top: 75}}>
            <Pie dataKey="value" startAngle={180} endAngle={0} data={polishData()} cx={'50%'} cy={'50%'} outerRadius={90} fill="#008080" />
        </PieChart>
    );
};

export default EmployeeInnerCardStatisticsChart;