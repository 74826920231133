import React from "react";
import classnames from "classnames"
import EmployeeInnerCardStatisticsChart from "./EmployeeInnerCardStatisticsChart";
import Text from "../../textStyling/Text";

interface Props {
    cardPoints: number
    explanationText: string | Array<string>
    roundedFull?: boolean
    traitLevelHigh?: string
    traitLevelLow?: string
    small?: boolean
    medium?: boolean
}

const EmployeeInnerCardStatistics: React.FC<Props> = ({cardPoints, explanationText, roundedFull, traitLevelLow, traitLevelHigh, small, medium}) => {

    return (
        <>
            <div
                className={classnames("bg-white flex rounded-bl-15px h-450px flex-grow-0 rounded-r-15px shadow-desktopResultsRightCard px-4 pt-2 pb-3",  {
                    "rounded-tl-15px" : roundedFull,
                    "self-end" : small,
                    "self-center": medium,
                    "mb-4": small, 
                    "h-450px" : !small && small !== undefined,
                })
                }>
                <div className="flex flex-grow flex-col items-center justify-center py-4">
                    <div className="flex flex-row items-center">
                        <div className="text-center w-250px small h1Mobile">
                            <Text color="text-uw-grey"> 
                                 {traitLevelLow}
                            </Text>
                        </div> 
                        <div className="m-3">
                            <EmployeeInnerCardStatisticsChart points={cardPoints}/>
                        </div>
                        <div id="foobar" className="text-center w-250px small h1Mobile">
                            <Text color="text-uw-grey"> 
                               {traitLevelHigh}
                            </Text>
                        </div>
                    </div>
                    <div className="flex text-center mx-8">
                        <Text color="text-uw-grey" three>
                            {explanationText}
                        </Text>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeInnerCardStatistics;