
import React from 'react'
import { useTranslation } from "react-i18next"
import Employee, { IEmployee } from './Employee'


interface Employees {
  employees: IEmployee[] | null | any
}

const EmployeeList: React.FC<Employees> = ({ employees }) => {

  const { t } = useTranslation('employeeList')

  return (
    <>
      <div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 table-fixed">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="w-1/4 pl-8 px-6 py-3 text-left text-xs font-medium text-gray-500 hover:text-uw-teal uppercase tracking-wider"
                    >
                      {t('rowOne.userName')}
                    </th>
                    <th
                      scope="col"
                      className="w-1/2 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t('rowTwo.userPosition')}
                    </th>
                    <th
                      scope="col"
                      className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t('rowThree.testStatusHeadline')}
                    </th>
                    <th
                      scope="col"
                      className="w-1/5 relative px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <span className="px-6 py-3">{t('rowFour.userResult')}</span>
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {employees ? employees.map((employee: IEmployee, employeeIdx: any) => (
                    <Employee key={employee.id} employee={employee} employeeIdx={employeeIdx} />
                  )) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}


export default EmployeeList
