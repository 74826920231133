// React
import React, { useState } from 'react'
import { Redirect } from "react-router"
import { useTranslation } from "react-i18next"
// Components
import Container from "../components/layout/Container"
import H1 from "../components/textStyling/H1"
import Text from "../components/textStyling/Text"
import Button from "../components/layout/Button"
import H2 from "../components/textStyling/H2"

const CompanyRegistrationConfirmationScreen: React.FC = () => {

  const [logout, setLogout] = useState(false)
  const { t } = useTranslation(['companyRegistrationConfirmation', 'basics'])

  return (
    <>
      {logout && <Redirect push to="/logout" />}
      <Container background="white" headerFooter logout>
        {/*Mobile*/}
        <div className="lg:hidden xl:hidden">
          <div className="mb-10 mt-12 flex justify-center text-uw-teal">
            <H1 color="uw-teal">
              {t('headlinePartOne')}
              <br />
              {t('headlinePartTwo')}
            </H1>
          </div>
          <H2 color="uw-teal">
            {t('nextSteps')}
          </H2>
          <div className="my-8 text-black">
            <Text color="text-uw-grey">{t('text')}</Text>
          </div>
          <div className="mb-8">
            <Button title={t('logout')} type="button" onClick={setLogout} />
          </div>
        </div>
        {/*Desktop*/}
        <div className="hidden lg:block xl:block">
          <div className="mb-24 mt-32 flex text-uw-teal w-3/5">
            <H1 color="uw-teal">
              {t('headlinePartOne')}
              <br />
              {t('headlinePartTwo')}
            </H1>
          </div>
          <div className="flex flex-row justify-between">
            <div>
              <H2 color="uw-teal">
                {t('nextSteps')}
              </H2>
            </div>
            <div className="flex flex-col w-7/12 mr-2">
              <div className="mb-8 text-black">
                <Text color="text-uw-grey" one>{t('text')}</Text>
              </div>
              <div className="mb-24">
                <Button title={t('logout')} type="button" onClick={setLogout} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default CompanyRegistrationConfirmationScreen