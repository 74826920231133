import React from 'react'
import CompanyIndividualDevelopmentView from "../components/companyDashbaord/CompanyIndividualDevelopmentView"
import { INavigation } from "../components/layout/Menu/CustomMenu"


const CompanyIndividualDevelopment: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  return (
    <>
      <CompanyIndividualDevelopmentView mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
    </>
  )
}

export default CompanyIndividualDevelopment