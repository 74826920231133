//React
import { useState, useEffect, useCallback } from "react"
//Firebase
import { firestore } from "../firebase/Firebase"
import { collection, getDocs, where, query } from "@firebase/firestore"
import { useUserContext } from "../firebase/FirebaseContext";
//Components
import { IEmployee, TestStatus } from '../components/layout/Employee/Employee'

export const useGetAllEmployees = (): [IEmployee[] | null, (Employees: IEmployee[] | null) => void, IEmployee[] | null, (Employees: IEmployee[] | null) => void, IEmployee[] | null, (Employees: IEmployee[] | null) => void] => {
    
  const {companyUser} = useUserContext()
  const [allEmployees, setFunctionAll] = useState<IEmployee[] | null>(null)
  const [openEmployees, setFunctionOpen] = useState<IEmployee[] | null>(null)
  const [doneEmployees, setFunctionDone] = useState<IEmployee[] | null>(null)

  const setAllEmployees = (Employees: IEmployee[] | null) => {
    setFunctionAll(Employees);
  }

  const setOpenEmployees = (Employees: IEmployee[] | null) => {
    setFunctionOpen(Employees);
  }

  const setDoneEmployees = (Employees: IEmployee[] | null) => {
    setFunctionDone(Employees);
  }

  const getEmployees = useCallback(async () => {
    if(companyUser){
      const employeesRef = collection(firestore, 'employees')
      const q = query(employeesRef, where('testGroups', '==',companyUser?.testGroups), where('role', '==', 'EMPLOYEE'))
      const querySnapshot = await getDocs(q)
      const employeeItemsAll: IEmployee[] = [];
      const employeeItemsOpen: IEmployee[] = [];
      const employeeItemsDone: IEmployee[] = [];
      querySnapshot.forEach((doc) => {
        let testStatus = TestStatus.OPEN

        if(doc.data().test && doc.data().test.testDone === true && doc.data().test.receivedResults === false) {
          testStatus = TestStatus.IN_PROGRESS
        }

        if (doc.data().test && doc.data().test.testDone === true && doc.data().test.receivedResults === true) {
          testStatus = TestStatus.DONE
        }

        const employeeItem: IEmployee = {
          id: doc.data().uid,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          language: doc.data().language,
          test: doc.data().test,
          email: doc.data().email,
          testStatus: testStatus,
          department: doc.data().department,
          jobTitle: doc.data().jobTitle
        }
        employeeItemsAll.push(employeeItem);
        if(employeeItem.test) {
          employeeItemsDone.push(employeeItem);
        } else {
          employeeItemsOpen.push(employeeItem);
        }
      })
      setAllEmployees(employeeItemsAll);
      setOpenEmployees(employeeItemsOpen);
      setDoneEmployees(employeeItemsDone);
    }
  }, [companyUser])

  useEffect(() => {
    getEmployees()
  }, [getEmployees])

  return [allEmployees, setAllEmployees, openEmployees, setFunctionOpen, doneEmployees, setFunctionDone]
}
