// Firebase
import { useUserContext } from "../firebase/FirebaseContext";
import { firestore } from "../firebase/Firebase"

import { doc, addDoc, collection, Timestamp } from 'firebase/firestore'

export interface IUserData {
  [index: string]: number
}

export interface IJob {
  template?: string,
  company?: any,
  name: string,
  description: string,
  data: IUserData,
  updated?: number | Timestamp,
  status: [
    {
      date: number | Timestamp,
      status: EJobStatus
    }
  ],
  testGroup?: any,
  privacy?: boolean,
  privacyLink?: string
}

export enum EJobStatus {
  ALL = "ALL",
  NEW = "NEW",
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
  DEACTIVATED = "DEACTIVATED",
  HIRED = "HIRED",
  NOTHING = "NOTHING"
}


export const useSaveJob = () => {

  const {companyUser} = useUserContext()

  const saveJob =  async (profile : IJob) => {

    // get company reference
    const companyDoc = doc(firestore, 'companies', companyUser!.companyId)
    const testGroupRef = doc(firestore, 'testGroups', companyUser!.testGroups[0].id)

    // Add company reference to profile
    profile.company = companyDoc
    profile.testGroup = testGroupRef

    // Add Timestamp
    profile.updated = new Timestamp(Math.floor(Number(profile.updated) / 1000), 0)

    //const profileDoc = doc(firestore, 'profiles');
    await addDoc(collection(firestore, 'jobs'), profile);

  }

  return saveJob
}
