import React from 'react'

interface Props {
    color: "uw-teal" | "white" | "uw-grey"
}

const H2: React.FC<Props> = ({color, children}) => {
    return (
        <>
            <div className="lg:hidden xl:hidden">
                <h2 className={`font-headlines text-${color} font-medium text-h2h3Mobile leading-h2h3Mobile`}>{children}</h2>
            </div>
            <div className="hidden lg:block xl:block">
                <h2 className={`font-headlines text-${color} font-medium text-h2Desktop leading-h2Desktop`}>{children}</h2>
            </div>
            </>

    )
}

export default H2;