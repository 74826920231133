//Firebase
import { collection, getDocs, query, where } from "firebase/firestore"
import { useState, useEffect } from "react"
import { firestore } from "../firebase/Firebase"
import { useUserContext } from "../firebase/FirebaseContext"
//Components
import { IEmployee } from '../components/layout/Employee/Employee'


export const useGetEmployee = (uid:string | undefined) => {
  const {companyUser, employeeUser} = useUserContext()
  const [employee, setEmployee] = useState<IEmployee>({ loadingStatus: true, found: false } as  IEmployee)
  useEffect(() => {
    (async () => {
      const employeesRef = collection(firestore, 'employees')
      //console.log(companyUser)
      const q = query(
        employeesRef, 
        where('testGroups', '==', companyUser!.testGroups),
        where('uid', '==', uid)
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        const employeeItem: IEmployee = {
          id: doc.data().uid,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          language: doc.data().language,
          role: employeeUser?.role,
          test: doc.data().test,
          email: doc.data().test,
          jobTitle: doc.data().jobTitle,
          department: doc.data().department,
          // loadingStatus: false,
          found: true
      }
        setEmployee(employeeItem);
      })
    // setEmployee(employee => ({...employee, ...{ loadingStatus: false }}));
    })()
  }, [companyUser, employeeUser, uid])

  return employee
}