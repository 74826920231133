// Firebase
//import { useUserContext } from "../firebase/FirebaseContext";
import { firestore } from "../firebase/Firebase"
//import { useState } from "react"

import { getDoc, doc } from "firebase/firestore";
import { EProfileStatus } from "./useGetProfiles";

export interface IUserData {
  [index: string]: number
}

export interface IProfile {
  template?: string,
  id: string,
  company?: any,
  name: string,
  description: string,
  data: IUserData,
  status?: string,
  statusArr: any[],
}


export const UseGetProfile = (): any => {

  const getProfiles = async (profileId: string) => {

    const docRef = doc(firestore, "profiles", profileId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {

      let status = EProfileStatus.IN_WORK
      let statusArr: any = []

      if(docSnap.data().status && Array.isArray(docSnap.data().status)) {
        status = docSnap.data().status[docSnap.data().status.length-1].status
        statusArr = docSnap.data().status
      }

      let profile: IProfile = {
        template: docSnap.data().template,
        id: docSnap.id,
        name: docSnap.data().name,
        description: docSnap.data().description,
        data: docSnap.data().data,
        status: status,
        statusArr: statusArr
      }

      return profile

    }

    return false;

  }

  return getProfiles
}
