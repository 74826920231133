import React, {useCallback, useState} from 'react'
import classnames from "classnames";
import H4 from "../../textStyling/H4";
import IconRectangle from "../../design/IconRectangle";
import Text from "../../textStyling/Text";

interface IProps {
    lastCard?: boolean
    mainCardOpened: boolean
    mainTitle: string
    subTitles: Array<string>
    texts: Array<string>
}

const EmployeeCardSubPart: React.FC<IProps> = ({lastCard, mainCardOpened, mainTitle, subTitles, texts}) => {

    const [subPartOpened, setSubPartOpen] = useState(false)
    const showText = "mt-4"
    const toggleExpand = useCallback(() => setSubPartOpen(!subPartOpened), [setSubPartOpen, subPartOpened])
    const uuidv1 = require('uuid/v1')

    const generateSubText = () => {
        let subText: Array<object> = []
        let counterForBr = 0
        for (let i = 0; i < subTitles.length; i++) {
            subText.push(
                <div key={uuidv1()}>
                    <div className="mb-2">
                        <Text fat color="text-uw-grey">
                            {subTitles[i]}
                        </Text>
                    </div>
                    <div>
                        <Text color="text-uw-grey">
                            {texts[i]}
                        </Text>
                    </div>
                    {counterForBr % 2 === 0 && <br/>}
                </div>
            )
            counterForBr++
        }
        return subText
    }

    return (
        <>
            <div className={classnames({
                "hidden": !mainCardOpened
            })}>
                <div className={classnames("bg-white rounded-15px shadow-cardShadow px-6 py-6", {
                    'mb-4': lastCard === false || lastCard === undefined,
                    'mb-2': lastCard === true
                })}>
                    <div className="flex flex-row" onClick={toggleExpand}>
                        <div className="flex flex-1 justify-start">
                            <H4 color="uw-teal">
                                {mainTitle}
                            </H4>
                        </div>
                        <div className="flex flex-1 justify-end">
                            <div>
                                {subPartOpened &&
                                    <div className="rotate-180">
                                        <IconRectangle/>
                                    </div>
                                }
                                {!subPartOpened && <IconRectangle/>}
                            </div>
                        </div>
                    </div>
                    <div className={classnames(showText, {
                            "hidden pb-2": !subPartOpened
                        }
                    )}
                    >
                        {generateSubText()}
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeCardSubPart