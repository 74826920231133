import React, { useEffect } from 'react'
import CustomMenu from '../layout/Menu/CustomMenu'
import Footer from "../layout/FooterNew"
import { INavigation, INavigationMainItem, setMainMenuIdActive, MAIN_MENU_IDS } from "../layout/Menu/CustomMenu"
import BreadcrumbNavigation from '../layout/Menu/BreadcrumbNavigation'
//import { CheckCircleIcon } from '@heroicons/react/solid'
import MatchingList from "../layout/MatchingList"
//import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
//import { UseGetJobs, IJob } from '../../hooks/useGetJobs';
//import { UseGetMatchingCandidates, IMatchingCandidate } from '../../hooks/useGetMatchingCandidates';
import { UseGetMatchingEmployees, IMatchingEmployee } from '../../hooks/useGetMatchingEmployees';
//import { UseGetJob, IJob } from '../../hooks/useGetJob'
//import { useUserContext } from '../../firebase/FirebaseContext'
import { scoreEmployees } from '../../helpers/scoreEmployees'
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom';
import {
  CheckIcon,
  ChevronDownIcon,
  PencilIcon,
  ChevronLeftIcon,
  TrashIcon
} from '@heroicons/react/solid'
import { UseGetProfile, IProfile } from '../../hooks/useGetProfile'
import { useDeleteProfile } from '../../hooks/useDeleteProfile'
import { useGetProfileStats, IProfileStats } from "../../hooks/useGetProfileStats"
//import { EJobStatus } from '../../hooks/useSaveJob'
import { useChangeProfileStatus } from '../../hooks/useChangeProfileStatus'
import { Timestamp } from 'firebase/firestore'
import { useHistory } from "react-router-dom"
//import { useUserContext } from "../../firebase/FirebaseContext"



import {useTranslation} from "react-i18next"
//import { ITabs } from '../layout/Employee/EmployeeListHeadingCards'

import { EProfileStatus, EStatus } from "../../hooks/useGetProfiles";

interface ICandidateStats {
  ALL: number,
  INTERVIEW: number,
  OFFER: number,
  HIRED: number,
  FIRED: number,
  NOTHING: number
}

interface ITab {
  id: EStatus,
  name: string,
  href: string,
  current: boolean,
  count: any,
  onclick: any
}

const CompanyMatchingView: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

    const { t } = useTranslation('companyEmployeeResults')

    interface ParamTypes {
      profileid: string
    }
    const { profileid } = useParams<ParamTypes>()

    const jobDataTemp = useGetProfileStats(profileid)
    const changeProfileStatus = useChangeProfileStatus()

    //const [profileId, setProfileId] = useState<any>(null)
    const [jobData, setJobData] = useState<IProfileStats>()
    const [allMatches, setAllMatches] = useState<IMatchingEmployee[]>([])
    const [filteredMatches, setFilteredMatches] = useState<IMatchingEmployee[]>([])
    const [filter, setFilter] = useState<EStatus>(EStatus.ALL)
    const [candidateStats, setCandidateStats] = useState<ICandidateStats | any>({ALL: 0, PRE_SELECTED:0, INTERVIEW: 0, SELECTED: 0, REJECTED: 0, NOTHING: 0})
    //const [jobStatus, setJobStatus] = useState<EJobStatus>(EJobStatus.DRAFT)
    //const getAllMatchingCandidates = UseGetMatchingCandidates()
    //const [profile, setProfile] = useState<any>(null)
    const getProfile = UseGetProfile()
    //setJobData(jobDataTemp)

    const getAllMatchingEmployees = UseGetMatchingEmployees()

    //const {companyUser} = useUserContext()

    setMainMenuIdActive(mainNavItems, MAIN_MENU_IDS.DASHBOARD_COMPANY) 

    const initTabs: ITab[] = [
      { id: EStatus.ALL, name: t('companyRecruitingJobProfile:candidateStatus.APPLIED'), href: '#', count: jobData?.countStats.all, current: true, onclick: () => { filterProfiles(EStatus.ALL) } },
      //{ id: EStatus.NOTHING, name: t('companyRecruitingJobProfile:candidateStatus.NOTHING'), href: '#', count: jobData?.countStats.pre_selected, current: false , onclick: () => { filterProfiles(EStatus.NOTHING) }},
      { id: EStatus.PRE_SELECTED, name: t('companyRecruitingJobProfile:candidateStatus.PRE_SELECTED'), href: '#', count: jobData?.countStats.nothing, current: false , onclick: () => { filterProfiles(EStatus.PRE_SELECTED) }},
      { id: EStatus.INTERVIEW, name: t('companyRecruitingJobProfile:candidateStatus.INTERVIEW'), href: '#', count: jobData?.countStats.interview, current: false, onclick: () => { filterProfiles(EStatus.INTERVIEW) } },
      { id: EStatus.SELECTED, name: t('companyRecruitingJobProfile:candidateStatus.SELECTED'), href: '#', count: jobData?.countStats.selected, current: false, onclick: () => { filterProfiles(EStatus.SELECTED) } },
      { id: EStatus.REJECTED, name: t('companyRecruitingJobProfile:candidateStatus.REJECTED'), href: '#', count: jobData?.countStats.rejected, current: false, onclick: () => { filterProfiles(EStatus.REJECTED) } },
    ]

    const [tabs, setTabs] = useState<ITab[]>(initTabs)

    const publishingOptions = [
      //{ id: EProfileStatus.ALL, name: t('profileItems.status.ALL'), description: t('profileItems.status.ALL-Description'), current: false },
      { id: EProfileStatus.NEW, name: t('profileItems.status.NEW'), description: t('profileItems.status.NEW-Description'), current: false },
      { id: EProfileStatus.IN_WORK, name: t('profileItems.status.IN_WORK'), description: t('profileItems.status.IN_WORK-Description'), current: false },
      { id: EProfileStatus.HIRED, name: t('profileItems.status.HIRED'), description: t('profileItems.status.HIRED-Description'), current: false },
    ]

    const [selected, setSelected] = useState(publishingOptions[0])
    const [profile, setProfile] = useState<IProfile | undefined>()

    let history = useHistory()

    useEffect(() => {

      const requestProfiles = async () => {
        if(profileid) {
          try {
            let tempProfile = await getProfile(profileid)
            if(tempProfile) {
              setProfile(tempProfile)
              let jobOption = publishingOptions.find(element => element.id === tempProfile.status)
              if(jobOption)
                setSelected(jobOption)
              let allMatchingCandidates: IMatchingEmployee[] = await getAllMatchingEmployees(profileid)
              //console.log('allMatchingEmployees', allMatchingCandidates)
              let scoredEmployees: IMatchingEmployee[] = scoreEmployees(tempProfile, allMatchingCandidates)
              setAllMatches(scoredEmployees)
              setFilteredMatches(scoredEmployees)
              //filterProfiles(filter)
              //setFilteredMatches(scoredEmployees)
            }
          } catch (err) {
              console.log(err)
              return
          }
        }
      }

      setJobData(jobDataTemp!)
      setCandidateStats(jobDataTemp?.countStats)

      if(jobDataTemp && allMatches.length<=0) {
        requestProfiles()
      }
        
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobDataTemp])
    
    useEffect(() => {
      filterProfiles(filter)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allMatches, filter])


    //const { t } = useTranslation(['companyRecruitingDashboard']);
    //console.log(filteredMatches)

    const filterProfiles = (status: EStatus) => {
      setFilter(status)
      if(status === EStatus.ALL) {
        setFilteredMatches(allMatches)
      } else {
        let tempMatches: IMatchingEmployee[] = Object.assign(allMatches)
        tempMatches = allMatches.filter((element) => { return element.status === status })
        setFilteredMatches(tempMatches)
      }
      
      let tempTabs = tabs.filter((element) => {
        if(element.id === status)
          element.current = true
        else
          element.current = false
        return element })

        //console.log('tempTabs', tempTabs)
        setTabs(tempTabs)
        
    }

    const handleChangeJobStatus = (e: any) => {
      setSelected(e)
      if(profile) {
        if(profile.status === e.id)
          return
        profile.statusArr.push(
          { date: new Timestamp(Math.floor(Number(Date.now()) / 1000), 0), status: e.id }
        )
        profile.status = e.id
        changeProfileStatus(profileid, profile.statusArr)

      }
    }

    const deleteProfile = useDeleteProfile()
    const handleDeleteProfile = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        try {
            await deleteProfile(profileid)
            history.push('/company/matching/')
        } catch (err) {
            console.log(err)
            return
        }
    }

    const breadCrumbHome = {
        name: 'home',
        href: '/company/matching',
        current: false
    }

    const breadCrumbPaths: INavigationMainItem[] = [
        {
            name: (profile?.name)?profile?.name:'',
            href: '/company/matching/'+profileid,
            current: true
        }
    ]
    
    
    
    
    function classNames(...classes: string[]) {
      return classes.filter(Boolean).join(' ')
    }
    
    




    return (
            <>
                <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} /> 
                <div className="py-10">
                    <header className="mt-16">
                        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="pt-2 pb-4">
                                <BreadcrumbNavigation homePath={ breadCrumbHome } currentPaths={ breadCrumbPaths }></BreadcrumbNavigation>
                            </div>
                            <div className="grid grid-cols-2">
                              <div>
                              <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal">{ profile?.name }</h1>
                              <p className="text-sm text-uw-teal mt-2">{profile?.description}</p>
                              </div>
                              <div className="mt-5 flex xl:mt-0 xl:ml-4 place-content-end">
                                <span className="hidden sm:block">
                                
                                <Link to={'/company/matching/editprofile/'+profileid}>
                                  <button
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                                  >
                                    <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    {t('companyRecruitingJobProfile:buttonEdit')}
                                  </button>
                                </Link>
                                
                                </span>

                                <span className="hidden sm:block">
                                
                                <Link to={'/company/matching/editprofile/'+profileid}>
                                  <button
                                    type="button"
                                    onClick={handleDeleteProfile}
                                    className="inline-flex items-center px-4 py-2 ml-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                                  >
                                    <TrashIcon className="-ml-2 -mr-2 h-5 w-5 text-gray-400 hover:text-red-600" aria-hidden="true" />
                                  </button>
                                </Link>
                                
                                </span>

                                

                                <div className="sm:ml-3 relative">
                                  <Listbox value={selected} onChange={handleChangeJobStatus}>
                                    {({ open }) => (
                                      <>
                                        <Listbox.Label className="sr-only">Change published status</Listbox.Label>
                                        <div className="relative">
                                          <div className="inline-flex shadow-sm rounded-md divide-x divide-white">
                                            <div className="relative inline-flex shadow-sm rounded-md divide-x divide-white">
                                              <div className="relative inline-flex items-center bg-uw-teal py-2 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                <p className="ml-2.5 text-sm font-medium">{selected.name}</p>
                                              </div>
                                              <Listbox.Button className="relative inline-flex items-center bg-uw-teal p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-gray-600">
                                                <span className="sr-only">Change published status</span>
                                                <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                              </Listbox.Button>
                                            </div>
                                          </div>

                                          <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <Listbox.Options className="origin-top-right absolute left-0 mt-2 -mr-1 z-10 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none sm:left-auto sm:right-0">
                                              {publishingOptions.map((option) => (
                                                <Listbox.Option
                                                  key={option.name}
                                                  className={({ active }) =>
                                                    classNames(
                                                      active ? 'text-white bg-uw-teal' : 'text-gray-900',
                                                      'cursor-default select-none relative p-4 text-sm'
                                                    )
                                                  }
                                                  value={option}
                                                >
                                                  {({ selected, active }) => (
                                                    <div className="flex flex-col">
                                                      <div className="flex justify-between">
                                                        <p className={selected ? 'font-semibold' : 'font-normal'}>{option.name}</p>
                                                        {selected ? (
                                                          <span className={active ? 'text-white' : 'text-uw-teal'}>
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                          </span>
                                                        ) : null}
                                                      </div>
                                                      <p className={classNames(active ? 'text-gray-200' : 'text-gray-500', 'mt-2')}>
                                                        {option.description}
                                                      </p>
                                                    </div>
                                                  )}
                                                </Listbox.Option>
                                              ))}
                                            </Listbox.Options>
                                          </Transition>
                                        </div>
                                      </>
                                    )}
                                  </Listbox>
                                </div>

                                {/* Dropdown */}
                                {/*<Menu as="div" className="ml-3 relative sm:hidden">
                                  <Menu.Button className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50">
                                    More
                                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                                  </Menu.Button>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            href="#"
                                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                          >
                                            Edit
                                          </a>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            href="#"
                                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                          >
                                            View
                                          </a>
                                        )}
                                      </Menu.Item>
                                    </Menu.Items>
                                  </Transition>
                                        </Menu>*/}
                              </div>
                            </div>
                        </div>
                    </header>
                    <main>
                      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="max-w-7xl mx-auto">
                          <div className="px-4 sm:px-0 mt-8">

                            {/* Tabs */}
                            <div className="sm:hidden">
                              <label htmlFor="tabs" className="sr-only">
                                Select a tab
                              </label>
                              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                              <select
                                id="tabs"
                                name="tabs"
                                className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
                                defaultValue={tabs.find((tab) => tab.current).name}
                              >
                                {tabs.map((tab: ITab) => (
                                  <option key={tab.name}>{tab.name}</option>
                                ))}
                              </select>
                            </div>
                            <div className="hidden sm:block">
                              <div className="border-b border-gray-200">
                                <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                                  {tabs.map((tab: ITab) => (
                                    <a
                                      key={tab.name}
                                      href={tab.href}
                                      onClick={tab.onclick}
                                      className={classNames(
                                        tab.current
                                          ? 'border-uw-teal text-uw-teal'
                                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                                      )}
                                    >
                                      {tab.name}
                                      {candidateStats ? (
                                        <span
                                          className={classNames(
                                            tab.current ? 'bg-gray-200 text-uw-teal' : 'bg-gray-100 text-gray-600',
                                            'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                          )}
                                        >
                                          {candidateStats[tab.id]}
                                        </span>
                                      ) : null}
                                    </a>
                                  ))}
                                </nav>
                              </div>
                            </div>
                          </div>

                          {/* Stacked list */}
                          <MatchingList allMatches={filteredMatches} profileid={profileid} showStatus={true}></MatchingList>

                          {/* Pagination können wir in Ausbaustufe einbauen:
                          <nav
                            className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0"
                            aria-label="Pagination"
                          >
                            <div className="-mt-px w-0 flex-1 flex">
                              <a
                                href="#"
                                className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-200"
                              >
                                <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                Previous
                              </a>
                            </div>
                            <div className="hidden md:-mt-px md:flex">
                              <a
                                href="#"
                                className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                              >
                                1
                              </a>
                              {/* Current: "border-purple-500 text-purple-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200" */} {/*
                              <a
                                href="#"
                                className="border-purple-500 text-purple-600 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                                aria-current="page"
                              >
                                2
                              </a>
                              <a
                                href="#"
                                className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                              >
                                3
                              </a>
                              <a
                                href="#"
                                className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                              >
                                4
                              </a>
                              <a
                                href="#"
                                className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                              >
                                5
                              </a>
                              <a
                                href="#"
                                className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                              >
                                6
                              </a>
                            </div>
                            <div className="-mt-px w-0 flex-1 flex justify-end">
                              <a
                                href="#"
                                className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-200"
                              >
                                Next
                                <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                              </a>
                            </div>
                          </nav> */}
                           <div className="pt-8"> 
                           <Link to="/company/matching">
                              <button
                                type="button"
                                className="items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-bg-teal bg-white hover:bg-gray-200 focus:outline-none">
                                <ChevronLeftIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" />
                              </button>
                            </Link>
                          </div>
                        </div> 
                      </div>
                    </main>
                </div>
                <Footer />
            </>
            )
}

export default CompanyMatchingView;