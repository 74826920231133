import React from 'react'
import H2 from "../textStyling/H2";
import { DotLoader } from "react-spinners";
import Container from "./Container";
import { useTranslation } from "react-i18next";

export const LoadingScreen: React.FC = () => {

  const { t } = useTranslation('basics')

  return (
    <>
      {/* TODO: Check how to make this easier with:
        - EmployeeResults Desktop (DotLoader)
        - EmployeeResults Mobile (DotLoader)
        - General LoadingScreen */}
      <Container background="white" headerFooter login logout>
        <div className="h-screen flex justify-center">
          <div className="flex flex-col align-middle justify-center text-center">
            <div className="lg:hidden xl:hidden">
              <H2 color="uw-teal">
                {t('validation.validationOne')}
                <br />
                {t('validation.validationTwo')}
              </H2>
            </div>
            <div className="hidden lg:block xl:block lg:mb-16 xl:mb-16">
              <H2 color="uw-teal">
                {t('validation.validationOne')}
                <br />
                {t('validation.validationTwo')}
              </H2>
            </div>
            <div className="mt-12 flex justify-center">
              <DotLoader color="#008080" loading size={150} />
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default LoadingScreen;
