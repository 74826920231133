import React, { FormEvent, useState } from 'react'
import Container from "../components/layout/Container"
import H1 from "../components/textStyling/H1"
import InputField from "../components/layout/InputField"
import Button from "../components/layout/Button"
import { useFormFieldState } from "../hooks/useFormFieldState"
import { useTranslation } from "react-i18next"
import { DotLoader } from "react-spinners"
import H2 from "../components/textStyling/H2"
import Text from "../components/textStyling/Text"
import { useUserContext } from '../firebase/FirebaseContext'
import { Redirect } from "react-router-dom"


const Login: React.FC = () => {
  const { logIn, currentUser, isLoggedIn } = useUserContext()
  const [awaitingResults, setAwaitingResults] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [email, setEmail] = useFormFieldState('')
  const [password, setPassword] = useFormFieldState('')
  const [formSubmittedToggle, setFormSubmittedToggle] = useState(false)
  const { t } = useTranslation(['login', 'basics'])
  //let loginError = false

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setAwaitingResults(true)
    const lgoinRes = await logIn(email, password)
    //if (errorEmailMsg && errorPasswordMsg) {
    if(lgoinRes === false) {
      setLoginError(true)
      //setAwaitingResults(true)
      setFormSubmittedToggle(!formSubmittedToggle)
      setAwaitingResults(false)
    }
  }

  
  if((isLoggedIn && currentUser)) {
		switch (currentUser!.role) {
			case 'EMPLOYEE':
			  return <Redirect to='/employee' />
      case 'CANDIDATE':
        return <Redirect to='/candidate' />
			case 'COMPANY':
			  return <Redirect to='/company/dashboard' />
      case 'RECRUITING':
        return <Redirect to='/recruiting/dashboard' />
      case 'RECRUITING_DEMO':
        return <Redirect to='/recruiting/dashboard' />
      case 'COMPANY_AND_RECRUITING':
        return <Redirect to='/company/dashboard' />
			case 'LUKSIT_ADMIN':
			  return <Redirect to='/luksit/dashboard' />
		  }
	}

  return (!awaitingResults ?
    <>
      <Container background="white" headerFooter>
        <div
          className="lg:flex xl:flex lg:flex-col xl:flex-col lg:justify-center xl:justify-center lg:h-screen xl:h-screen">
          <div
            className="my-24 lg:mt-40 xl:mt-40 lg:mb-16 xl:mb-16 lg:flex lg:justify-center lg:text-center xl:flex xl:justify-center xl:text-center">
            <div className="lg:w-1/3 xl:w-1/3">
              <H1 color="uw-teal">
                {t('texts.headline')}
              </H1>
            </div>
          </div>
          <div className="mb-48 lg:flex lg:justify-center xl:flex xl:justify-center">
            <form className="lg:w-1/4 xl:w-1/4">
              <InputField autoFocus={false} error={loginError} errorMsg={t('errors.general')}
                formSubmitted={formSubmittedToggle} nameSpace="login" color="text-uw-grey"
                value={email} onChangeHandler={setEmail}
                type="text">{t('texts.email')}</InputField>
              <InputField autoFocus={false} error={loginError}
                errorMsg={t('errors.general')} formSubmitted={formSubmittedToggle}
                nameSpace="login"
                color="text-uw-grey" value={password} onChangeHandler={setPassword}
                type="password">{t('texts.password')}</InputField>
              <div
                className="mt-10 mb-8 lg:flex xl:flex lg:justify-center xl:justify-center">
                <Button className="py-4 px-16 lg:px-16 xl:px-16" onClick={handleSubmit}
                  title={t('texts.loginButton')}
                  type="submit" />
              </div>
              <div className="lg:flex xl:flex lg:justify-center xl:justify-center">
                <a href="mailto:support@skilldetektor.de" rel="noopener noreferrer" target="_blank">
                  <Text color="text-uw-grey" small underlined>
                    {t('texts.forgotPassword')}
                  </Text>
                </a>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </> :
    <>
      <Container background="white" headerFooter login>
        <div className="h-screen flex justify-center">
          <div className="flex flex-col align-middle justify-center text-center">
            <div className="lg:hidden xl:hidden">
              <H2 color="uw-teal">
                {t('basics:validation.validationOne')}
                <br />
                {t('basics:validation.validationTwo')}
              </H2>
            </div>
            <div className="hidden lg:block xl:block lg:mb-16 xl:mb-16">
              <H2 color="uw-teal">
                {t('basics:validation.validationOne')}
                <br />
                {t('basics:validation.validationTwo')}
              </H2>
            </div>
            <div className="mt-12 flex justify-center">
              <DotLoader color="#008080" loading size={150} />
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Login