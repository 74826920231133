import React from 'react'
import CompanyContact from "../components/companyDashbaord/CompanyContact"
import { INavigation } from "../components/layout/Menu/CustomMenu"


const CompanyDashboard: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  return (
    <>
      <CompanyContact mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
    </>
  )
}

export default CompanyDashboard