//React
import React from 'react'
//Components
//import MobileView from "../components/employeeResults/mobileResults/MobileView";
import DesktopView from "../components/employeeResults/desktopResults/DesktopView";

const ResultsCandidate: React.FC = () => {

  return (
    <>
      <DesktopView />
    </>
  )
}

export default ResultsCandidate