import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/Firebase";
import {validateMail} from "../helpers/validateEmail";

export const useSubmitCompanyContactForm = () => {
    return async (from: string, subject: string, form: Record<string, string>) => {
        const request = httpsCallable(functions,'sendEmailSendgrid');
        try {
            // eslint-disable-next-line
            if(form.companyName === "" || form.firstName === "" || form.surname === "" || form.subject === "" || form.message === "" || !validateMail(from) || from === "" || subject === "") throw "error"
            const result = await request({
                from,
                subject,
                form
            })
            return result;
        }
        catch (error) {
            throw error;
        }
    }
}