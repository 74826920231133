
import { firestore } from "../firebase/Firebase"
import { collection, query, where, getDocs } from "firebase/firestore";
import { EStatus } from "./useGetProfiles";

export interface IUserData {
  [index: string]: number
}

export interface IMatchingEmployee {
  id: string,
  score?: number,
  firstName: string,
  lastName: string,
  email?: string,
  data: IUserData,
  jobTitle?: string,
  department?: string | null,
  status?: EStatus,
  statusArr: any[]
}


export const UseGetMatchingEmployee = (): any => {

  const getMatchingEmployee = async (uid: string, testGroups: string, profileId: string = '') => {
    let employee: IMatchingEmployee

    try {

      const q = query(collection(firestore, "employees"), where("testGroups", "==", testGroups), where("test.receivedResults", "==", true), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {

        if(doc.data().test.results.errors) {
          return
        }

        let status: EStatus = EStatus.NOTHING
        let statusArr: any = []

        if(doc.data().profiles && doc.data().profiles[profileId] && doc.data().profiles[profileId].status && doc.data().profiles[profileId].status[0]) {
          status = doc.data().profiles[profileId].status[doc.data().profiles[profileId].status.length-1].status
          statusArr = doc.data().profiles[profileId].status
        }

        employee = {
          id: doc.id,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          email: doc.data().email,
          data: doc.data().test.results,
          jobTitle: doc.data().jobTitle,
          department: doc.data().department,
          status: status,
          statusArr: statusArr
        }

        

      });

      return employee

    } catch(err) {
      console.log('err:', err)
    }


  }

  return getMatchingEmployee
}
