// React
import React, { RefObject, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Redirect, useHistory } from 'react-router'
// Components
import Text from "../components/textStyling/Text"
import Container from "../components/layout/Container"
import Button from "../components/layout/Button"
import TestBlock from '../components/test/TestBlock'
import H2 from "../components/textStyling/H2"
import LoadingScreen from "../components/layout/LoadingScreen"
// Moment & Classnames and Hooks
import moment from "moment";
import classnames from "classnames"
import { useLocalStorage } from '../hooks/useLocalStorage'
// Firebase
import { useUserContext } from '../firebase/FirebaseContext';
import { httpsCallable } from '@firebase/functions';
import { functions } from '../firebase/Firebase';

export interface QuestionState {
  [key: string]: 1 | 0 | -1;
}

export interface ClearedQuestionState {
  [key: string]: 1 | -1;
}

const TestScreenCandidate: React.FC = () => {

  window.scrollTo({top: 0, behavior: 'smooth'});

  //useG8tQuestions
  const { questions } = useUserContext()
  const { employeeUser } = useUserContext()

  const [error, setError] = useState(false)
  const [sending, setSending] = useState(false)
  const [questionState, setQuestionState] = useLocalStorage<QuestionState>('roc_g8t', {});
  const TestBlocks = questions && questions.map(qSet =>
    <TestBlock questions={qSet.questions}
      questionState={questionState}
      setQuestionState={setQuestionState} />);
  const [currentPage, setCurrentPage] = useLocalStorage<number>('test_currentPage', 0);
  const history = useHistory();
  const { t } = useTranslation(['test', 'basics'])
  const divRef = React.createRef() as RefObject<HTMLDivElement>
  let buttonTitle
  const [ids, setIds] = useState([''])
  const [hasNegative, setHasNegative] = useState(false)
  const [hasPositive, setHasPositive] = useState(false)

  const handleTestSubmit = async (questionState: QuestionState) => {
    if (!employeeUser) return;
    const clearedQuestionState: ClearedQuestionState = {};
    for (const key in questionState) {
      const val = questionState[key];
      if (val === 1 || val === -1) {
        clearedQuestionState[key] = val;
      }
    }
    const submitQuestions = httpsCallable(functions, 'submitQuestions')
    try {
      setSending(true)
      const data = {
        firstName: employeeUser.firstName,
        lastName: employeeUser.lastName,
        email: employeeUser.email,
        uid: employeeUser.uid,
        input: clearedQuestionState,
        testDone: true,
        testDate: moment().format("D.M.YY")
      }
      const result = await submitQuestions(data)
      return result;
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    if (questions) {
      const localIds = questions[currentPage].questions.map(x => x.id);
      const localHasNegative = ids.some(id => questionState[id] === -1);
      const localHasPositive = ids.some(id => questionState[id] === 1);
      setIds(localIds);
      setHasNegative(localHasNegative);
      setHasPositive(localHasPositive);
      if (error && hasPositive && hasNegative) setError(false);
    }
    //eslint-disable-next-line
  }, [currentPage, error, hasNegative, hasPositive, questions, questionState])

  const handleNextPage = async () => {
    if (!TestBlocks || !questions) return;
    if (currentPage + 1 < TestBlocks.length) {
      if (hasPositive && hasNegative) {
        setError(false)
        setCurrentPage(currentPage + 1)
        setHasPositive(false)
        setHasNegative(false)
      } else {
        setError(true)
      }
    } else {
      if (hasPositive && hasNegative) {
        try {
          await handleTestSubmit(questionState)
          setCurrentPage(0)
          setQuestionState('roc_g8t', {})
          history.push('/candidate/test/done')
        } catch (error: any) {
          alert(`${error.message}`);
        }
      } else {
        setError(true)
      }
    }
  }

  useEffect(() => {
    const focusCheck = async () => {
      if (error) {
        divRef.current!.focus()
      }
    }
    focusCheck()
  }, [error, divRef])

  if ((employeeUser?.test || {}).testDone === undefined || employeeUser?.test.testDone === false) {
    if (!sending) {
      if (TestBlocks) {
        return (
          <Container background="uw-teal" headerFooter={false} header>
            <div className="lg:px-18 xl:px-18">
              <div className="lg:hidden xl:hidden mb-6 mt-8 flex justify-center text-white">
                <Text color="text-white"
                  small>{`${t('site')} ${currentPage + 1} ${t('of')} ${TestBlocks.length}`}</Text>
              </div>
              <div className="hidden lg:flex xl:flex mb-12 mt-16 flex justify-center text-white">
                <Text color="text-white"
                  four>{`${t('site')} ${currentPage + 1} ${t('of')} ${TestBlocks.length}`}</Text>
              </div>
              {TestBlocks[currentPage]}
              <div className="lg:hidden xl:hidden opacity-0">
                {buttonTitle = (currentPage + 1 === TestBlocks.length) ? t('sendButtonTitle') : t('nextPageButtonTitle')}
              </div>
            </div>
            {/*Mobile*/}
            <div className="lg:hidden xl:hidden">
              <div className="flex justify-center mb-6">
                <Button
                  onClick={handleNextPage}
                  backgroundWhite
                  borderColor={false}
                  title={buttonTitle}
                  type="button"
                />
              </div>
              <div id="hint" ref={divRef} className={classnames("flex flex-col", {
                "bg-uw-errorRed rounded-15x pl-4 pr-2 py-2 border-2 border-uw-red": error,
                "bg-uw-teal rounded-15px pl-4 pr-2 py-2 border-2 border-uw-teal": !error,
              }
              )}
                tabIndex={error && !questions![currentPage].questions.map(x => x.id).some(id => questionState[id] === 1) && !questions![currentPage].questions.map(x => x.id).some(id => questionState[id] === -1) ? -1 : undefined}>
                <Text color={!error ? "text-white" : "text-uw-errorRedText"} small
                  underlined>{t('advice')}</Text>
                <Text color={!error ? "text-white" : "text-uw-errorRedText"}
                  small>{t('explanation')}</Text>
              </div>
            </div>
            {/*Desktop*/}
            <div className="hidden lg:block xl:block lg:px-18 xl:px-18">
              <div className="flex flex-row justify-between mb-6">
                <div id="hint" ref={divRef}
                  className={classnames("flex flex-col w-2/5 -ml-6 lg:-ml-5 xl:-ml-5", {
                    "bg-uw-errorRed rounded-15px pl-4 pr-2 py-2 border-2 border-uw-red": error,
                    "bg-uw-teal rounded-15px pl-4 pr-2 border-2 border-uw-teal": !error,
                  }
                  )}
                  tabIndex={error && !questions![currentPage].questions.map(x => x.id).some(id => questionState[id] === 1) && !questions![currentPage].questions.map(x => x.id).some(id => questionState[id] === -1) ? -1 : undefined}>
                  <Text color={!error ? "text-white" : "text-uw-errorRedText"} four
                  >
                    <u>{t('advice')}</u>
                    {t('explanation')}
                  </Text>
                </div>
                <div className="h-4 mt-6">
                  <Button
                    onClick={handleNextPage}
                    className="lg:-mr-6 xl:-mr-6"
                    backgroundWhite
                    borderColor={false}
                    title={buttonTitle}
                    testSize
                    type="button"
                  />
                </div>
              </div>
            </div>
          </Container>
        )
      } else {
        return (
          <Container background="white" headerFooter>
            <div className="lg:hidden xl:hidden">
              <div className="mt-8">
                <Text color="text-uw-teal">
                  {t('loadingQuestions')}
                </Text>
              </div>
            </div>
            <div className="hidden lg:block xl:block">
              <div className="mt-24">
                <H2 color="uw-teal">
                  {t('loadingQuestions')}
                </H2>
              </div>
            </div>
          </Container>
        )
      }
    } else
      return <LoadingScreen />
  } else
    return <Redirect push to="/employee" />
}

export default TestScreenCandidate
