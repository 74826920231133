import React from "react";
import classnames from "classnames"
import Text from "../../textStyling/Text";

interface Props {
    roundedFull?: boolean
    small?: boolean
    titles: Array<string>
    titleExplanations: Array<string>

}

const EmployeeInnerCardStrengthsAndRisks: React.FC<Props> = ({roundedFull, small, titles, titleExplanations}) => {

    const uuidv1 = require('uuid/v1')

    const generateSubText = () => {
        let subText: Array<object> = []
        let counterForBr = 0
        for (let i = 0; i < titles.length; i++) {
            subText.push(
                <div className="w-5/6" key={uuidv1()}>
                    <div className="mb-2">
                        <Text color="text-uw-grey" four style={{className : "font-semibold"}}>
                            {titles[i]}
                        </Text>
                    </div>
                    <div>
                        <Text color="text-uw-grey" four>
                            {titleExplanations[i]}
                        </Text>
                    </div>
                    {counterForBr % 2 === 0 && <br/>}
                </div>
            )
            counterForBr++
        }
        return subText
    }

    return (
        <>
            <div
                className={classnames("bg-white flex flex-grow rounded-bl-15px rounded-r-15px shadow-desktopResultsRightCard px-4 pt-2 pb-3", {
                    "rounded-tl-15px" : roundedFull,
                    "mt-32" : titles.length === 1,
                    "h-265px mt-45" : small,
                    "h-450px" : !small && small !== undefined,
                })}>
                <div className="flex flex-grow flex-col justify-center items-center p-4">
                    {generateSubText()}
                </div>
            </div>
        </>
    )
}

export default EmployeeInnerCardStrengthsAndRisks;