import { firestore } from "../firebase/Firebase"
import { doc, updateDoc, Timestamp } from 'firebase/firestore'

export interface IUserData {
  [index: string]: number
}

export interface IJob {
  company?: any,
  name: string,
  description: string,
  data: IUserData
}


export const useUpdateJob = () => {

  const updateJob =  async (profileId : string, data: {}, name: string, description: string, updated: number, privacy?: boolean,  privacyLink?: string) => {

    const docRef = doc(firestore, "jobs", profileId);
    await updateDoc(docRef, { data: data, name: name, description: description, updated: new Timestamp(Math.floor(updated / 1000), 0), privacy, privacyLink });

  }

  return updateJob
}
