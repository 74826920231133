import React from 'react'
import Container from "../components/layout/Container";
import H1 from "../components/textStyling/H1";
import {useTranslation} from "react-i18next";
import Text from "../components/textStyling/Text";

const EdgeErrorScreen: React.FC = () => {

    const {t} = useTranslation('edgeErrorScreen')

    return (
        <>
            <Container background="white" headerFooter>
                <div className="flex justify-center h-screen">
                    <div
                        className="flex flex-col justify-center text-center">
                        <div className="mb-10 lg:mb-24 xl:mb-24 ">
                            <H1 color="uw-teal">
                                {t('headline')}
                            </H1>
                        </div>
                        <div className="hidden lg:justify-center xl:justify-center lg:flex xl:flex">
                            <Text color="text-uw-grey" one>
                                {t('text')}
                            </Text>
                        </div>
                        <div className="text-center lg:hidden xl:hidden">
                            <Text color="text-uw-grey">
                                {t('text')}
                            </Text>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default EdgeErrorScreen;