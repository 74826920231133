// Firebase
import { firestore } from "../firebase/Firebase"
//import { Timestamp } from "firebase/firestore"
import { collection, query, where, getDocs, doc, orderBy, Timestamp } from "firebase/firestore";
import { EJobStatus } from "./useSaveJob";

export interface IUserData {
  [index: string]: number
}

/*
interface IJobStatus {
  date: number | Timestamp,
  status: EJobStatus
}
*/

export interface IJob {
  id: string,
  company?: any,
  name: string,
  description: string,
  data: IUserData,
  status?: string,
  statusArr: any[],
  updated: Timestamp,
  published: Timestamp | null
}


export const UseGetJobs = (): any => {

  //const {companyUser} = useUserContext()

  const getJobs = async (companyId: string, selectedStatus: EJobStatus | false = false) => {

    const companyRef = doc(firestore, 'companies', companyId)
    //const q = query(collection(firestore, "profiles"), where("company", "==", "/companies/"+companyUser!.companyId));
    const q = query(collection(firestore, "jobs"), where("company", "==", companyRef), orderBy("updated", "desc"));
    const querySnapshot = await getDocs(q);

    let allJobs: IJob[] = [];

    querySnapshot.forEach((doc) => {

      if(typeof doc.data().user === "undefined") {

        let status = EJobStatus.DRAFT
        let statusArr: any = []
        let published = null

        if(doc.data().status && Array.isArray(doc.data().status)) {
          status = doc.data().status[doc.data().status.length-1].status
          if(status === EJobStatus.PUBLISHED)
            published = doc.data().status[doc.data().status.length-1].date
          statusArr = doc.data().status
        }

        if(selectedStatus === false) {
          let profile: IJob = {
            id: doc.id,
            name: doc.data().name,
            description: doc.data().description,
            data: doc.data().data,
            status: status,
            statusArr: statusArr,
            updated: doc.data().updated,
            published: published
          }
    
          allJobs.push(profile)
        } else {
          if(status === String(selectedStatus)) {
            let profile: IJob = {
              id: doc.id,
              name: doc.data().name,
              description: doc.data().description,
              data: doc.data().data,
              status: status,
              statusArr: statusArr,
              updated: doc.data().updated,
              published: published
            }
      
            allJobs.push(profile)
          }
        }
        
      }

    });

    return allJobs;

  }

  return getJobs
}
