//React
import { useState, useEffect } from "react"
//Firebase
import { collection, getDocs, query, where } from "firebase/firestore"
import { firestore } from "../firebase/Firebase"
import { useUserContext } from "../firebase/FirebaseContext"
//import { UseGetJob } from "../hooks/useGetJob"
import { UseGetJobs, IJob } from "./useGetJobs"
import { EJobStatus } from "./useSaveJob";
import { EStatus } from "./useGetJobStats";

export interface IJobOverviewStats {
    activeCandidates: number
    publishedJobs: number,
    openLicenses: number
}



export const useGetJobOverviewStats = () => {
  const {companyUser} = useUserContext()
  const [results, setResults] = useState<IJobOverviewStats | null>(null)

  useEffect(() => {
    const getAllJobs = UseGetJobs()
    const runMe = async () => {
      
      let tempJobs:IJob = await getAllJobs(companyUser!.companyId, EJobStatus.PUBLISHED)

      let resultObj: IJobOverviewStats = {
        activeCandidates: 0,
        publishedJobs: (Array.isArray(tempJobs))?tempJobs.length:0,
        openLicenses: 0
      }

      let foundJobs: any = []
      foundJobs.push('gibtsnicht')
      if(Array.isArray(tempJobs)) {
        tempJobs.forEach(function(element: any) {
          foundJobs.push(element.id);
        });
      }

      const q = query(collection(firestore, 'employees'), where('testGroups', '==', companyUser!.testGroups), where("test.receivedResults", "==", true))
      const allCandidatesForJob = await getDocs(q)

      allCandidatesForJob.forEach((doc) => {
        if(doc.data().test.results && doc.data().test.results.errors) {
          return;
        }
        if(doc.data().jobs) {
          foundJobs.forEach((value: string) => {
            if(Object.keys(doc.data().jobs).includes(value)) {
              let statusArr = doc.data().jobs[value].status;
              if(statusArr && Array.isArray(statusArr)) {
                let candidateStatus = statusArr[statusArr.length-1].status;
                if(candidateStatus !== EStatus.HIRED && candidateStatus !== EStatus.FIRED) {
                  resultObj.activeCandidates++;
                  //console.log('####### Key: ', statusArr[statusArr.length-1].status, value);
                }
              }
            }
          });
        }
      })

      setResults(resultObj);

    }
    runMe()
  }, [companyUser])
  return results;
}