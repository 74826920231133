import React, {useState} from "react";
import EmployeeInnerCardLeftTitleCard from "./EmployeeInnerCardLeftTitleCard";
import EmployeeInnerCardStatistics from "./EmployeeInnerCardStatistics";
import {IState} from "../desktopResults/EmployeeOuterCard";


interface Props {
    data: Array<IState>
}

const EmployeeInnerCompetencesCard: React.FC<Props> = ({data}) => {

    const [selectedIndex, setSelectedIndex] = useState(0)

    const onPressLeftCard = (key: number) => {
        setSelectedIndex(key)
    }

    const indexFour = selectedIndex === 4;
    const indexFive =selectedIndex === 5 && selectedIndex !== data.length - 1; 

    //console.log(data, data.length -1)
    return (
        <div>
            <div className="flex flex-row justify-between">
                <div className="flex flex-col mr-4 w-1/4">
                    {data.map((dataSet, index) => (
                        <div className="mb-4" key={index}>
                            <EmployeeInnerCardLeftTitleCard
                                clicked={selectedIndex === index}
                                lastElement={data.length - 1 === index}
                                onClick={() => {
                                    onPressLeftCard(index)
                                }}
                                selectedIndex={selectedIndex}
                                strengthsAndRisks={false}
                                title={dataSet.title}
                            />
                        </div>
                    ))}
                </div>
                <div className="flex w-3/4">
                    <EmployeeInnerCardStatistics
                        cardPoints={data[selectedIndex].points!}
                        explanationText={data[selectedIndex].explanationText}
                        traitLevelHigh={data[selectedIndex].traitLevelHigh}
                        traitLevelLow={data[selectedIndex].traitLevelLow}
                        roundedFull={selectedIndex !== 0}
                        small={selectedIndex > 4}
                        medium={indexFour || indexFive}
                    />
                </div>
            </div>
        </div>
    )
}

export default EmployeeInnerCompetencesCard;