import React from 'react'
import CustomMenu from '../layout/Menu/CustomMenu'
import Footer from "../layout/FooterNew"
import { INavigation, INavigationMainItem, setMainMenuIdActive } from "../layout/Menu/CustomMenu"
import BreadcrumbNavigation from '../layout/Menu/BreadcrumbNavigation'
//import {useTranslation} from "react-i18next"



const AdminDaschboard: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

    //const { t } = useTranslation(['companyFaq', 'basics'])

    setMainMenuIdActive(mainNavItems)

    const breadCrumbHome = {
        name: 'Home',
        href: '/luksit/dashboard',
        current: false
    }

    const breadCrumbPaths: INavigationMainItem[] = [
        {
            name: 'ADMIN DASHBAORD',
            href: '/luksit/dashboard',
            current: true
        }
    ]

    return (
            <>
                <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} /> 
                <div className="py-10">
                    <header className="mt-16">
                        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="pt-2 pb-4">
                                <BreadcrumbNavigation homePath={ breadCrumbHome } currentPaths={ breadCrumbPaths }></BreadcrumbNavigation>
                            </div>
                            <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal pb-10">ADMIN DASHBAORD</h1>
                        </div>
                    </header>
                    <main className="mt-10">

                    </main>
                </div>
                <Footer />
            </>
            )
}

export default AdminDaschboard;