import basicsDE from './de/basics.json'
import basicsEN from './en/basics.json'
import companyStatsDE from './de/companyStats.json'
import companyStatsEN from './en/companyStats.json'
import menuDE from './de/menu.json'
import menuEN from './en/menu.json'
import employeeListHeadingCardsDE from './de/employeeListHeadingCards.json'
import employeeListHeadingCardsEN from './en/employeeListHeadingCards.json'
import employeeListDE from './de/employeeList.json'
import employeeListEN from './en/employeeList.json'
import companyEmployeeResultsDE from './de/companyEmployeeResults.json'
import companyEmployeeResultsEN from './en/companyEmployeeResults.json'
import companyCardDE from "./de/companyCard.json"
import companyCardEN from "./en/companyCard.json"
import companyContactDE from "./de/companyContact.json"
import companyContactEN from "./en/companyContact.json"
import companyRequirementProfilesDE from "./de/companyRequirementProfiles.json"
import companyRequirementProfilesEN from "./en/companyRequirementProfiles.json"
import companyRequirementJobsDE from "./de/companyRequirementJobs.json"
import companyRequirementJobsEN from "./en/companyRequirementJobs.json"
import companyMatchingDE from "./de/companyMatching.json"
import companyMatchingEN from "./en/companyMatching.json"
import companyResultsDE from "./de/companyResults.json"
import companyResultsEN from "./en/companyResults.json"
import companyRegistrationDE from "./de/companyRegistration.json"
import companyRegistrationEN from "./en/companyRegistration.json"
import companyRegistrationConfirmationDE from "./de/companyRegistrationConfirmation.json"
import companyRegistrationConfirmationEN from "./en/companyRegistrationConfirmation.json"
import companyRecruitingDashboardDE from "./de/companyRecruitingDashboard.json"
import companyRecruitingDashboardEN from "./en/companyRecruitingDashboard.json"
import companyRecruitingJobProfileDE from "./de/companyRecruitingJobProfile.json"
import companyRecruitingJobProfileEN from "./en/companyRecruitingJobProfile.json"
import companyRecruitingIndividualComparisonDE from "./de/companyRecruitingIndividualComparison.json"
import companyRecruitingIndividualComparisonEN from "./en/companyRecruitingIndividualComparison.json"
import candidateLandingPageDE from './de/candidateLandingPage.json'
import candidateLandingPageEN from './en/candidateLandingPage.json'
import companySettingsDE from "./de/companySettings.json"
import companySettingsEN from "./en/companySettings.json"
import candidateRegistrationDE from "./de/candidateRegistration.json"
import candidateRegistrationEN from "./en/candidateRegistration.json"
import candidateFaqDE from "./de/candidateFaq.json"
import candidateFaqEN from "./en/candidateFaq.json"
import testIntroCandidateDE from "./de/testIntroCandidate.json"
import testIntroCandidateEN from "./en/testIntroCandidate.json"
import testConfirmationCandidateDE from "./de/testConfirmationCandidate.json"
import testConfirmationCandidateEN from "./en/testConfirmationCandidate.json"
import dropdownDE from "./de/dropdown.json"
import dropdownEN from "./en/dropdown.json"
import edgeErrorScreenDE from "./de/edgeErrorScreen.json"
import edgeErrorScreenEN from "./en/edgeErrorScreen.json"
import employeeCardDE from "./de/employeeCard.json"
import employeeCardEN from "./en/employeeCard.json"
import employeeLandingPageDE from "./de/employeeLandingPage.json"
import employeeLandingPageEN from "./en/employeeLandingPage.json"
import employeeResultsDE from "./de/employeeResults.json"
import employeeResultsEN from "./en/employeeResults.json"
import employeeRegistrationDE from "./de/employeeRegistration.json"
import employeeRegistrationEN from "./en/employeeRegistration.json"
import testIntroDE from "./de/testIntro.json"
import testIntroEN from "./en/testIntro.json"
import errorComponentDE from "./de/errorComponent.json"
import errorComponentEN from "./en/errorComponent.json"
import employeeFaqDE from "./de/employeeFaq.json"
import employeeFaqEN from "./en/employeeFaq.json"
import companyFaqDE from "./de/companyFaq.json"
import companyFaqEN from "./en/companyFaq.json"
import footerDE from "./de/footer.json"
import footerEN from "./en/footer.json"
import impressumDE from "./de/impressum.json"
import impressumEN from "./en/impressum.json"
import loginDE from "./de/login.json"
import loginEN from "./en/login.json"
import privacyDE from "./de/privacy.json"
import privacyEN from "./en/privacy.json"
import resultsCompanyOverallScreenDE from "./de/resultsCompanyOverallScreen.json"
import resultsCompanyOverallScreenEN from "./en/resultsCompanyOverallScreen.json"
import resultsEmployeeOverallScreenDE from "./de/resultsEmployeeOverallScreen.json"
import resultsEmployeeOverallScreenEN from "./en/resultsEmployeeOverallScreen.json"
import siteNotFoundDE from "./de/siteNotFound.json"
import siteNotFoundEN from "./en/siteNotFound.json"
import testDE from "./de/test.json"
import testEN from "./en/test.json"
import testConfirmationDE from "./de/testConfirmation.json"
import testConfirmationEN from "./en/testConfirmation.json"
import textAreaDE from "./de/textArea.json"
import textAreaEN from "./en/textArea.json"
import departmentsDE from "./de/departments.json"
import departmentsEN from "./en/departments.json"

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    de: {
        basics: basicsDE,
        menu: menuDE,
        companyStats: companyStatsDE,
        employeeListHeadingCards: employeeListHeadingCardsDE,
        employeeList: employeeListDE,
        companyEmployeeResults: companyEmployeeResultsDE,
        companyContact: companyContactDE,
        companyCard: companyCardDE,
        companyResults: companyResultsDE,
        companyRegistration: companyRegistrationDE,
        companyRequirementProfiles: companyRequirementProfilesDE,
        companyRequirementJobs: companyRequirementJobsDE,
        companyMatching: companyMatchingDE,
        companyRegistrationConfirmation: companyRegistrationConfirmationDE,
        companySettings: companySettingsDE,
        companyRecruitingDashboard: companyRecruitingDashboardDE,
        companyRecruitingJobProfile: companyRecruitingJobProfileDE,
        companyRecruitingIndividualComparison: companyRecruitingIndividualComparisonDE,
        candidateRegistration: candidateRegistrationDE,
        candidateFaq: candidateFaqDE,
        candidateLandingPage: candidateLandingPageDE,
        testIntroCandidate: testIntroCandidateDE,
        testConfirmationCandidate: testConfirmationCandidateDE,
        dropdown: dropdownDE,
        edgeErrorScreen: edgeErrorScreenDE,
        employeeCard: employeeCardDE,
        employeeLandingPage: employeeLandingPageDE,
        employeeResults: employeeResultsDE,
        employeeRegistration: employeeRegistrationDE,
        testIntro: testIntroDE,
        errorComponent: errorComponentDE,
        employeeFaq: employeeFaqDE,
        companyFaq: companyFaqDE,
        footer: footerDE,
        impressum: impressumDE,
        login: loginDE,
        privacy: privacyDE,
        resultsCompanyOverallScreen: resultsCompanyOverallScreenDE,
        resultsEmployeeOverallScreen: resultsEmployeeOverallScreenDE,
        siteNotFound: siteNotFoundDE,
        test: testDE,
        testConfirmation: testConfirmationDE,
        textArea: textAreaDE,
        departments: departmentsDE
    },
    en: {
        basics: basicsEN,
        menu: menuEN,
        companyStats: companyStatsEN,
        employeeListHeadingCards: employeeListHeadingCardsEN,
        employeeList: employeeListEN,
        companyEmployeeResults: companyEmployeeResultsEN,
        companyContact: companyContactEN,
        companyCard: companyCardEN,
        companyResults: companyResultsEN,
        companyRegistration: companyRegistrationEN,
        companyRequirementProfiles: companyRequirementProfilesEN,
        companyRequirementJobs: companyRequirementJobsEN,
        companyMatching: companyMatchingEN,
        companyRegistrationConfirmation: companyRegistrationConfirmationEN,
        companySettings: companySettingsEN,
        companyRecruitingDashboard: companyRecruitingDashboardEN,
        companyRecruitingJobProfile: companyRecruitingJobProfileEN,
        companyRecruitingIndividualComparison: companyRecruitingIndividualComparisonEN,
        candidateRegistration: candidateRegistrationEN,
        candidateFaq: candidateFaqEN,
        candidateLandingPage: candidateLandingPageEN,
        testIntroCandidate: testIntroCandidateEN,
        testConfirmationCandidate: testConfirmationCandidateEN,
        dropdown: dropdownEN,
        employeeCard: employeeCardEN,
        employeeLandingPage: employeeLandingPageEN,
        employeeResults: employeeResultsEN,
        employeeRegistration: employeeRegistrationEN,
        testIntro: testIntroEN,
        errorComponent: errorComponentEN,
        edgeErrorScreen: edgeErrorScreenEN,
        employeeFaq: employeeFaqEN,
        companyFaq: companyFaqEN,
        footer: footerEN,
        impressum: impressumEN,
        login: loginEN,
        privacy: privacyEN,
        resultsCompanyOverallScreen: resultsCompanyOverallScreenEN,
        resultsEmployeeOverallScreen: resultsEmployeeOverallScreenEN,
        siteNotFound: siteNotFoundEN,
        test: testEN,
        testConfirmation: testConfirmationEN,
        textArea: textAreaEN,
        departments: departmentsEN
    }
}