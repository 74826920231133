import React from 'react'
import Button from "../components/layout/Button"
import Container from "../components/layout/Container"
import H1 from "../components/textStyling/H1"
import Text from "../components/textStyling/Text"
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const SiteNotFoundScreen = () => {

    const { t } = useTranslation(['siteNotFound', 'basics', 'errorComponent'])
    const uuidv1 = require('uuid/v1')

    return (
        <>
            <Container background="white" headerFooter={true} login logout>
                <div className="lg:flex xl:flex lg:justify-center xl:justify-center h-screen">
                    <div className="lg:w-2/3 xl:w-2/3">
                        <div className="flex flex-col justify-center content-center align-middle lg:px-40 xl:px-40">
                            <div className="mb-10 mt-12 lg:mt-32 xl:mt-32 lg:mb-12 xl:mb-12 text-center">
                                <H1 color="uw-teal">
                                    {t('errorComponent:error.headline')}
                                </H1>
                            </div>
                            <div className="hidden lg:mb-6 xl:mb-6 lg:text-center xl:text-center lg:flex xl:flex">
                                <Text color="text-uw-grey" one>
                                    {t('errorMsg')}
                                </Text>
                            </div>
                            <div className="mb-6 text-center lg:hidden xl:hidden">
                                <Text color="text-uw-grey">
                                    {t('errorMsg')}
                                </Text>
                            </div>
                            <div
                                className="flex flex-col lg:flex-row xl:flex-row lg:justify-between xl:justify-between items-center">
                                <div className="mb-8 lg:mb-0 xl:mb-0 lg:w-1/2 xl:w-1/2 lg:flex lg:justify-center xl:flex xl:justify-center lg:mr-8 xl:mr-8" key={uuidv1()}>
                                    <a href="mailto:support@skilldetektor.de">
                                        <Button className="py-2" title={t('basics:buttons.messageButton')} type="button"/>
                                    </a>
                                </div>
                                <div className="lg:w-1/2 xl:w-1/2 lg:flex lg:justify-center xl:flex xl:justify-center" key={uuidv1()}>
                                    <Link to="/">
                                        <Button backgroundWhite borderColor className="py-2" title={t('basics:buttons.startButton')} type="button"/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default SiteNotFoundScreen