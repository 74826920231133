
//React
import React from 'react'
//Components
import RecruitingJobProfileView from "../components/recruitingDashboard/RecruitingJobProfileView"
import { INavigation } from "../components/layout/Menu/CustomMenu"


const RecruitingJobProfile: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  return (
    <RecruitingJobProfileView mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
  )
}

export default RecruitingJobProfile