import React, {RefObject, useEffect, useState} from 'react';
import Select from 'react-select'
import Text from '../textStyling/Text'
import {ValueType} from 'react-select/lib/types';
import {useTranslation} from "react-i18next";
import classnames from "classnames";
import {StateManager} from "react-select/lib/stateManager";

interface Props {
    autoFocus: boolean
    error: boolean,
    errorMessage?: string,
    formSubmitted: boolean
    isPackage?: boolean
    title: string;
    options: Array<object>;
    value: object;
    setValue: (gender: ValueType<object>) => void;
    tabIndex?: string
}

const Dropdown: React.FC<Props> = ({autoFocus, error, formSubmitted,isPackage, title, options, value, setValue, tabIndex, errorMessage}) => {

    const {t} = useTranslation('dropdown')
    const localRef = React.createRef() as RefObject<StateManager<object>>
    const [lastFormSubmitted, setLastFormSubmitted] = useState(false)

    useEffect(() => {
        if (autoFocus && error) {
            if (lastFormSubmitted !== formSubmitted) {
                localRef.current!.focus()
                setLastFormSubmitted(!lastFormSubmitted)
            }
        }
    }, [formSubmitted, lastFormSubmitted, autoFocus, error, localRef])

    return (
        error ?
            <>
                <div className="mb-2">
                    <Text four color="text-white">{title}</Text>
                </div>
                <div className="mb-4 max-w-md">
                    <Select
                        className="font-text font-normal text-base leading-text text-uw-teal lg:leading-textFourDesktop xl:leading-textFourDesktop lg:text-textFourDesktop xl:text-textFourDesktop"
                        ref={localRef}
                        options={options}
                        placeholder={t('value.placeholder')}
                        value={value}
                        onChange={setValue}
                    />
                    <div className="mt-3">
                        <div className="relative">
                            <div className=" ml-4 arrow-up absolute top-minus11"/>
                        </div>
                        <p
                            className={classnames(
                                "opacity-100 text-xs z-10 bg-uw-errorRed flex w-full max-w-md p-2 rounded-10px font-text leading-text font-normal text-uw-errorRedText",
                            )}
                            defaultValue={t('error')}
                        >{(errorMessage)? errorMessage : (!isPackage ? t('error') : t('errorPackage')) }</p>
                    </div>
                </div>

            </>
            :
            <>
                <div className="mb-2">
                    <Text four color="text-white">{title}</Text>
                </div>
                <div className="mb-4 max-w-md">
                    <Select
                        className="font-text font-normal text-base leading-text text-uw-teal lg:leading-textFourDesktop xl:leading-textFourDesktop lg:text-textFourDesktop xl:text-textFourDesktop"
                        options={options}
                        placeholder={t('value.placeholder')}
                        value={value}
                        onChange={setValue}
                        tabIndex={tabIndex}
                    />
                </div>
            </>
    )
}

export default Dropdown
