// Firebase
import { firestore } from "../firebase/Firebase"
import { collection, query, getDocs, orderBy } from "firebase/firestore"

export interface IUserData {
  [index: string]: number
}

export interface IProfile {
  id: string,
  //company?: any,
  name: string,
  description: string,
  data: IUserData[]
}


export const UseGetIscoProfiles = (): any => {

  const getIscoProfiles = async (lang: string = 'en') => {

    let q = query(collection(firestore, "ISCO_profiles"), orderBy("Name_en", "asc"))
    if(lang === 'de') {
      q = query(collection(firestore, "ISCO_profiles"), orderBy("Name_de", "asc"))
    }

    const querySnapshot = await getDocs(q)

    let allProfiles: IProfile[] = []

    let emptyProfile: IProfile = {
      id: 'EMPTY_PROFILE',
      name: 'Leeren',
      description: 'Daten leeren',
      data: { ...[] }
    }
    allProfiles.push(emptyProfile)

    querySnapshot.forEach((doc) => {

      let data: IUserData[] = []

      for (const key in doc.data().Attributes) {
        //console.log(`${key}: ${doc.data().Attributes[key].target}`);
        let singleData: IUserData = doc.data().Attributes[key].target
        data[key] = singleData+''
      }

      let name =  doc.data().Name_en
      if(lang === 'de') {
        name =  doc.data().Name_de
      }

      let profile: IProfile = {
        id: doc.id,
        name:name,
        description: doc.data().description,
        data: { ...data }
      }

      allProfiles.push(profile)

    });

    return allProfiles;

  }

  return getIscoProfiles
}
