import React from 'react'

interface Props {
    color: "white" | "uw-teal"
}

const H3: React.FC<Props> = ({color, children}) => {
    return (
        <>
            <div className="lg:hidden xl:hidden">
                <h3 className={`font-headlines text-${color} text-h2h3Mobile font-medium leading-h2h3Mobile`}>{children}</h3>
            </div>
            <div className="hidden lg:block xl:block">
                <h3 className={`font-headlines text-${color} text-h3h4Desktop font-medium leading-hh3h4Desktop`}>{children}</h3>
            </div>
        </>
    )
}

export default H3;