//React
import { useState, useEffect } from "react"
//Firebase
import { collection, getDocs, query, where } from "firebase/firestore"
import { firestore } from "../firebase/Firebase"
import { useUserContext } from "../firebase/FirebaseContext"
import { UseGetProfile, IProfile } from "../hooks/useGetProfile"
import { EStatus } from "./useGetProfiles"

export interface IProfileStats {
  name: string,
  countStats: {
    ALL: number,
    NOTHING: number,
    PRE_SELECTED: number,
    INTERVIEW: number,
    SELECTED: number,
    REJECTED: number
  } | any
}



export const useGetProfileStats = (profileId: string) => {
  const {companyUser} = useUserContext()
  const [results, setResults] = useState<IProfileStats | null>(null)
  const getProfile = UseGetProfile()
  useEffect(() => {
    (async () => {
      
      
      const job: IProfile = await getProfile(profileId, false, "LIST")
      //console.log('#####JOB', job)

      let resultObj: IProfileStats = {
        name: job.name,
        countStats: {
          ALL: 0,
          NOTHING: 0,
          PRE_SELECTED: 0,
          INTERVIEW: 0,
          SELECTED: 0,
          REJECTED: 0
        }
      }
      
      //where("profiles."+profileId+".profileId", "==", profileId),
      const q = query(collection(firestore, 'employees'), where('testGroups', '==', companyUser!.testGroups), where("test.receivedResults", "==", true), where('role', '==', 'EMPLOYEE'))
      const allEmployeesForProfile = await getDocs(q)
      //console.log('allEmployeesForProfile', allEmployeesForProfile)

      allEmployeesForProfile.forEach((doc) => {

        if(doc.data().test.results.errors) {
          return
        }

        resultObj.countStats.ALL++
        if(doc.data().profiles && doc.data().profiles[profileId] && doc.data().profiles[profileId].status && doc.data().profiles[profileId].status[0]) {
          let status = doc.data().profiles[profileId].status[doc.data().profiles[profileId].status.length-1].status
          switch (status) {
            case EStatus.NOTHING:
              resultObj.countStats.NOTHING++
              break;
            case EStatus.PRE_SELECTED:
              resultObj.countStats.PRE_SELECTED++
              break;
            case EStatus.INTERVIEW:
              resultObj.countStats.INTERVIEW++
              break;
            case EStatus.SELECTED:
              resultObj.countStats.SELECTED++
              break;
            case EStatus.REJECTED:
              resultObj.countStats.REJECTED++
              break;
          }
        } else {
          resultObj.countStats.NOTHING++
        }
        //console.log(doc.data().firstName)
      })
      
      setResults(resultObj);

    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUser, profileId])
  return results;
}