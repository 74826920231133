import { firestore } from "../firebase/Firebase"
import { doc, updateDoc, Timestamp } from 'firebase/firestore'

export interface IUserData {
  [index: string]: number
}

export interface IProfile {
  company?: any,
  name: string,
  description: string,
  data: IUserData
}


export const useUpdateProfile = () => {

  const updateProfile =  async (profileId : string, data: {}, name: string, description: string, updated: number) => {

    const docRef = doc(firestore, "profiles", profileId);
    await updateDoc(docRef, { data: data, name: name, description: description, updated: new Timestamp(Math.floor(updated / 1000), 0) });

  }

  return updateProfile
}
