import React from 'react'


interface Props {
    color: "white" | "uw-teal"
}

const H5: React.FC<Props> = ({children, color}) => {

    return <h5 className={`font-headlines font-medium leading-h5Desktop text-h5Desktop text-${color}`}>{children}</h5>
}

export default H5;