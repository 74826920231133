import { User } from "firebase/auth";
import { DocumentReference, Timestamp } from "firebase/firestore";

export enum UserRoles {
  ADMIN = 'ADMIN',
  COMPANY = 'COMPANY',
  RECRUITING = 'RECRUITING',
  RECRUITING_DEMO = 'RECRUITING_DEMO',
  COMPANY_AND_RECRUITING = 'COMPANY_AND_RECRUITING',
  EMPLOYEE = 'EMPLOYEE',
  CANDIDATE = 'CANDIDATE',
  LUKSIT_ADMIN = 'LUKSIT_ADMIN',
  NOTHING = 'NOTHING'
}

export const userRole = {
  admins: [String(UserRoles.ADMIN)],
  employees: [String(UserRoles.EMPLOYEE)],
  candidates: [String(UserRoles.CANDIDATE)],
  companies: [String(UserRoles.COMPANY)],
  recruiting: [String(UserRoles.RECRUITING)],
  recruitingDemo: [String(UserRoles.RECRUITING_DEMO)],
  companieAndRecruiting: [String(UserRoles.COMPANY_AND_RECRUITING)],
  allCompanies: [String(UserRoles.COMPANY), String(UserRoles.RECRUITING), String(UserRoles.RECRUITING_DEMO), String(UserRoles.COMPANY_AND_RECRUITING)],
  luksitAdmins: [String(UserRoles.LUKSIT_ADMIN)]
}

interface EmployeeBase extends User {
  firstName: string;
  lastName: string;
  gender: "male" | "female" | "other" | "empty";
  termsAccepted: Timestamp;
  testGroups: Array<DocumentReference>
  test?: any;
  loadingStatus?: boolean;
}

export interface Employee extends EmployeeBase {
  role: UserRoles.EMPLOYEE;
}

export interface Candidate extends EmployeeBase {
  role: UserRoles.CANDIDATE;
}

export interface CompanyBase extends User {
  active: boolean;
  companyId: string
  company: {
    city: string;
    name: string;
    street: string;
    zipCode: string;
    size: string;
    participators: number;
  },
  contact: {
    firstName: string;
    lastName: string;
    telephone: string;
  },
  subscription: string;
  //termsAccepted: Timestamp;
  testGroups: Array<DocumentReference>
  empoloyeeAccess: Boolean,
  registerDate: number,
  loadingStatus?: boolean;
  demoAllowedProfiles?: number
  matchingAllowedProfiles?: number
}
export interface Company extends CompanyBase {
  role: UserRoles.COMPANY;
}
export interface RecruitingDemo extends CompanyBase {
  role: UserRoles.RECRUITING_DEMO;
}
export interface Recruiting extends CompanyBase {
  role: UserRoles.RECRUITING;
}

export interface CompanyAndRecruiting extends CompanyBase {
  role: UserRoles.COMPANY_AND_RECRUITING;
}

export interface LuksitAdmin extends User {
  role: UserRoles.LUKSIT_ADMIN;

}

export type CurrentUser = Company | Employee | Candidate | Recruiting | RecruitingDemo | CompanyAndRecruiting | LuksitAdmin | null

// export interface LuksitUser extends User{
//   firstName?: string,
//   lastName?: string,
//   termsAccepted?: Timestamp,
//   role?: UserRoles,
//   active?: boolean,
//   gender?: "male" | "female" | "other" | "empty",
//   // userObject?: Employee | Company,
//   employee?:{
//     testGroups?: Array<DocumentReference>
//     test?: any;
//   },
//   company?: {
//     city: string,
//     name: string,
//     street: string,
//     zipCode: string,
//     size: string,
//     participators: number
//   },
//   contact?: {
//     firstName: string;
//     lastName: string;
//     telephone: string;
//   },
//   subscription?: string;
//   testGroups?: Array<DocumentReference>
// } 

// export interface Employee extends LuksitUser {
//   testGroups?: Array<DocumentReference>
//   test?: any;
// }

// export interface Company extends LuksitUser {
//   active?: boolean;
//   company?: {
//       city: string;
//       name: string;
//       street: string;
//       zipCode: string;
//       size: string;
//       participators: number;
//   },
//   contact?: {
//       firstName: string;
//       lastName: string;
//       telephone: string;
//   },
//   subscription?: string;
//   testGroups?: Array<DocumentReference>
// }

// export type CurrentUser = Company | Employee | null
