import React from 'react'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import HeaderLogo from "../design/HeaderLogo"
import IconLogin from "../design/IconLogin"
import IconLogout from "../design/IconLogout"
import Text from "../textStyling/Text"
import { isIE } from 'react-device-detect'
import { useUserContext } from '../../firebase/FirebaseContext'

interface Props {
  backgroundWhite?: boolean
  language: string
  languageSetter: (languageCode: string) => void
  login?: boolean
  logout?: boolean
}

const Header: React.FC<Props> = ({ backgroundWhite, language, languageSetter, login, logout }) => {


  const history = useHistory()
  const { user } = useUserContext()

  const bottomMargin = backgroundWhite === false ? "" : "mb-1"
  const path = login && !user ? "/login" : "/logout"

  return (!login && !logout ?
    <>
      <header className={`bg-white fixed z-20 flex flex-1 justify-center shadow-headShadow ${bottomMargin}`}>
        <div className="flex items-center justify-center py-6 w-screen"
        >
          <div className="cursor-pointer" onClick={() => history.push("/")}>
            <HeaderLogo />
          </div>
          <div
            className={classnames("hidden lg:flex xl:flex lg:flex-row xl:flex-row lg:absolute xl:absolute lg:right-0 xl:right-0 lg:-mr-52 xl:mr-52",
              { "lg:mt-minus14 xl:mt-minus14": isIE }, { "lg:mt-minus2 xl:mt-minus2": !isIE }
            )}>
            <div className="cursor-pointer mt-1" onClick={() => {
              languageSetter('de')
            }}>
              <Text color="text-uw-teal" fat={language === "de"} small
                headlines={language === "de"}>
                deutsch&nbsp;
              </Text>
            </div>
            <div>
              <Text color="text-uw-teal" four>
                |
              </Text>
            </div>
            <div className="cursor-pointer mt-1" onClick={() => {
              languageSetter('en')
            }}>
              <Text color="text-uw-teal" fat={language === 'en'} small
                headlines={language === "en"}>
                &nbsp;english
              </Text>
            </div>
          </div>
        </div>
      </header>
    </>
    :
    <>
      <header
        className={`bg-white fixed z-20 shadow-headShadow ${bottomMargin} flex w-screen justify-center`}>
        <div className="flex container flex-1 flex-row relative">
          <div className="flex flex-1 items-center justify-center py-6">
            <div className="cursor-pointer" onClick={() => history.push("/")}>
              <HeaderLogo />
            </div>
          </div>
          <div
            className="hidden lg:flex xl:flex lg:flex-row xl:flex-row lg:absolute xl:absolute lg:right-0 xl:right-0 lg:mr-32 xl:mr-32 lg:mt-4 xl:mt-4">
            <div className="cursor-pointer mt-1" onClick={() => {
              languageSetter('de')
            }}>
              <Text color="text-uw-teal" fat={language === "de"} small headlines={language === "de"}>
                deutsch&nbsp;
              </Text>
            </div>
            <div>
              <Text color="text-uw-teal" four>
                |
              </Text>
            </div>
            <div className="cursor-pointer mt-1" onClick={() => {
              languageSetter('en')
            }}>
              <Text color="text-uw-teal" fat={language === 'en'} small headlines={language === "en"}>
                &nbsp;english
              </Text>
            </div>
          </div>
          <div className="absolute cursor-pointer items-end py-5 right-0 mr-8 lg:mr-8 xl:mr-8"
            onClick={() => history.push(path)}>
            {logout && user !== null && <IconLogout />}
            {login && user === null && <IconLogin />}
          </div>
        </div>
      </header>
    </>
  )
}

export default Header;