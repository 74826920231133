import React from 'react'
//import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next";
import Text from "../textStyling/Text";

import LuksitFooterLogo from '../../css/icons/Luksit_Logo-Footer.svg'

interface Props {
    language: string
    languageSetter: (languageCode: string) => void
}

const Footer: React.FC<Props> = ({language, languageSetter}) => {

    const {i18n, t} = useTranslation('footer')
    const en = i18n.language.includes('en') ? '/en' : ''

    /* const mailFunction = () => {
        window.location.href = `mailto:${t('mail')}`
    } */

    return (
        <>
            <footer className="flex flex-shrink-0 bg-uw-teal px-6">
                {/*Mobile*/}
                <div className="lg:hidden xl:hidden">
                    <img className="mb-3 mt-10" height={56} width={90} src={LuksitFooterLogo} alt="Luksit"/>
                    <p className="mb-6 text-white font-text leading-text font-normal text-xs text-left">{t('headline')}</p>
                    <div className="flex flex-row justify-between pb-10 pr-32">
                        <div className="flex flex-col items-start">
                           {/* <p className="mb-4 font-text font-normal leading-none text-textSmallMobile text-white opacity-80">
                                {t('service')}
                            </p> */}
                            {/* <a href={`https://luksit.de/skilldetektor/faq${en}`} rel="noopener noreferrer" target="_blank">
                                <p
                                    className="flex pb-3 font-text font-normal leading-none text-textSmallMobile underline text-white"
                                >{t('faq')}
                                </p>
                            </a>
                            <Link
                                className="flex font-text font-normal leading-none text-textSmallMobile underline text-white"
                                to="/contact">{t('contact')}</Link> */}
                        </div>
                        <div className="flex flex-col items-start">
                            {/* <p className="mb-4 font-text font-normal leading-none text-textSmallMobile text-white opacity-80">
                                {t('rights')}
                            </p> */}
                            <a href={`https://luksit.de/impressum${en}`} rel="noopener noreferrer" target="_blank">
                                <p
                                    className="flex pb-3 underline font-text font-normal leading-none text-textSmallMobile text-white"
                                >{t('impressum')}</p></a>
                            <a href={`https://luksit.de/datenschutz${en}/#luksit-app`} rel="noopener noreferrer" target="_blank">
                                <p
                                    className="flex underline font-text font-normal leading-none text-textSmallMobile text-white"
                                >{t('privacy')}
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
                {/*Desktop*/}
                <div className="hidden lg:block xl:block w-full">
                    <div className=" flex justify-center">
                        <div className="container flex flex-row pt-4 pb-8">
                            <div className="ml-6 mr-20 flex flex-col">
                                <div className="pt-8 py-1.5">
                                    <img height={56} width={90} src={LuksitFooterLogo}
                                         alt="Luksit Logo"/>
                                </div>
                                <p className="text-white font-text leading-text font-normal text-sm text-left">{t('headline')}</p>
                            </div>
                            <div className="flex flex-row">
                                <div className="flex flex-col pt-10">
                                    {/* <p className="pt-8 pb-10 font-text font-normal leading-none text-base text-white opacity-80">
                                        {t('service')}
                                        </p> */}
                                    <div className="flex flex-col items-start">
                                       {/* <a href={`https://luksit.de/skilldetektor/faq/${en}`} rel="noopener noreferrer"
                                           target="_blank">
                                            <p
                                                className="flex pb-3 font-text font-normal leading-none text-base underline text-white">
                                                {t('faq')}
                                            </p>
                                        </a>
                                        <Link
                                            className="flex font-text font-normal leading-none text-base underline text-white"
                                            to="/contact">{t('contact')}</Link> */}
                                    </div>
                                </div>
                                <div className="flex flex-col pt-10">
                                    {/* <p className="pt-8 pb-10 font-text font-normal leading-none text-base text-white opacity-80">
                                        {t('rights')}
                                        </p> */}
                                    <a href={`https://luksit.de/impressum${en}`} rel="noopener noreferrer" target="_blank">
                                        <p
                                            className="flex pb-3 underline font-text font-normal leading-none text-sm text-white"
                                        >{t('impressum')}</p>
                                    </a>
                                    <a href={`https://luksit.de/datenschutz${en}/#luksit-app`} rel="noopener noreferrer" target="_blank">
                                        <p className="flex underline font-text font-normal leading-none text-sm text-white"
                                        >{t('privacy')}</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="flex flex-row justify-center my-4 lg:hidden xl:hidden">
                <div className="flex">
                    <div onClick={() => {
                        languageSetter('de')
                    }}>
                        <Text color="text-uw-teal" fat={language === "de"} headlines={language === "de"} small>
                            deutsch&nbsp;
                        </Text>
                    </div>
                    <div>
                        <Text color="text-uw-teal" small>
                            |
                        </Text>
                    </div>
                    <div onClick={() => {
                        languageSetter('en')
                    }}>
                        <Text color="text-uw-teal" fat={language === "en"} headlines={language === "en"} small>
                            &nbsp;english
                        </Text>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;