import React, {RefObject, SetStateAction, SyntheticEvent} from 'react'
import InputField from "../layout/InputField";
import SwitchButton from "../layout/SwitchButton";
import Button from "../layout/Button";
import {useTranslation} from "react-i18next";
import Dropdown from "../layout/Dropdown";

export interface Props {
    alreadyRegistered: boolean
    autoFocus: Array<boolean>
    dropDownHandler: (bookedPackage: SetStateAction<{ value: string; label: string }>) => void
    dropDownOptions: Array<object>
    dropDownValue: object
    emailRef: RefObject<HTMLInputElement>
    error: Array<boolean>
    formSubmittedToggle: boolean
    handleSubmit: (e: SyntheticEvent<Element, Event>) => Promise<void>
    onChangeHandler: Array<(e: React.ChangeEvent<HTMLInputElement>) => void>
    state: 'tablet' | 'desktop' | 'mobile'
    termsHandler: () => void
    value: Array<string | boolean>
}

const BodyForm: React.FC<Props> = ({alreadyRegistered, autoFocus, dropDownHandler, dropDownOptions, dropDownValue, emailRef, error, formSubmittedToggle, handleSubmit, onChangeHandler, state, termsHandler, value}) => {

    const {i18n, t} = useTranslation(['companyRegistration', 'basics'])
    const en = i18n.language.includes('en') ? '/en' : ''

    return (
        state === 'mobile' || state === 'tablet' ?
            <>
                <div className="lg:hidden xl:hidden">
                    <form>
                        <InputField autoFocus={autoFocus[0]} error={error[0]}
                                    errorMsg="errors.companyName" formSubmitted={formSubmittedToggle}
                                    inputName="company"
                                    value={value[0] as string}
                                    onChangeHandler={onChangeHandler[0]}
                                    type={"text"}>{t('form.companyName')}</InputField>
                        <InputField autoFocus={autoFocus[2]}
                                    error={error[1]} errorMsg="errors.companyStreet"
                                    formSubmitted={formSubmittedToggle} value={value[1] as string}
                                    onChangeHandler={onChangeHandler[1]}
                                    type={"text"}>{t('form.street')}</InputField>
                        <div className="flex flex-row max-w-md">
                            <div className="flex w-1/3 flex-col mr-2">
                                <InputField
                                    autoFocus={autoFocus[2]}
                                    error={error[2]} errorMsg="errors.companyZip"
                                    formSubmitted={formSubmittedToggle} value={value[2] as string}
                                    onChangeHandler={onChangeHandler[2]}
                                    type={"number"}>{t('form.zip')}</InputField>
                            </div>
                            <div className="flex w-2/3 flex-col">
                                <InputField
                                    autoFocus={autoFocus[3]}
                                    error={error[3]} errorMsg="errors.companyCity"
                                    formSubmitted={formSubmittedToggle} value={value[3] as string}
                                    onChangeHandler={onChangeHandler[3]}
                                    type={"text"}>{t('form.city')}</InputField>
                            </div>
                        </div>
                        <InputField
                            autoFocus={autoFocus[4]}
                            error={error[4]} errorMsg="errors.companyParticipators"
                            formSubmitted={formSubmittedToggle} value={value[4] as string}
                            onChangeHandler={onChangeHandler[4]}
                            type={"number"}>{t('form.participants')}</InputField>
                        <div className="m-10"/>
                        <InputField
                            autoFocus={autoFocus[5]}
                            error={error[5]} errorMsg="errors.contactFirstName"
                            formSubmitted={formSubmittedToggle} inputName="given-name" nameSpace='basics'
                            value={value[5] as string}
                            onChangeHandler={onChangeHandler[5]}
                            type={"text"}>{t('form.prename')}</InputField>
                        <InputField
                            autoFocus={autoFocus[6]}
                            error={error[6]} errorMsg="errors.contactLastName"
                            formSubmitted={formSubmittedToggle} inputName="family-name" nameSpace="basics"
                            value={value[6] as string}
                            onChangeHandler={onChangeHandler[6]}
                            type={"text"}>{t('form.surname')}</InputField>
                        <InputField
                            autoFocus={autoFocus[7]}
                            error={error[7]} errorMsg="errors.contactTelephone"
                            formSubmitted={formSubmittedToggle} value={value[7] as string}
                            onChangeHandler={onChangeHandler[7]}
                            type={"number"}>{t('form.telefone')}</InputField>
                        <InputField
                            autoFocus={autoFocus[8]}
                            error={error[8]} errorMsg="errors.mail"
                            formSubmitted={formSubmittedToggle} nameSpace="basics" value={value[8] as string}
                            onChangeHandler={onChangeHandler[8]}
                            type={"email"}>{t('form.email')}</InputField>
                        {alreadyRegistered && <div className="mt-3">
                            <div className="relative">
                                <div className="ml-4 arrow-up absolute top-minus11"/>
                            </div>
                            <input
                                className="text-base bg-uw-errorRed flex w-full p-2 rounded-lg font-text leading-text font-normal text-uw-errorRedText"
                                defaultValue={t('basics:errors.mailInUse')}
                                disabled={true}
                                ref={emailRef}
                            />
                        </div>}
                        <InputField
                            autoFocus={autoFocus[9]}
                            error={error[9]} errorMsg="errors.password"
                            formSubmitted={formSubmittedToggle}
                            value={value[9] as string}
                            onChangeHandler={onChangeHandler[9]}
                            type={"password"}>{t('form.password')}</InputField>
                        <InputField
                            autoFocus={autoFocus[10]}
                            error={error[10]} errorMsg="errors.passwordConfirmation"
                            formSubmitted={formSubmittedToggle} value={value[10] as string}
                            onChangeHandler={onChangeHandler[10]}
                            type={"password"}>{t('form.passwordConfirmation')}</InputField>
                        <SwitchButton
                            autoFocus={autoFocus[11]}
                            formSubmitted={formSubmittedToggle} value={value[11] as boolean} setValue={termsHandler} //termshandler ändern
                            textFirst={t('form.switchTextFirst')}
                        />
                        <div className="m-3"/>
                        <Button onClick={handleSubmit} backgroundWhite borderColor={false}
                                title={t('form.buttonRegistration')}
                                type="submit"
                                className="p-2"/>
                    </form>
                </div>
                <div className="mt-4 ml-2">
                    <a className="flex" href={`https://luksit.de/datenschutz/#luksit-app${en}`} rel="noopener noreferrer" target="_blank">
                        <p className="font-text hover:underline leading-none sm:text-base text-white">{t('form.dataPrivacy')}</p>
                    </a>
                </div>
            </> :
            <>
                <div className="hidden lg:block xl:block flex flex-grow justify-center lg:mb-20 xl:mb-20">
                    <form>
                        <div className="flex flex-col mx-32">
                            <div className="flex flex-row">
                                <div className="w-1/2 mr-20">
                                    <InputField autoFocus={autoFocus[0]} error={error[0]}
                                                errorMsg="errors.companyName" formSubmitted={formSubmittedToggle}
                                                inputName="company"
                                                value={value[0] as string}
                                                onChangeHandler={onChangeHandler[0]}
                                                tabIndex={1} type={"text"}>{t('form.companyName')}</InputField>
                                </div>
                                <div className="w-1/2">
                                    <InputField
                                        autoFocus={autoFocus[1]}
                                        error={error[1]} errorMsg="errors.companyStreet"
                                        formSubmitted={formSubmittedToggle} value={value[1] as string}
                                        onChangeHandler={onChangeHandler[1]}
                                        tabIndex={8} type={"text"}>{t('form.street')}</InputField>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="w-1/2 mr-20">
                                        <Dropdown autoFocus={!error[0] && error[4]}
                                            error={error[11]}  formSubmitted={formSubmittedToggle} isPackage
                                            title={t('form.package')} options={dropDownOptions} value={dropDownValue}
                                            setValue={bookedPackage => dropDownHandler(bookedPackage as SetStateAction<{ value: string; label: string }>)}
                                            tabIndex="2"/>
                                </div>
                                <div className="w-1/2">
                                    <div className="flex flex-row max-w-md">                                       
                                        <div className="flex w-1/3 flex-col mr-2">
                                            <InputField
                                                autoFocus={autoFocus[2]}
                                                error={error[2]} errorMsg="errors.companyZip"
                                                formSubmitted={formSubmittedToggle} value={value[2] as string}
                                                onChangeHandler={onChangeHandler[2]}
                                                tabIndex={9} type={"number"}>{t('form.zip')}</InputField>
                                        </div>
                                        <div className="flex w-2/3 flex-col">
                                            <InputField
                                                autoFocus={autoFocus[3]}
                                                error={error[3]} errorMsg="errors.companyCity"
                                                formSubmitted={formSubmittedToggle} value={value[3] as string}
                                                onChangeHandler={onChangeHandler[3]}
                                                tabIndex={10} type={"text"}>{t('form.city')}</InputField>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="w-1/2 mr-20">
                                    <InputField
                                        autoFocus={autoFocus[4]}
                                        error={error[4]} errorMsg="errors.companyParticipators"
                                        formSubmitted={formSubmittedToggle} value={value[4] as string}
                                        onChangeHandler={onChangeHandler[4]}
                                        tabIndex={3} type={"number"}>{t('form.participants')}</InputField>
                                </div>
                                <div className="w-1/2"/>
                            </div>
                            <div className="mb-10"/>
                            <div className="flex flex-col">
                                <div className="flex flex-row">
                                    <div className="w-1/2 mr-20">
                                        <InputField
                                            autoFocus={autoFocus[5]}
                                            error={error[5]} errorMsg="errors.contactFirstName"
                                            formSubmitted={formSubmittedToggle} inputName="given-name"
                                            nameSpace='basics' value={value[5] as string}
                                            onChangeHandler={onChangeHandler[5]}
                                            tabIndex={4} type={"text"}>{t('form.prename')}</InputField>
                                    </div>
                                    <div className="w-1/2">
                                        <InputField
                                            autoFocus={autoFocus[9]}
                                            error={error[9]} errorMsg="errors.password"
                                            formSubmitted={formSubmittedToggle}
                                            value={value[9] as string}
                                            onChangeHandler={onChangeHandler[9]}
                                            tabIndex={11} type={"password"}>{t('form.password')}</InputField>
                                    </div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-1/2 mr-20">
                                        <InputField
                                            autoFocus={autoFocus[6]}
                                            error={error[6]} errorMsg="errors.contactLastName"
                                            formSubmitted={formSubmittedToggle} inputName="family-name"
                                            nameSpace='basics' value={value[6] as string}
                                            onChangeHandler={onChangeHandler[6]}
                                            tabIndex={5} type={"text"}>{t('form.surname')}</InputField>
                                    </div>
                                    <div className="w-1/2">
                                        <InputField
                                            autoFocus={autoFocus[10]}
                                            error={error[10]} errorMsg="errors.passwordConfirmation"
                                            formSubmitted={formSubmittedToggle} value={value[10] as string}
                                            onChangeHandler={onChangeHandler[10]}
                                            tabIndex={12}
                                            type={"password"}>{t('form.passwordConfirmation')}</InputField>
                                    </div>
                                </div>
                                <div className="flex flex-row">
                                    <div className="w-1/2 mr-20">
                                        <InputField
                                            autoFocus={autoFocus[7]}
                                            error={error[7]} errorMsg="errors.contactTelephone"
                                            formSubmitted={formSubmittedToggle} value={value[7] as string}
                                            onChangeHandler={onChangeHandler[7]}
                                            tabIndex={6} type={"number"}>{t('form.telefone')}</InputField>
                                    </div>
                                    <div className="w-1/2 flex flex-col mt-6" tabIndex={13}>
                                        <SwitchButton
                                            formSubmitted={formSubmittedToggle} value={value[11] as boolean}
                                            setValue={termsHandler} //termshandler ändern
                                            textFirst={t('form.switchTextFirst')}
                                            width={70}/>
                                    </div>
                                </div>
                                <div className="flex flex-row">
                                    {alreadyRegistered &&
                                    <div className="flex flex-col w-1/2 mr-20">
                                        <InputField
                                            autoFocus={autoFocus[8]}
                                            error={error[8]} errorMsg="errors.mail"
                                            formSubmitted={formSubmittedToggle} nameSpace='basics'
                                            value={value[8] as string}
                                            onChangeHandler={onChangeHandler[8]}
                                            tabIndex={6} type={"email"}>{t('form.email')}</InputField>
                                        <div className="mt-3">
                                            <div className="relative">
                                                <div className="ml-4 arrow-up absolute top-minus11"/>
                                            </div>
                                            <input
                                                className="text-base bg-uw-errorRed flex w-full p-2 rounded-lg font-text leading-text font-normal text-uw-errorRedText"
                                                defaultValue={t('basics:errors.mailInUse')}
                                                disabled={true}
                                                ref={emailRef}
                                            />
                                        </div>
                                    </div>
                                    }
                                    {!alreadyRegistered &&
                                    <div className="w-1/2 mr-20">
                                        <InputField
                                            autoFocus={autoFocus[8]}
                                            error={error[8]} errorMsg="errors.mail"
                                            formSubmitted={formSubmittedToggle} nameSpace='basics'
                                            value={value[8] as string}
                                            onChangeHandler={onChangeHandler[8]}
                                            tabIndex={6} type={"email"}>{t('form.email')}</InputField>
                                    </div>
                                    }
                                    <div className="w-1/2 mt-8">
                                        <Button onClick={handleSubmit} backgroundWhite borderColor={false}
                                                title={t('form.buttonRegistration')}
                                                type="submit"/>
                                        <div className="mt-4 ml-2">
                                            <a className="flex" href={`https://luksit.de/datenschutz/${en}#luksit-app`} rel="noopener noreferrer" target="_blank">
                                                <p className="font-text hover:underline leading-none sm:text-base text-white">{t('form.dataPrivacy')}</p>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
    )
}

export default BodyForm;
