// Firebase
//import { useUserContext } from "../firebase/FirebaseContext";
import { firestore } from "../firebase/Firebase"
//import { useState } from "react"

import { doc } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";

export interface IUserData {
  [index: string]: number
}

export interface IProfile {
  template?: string,
  id: string,
  company?: any,
  name: string,
  description: string,
  data: IUserData
}


export const UseGetEmployeeProfile = (): any => {


  //const {companyUser} = useUserContext()

  const getEmployeeProfiles = async (userid: string) => {

    try {

      const userDoc = doc(firestore, 'user', userid)
      const q = query(collection(firestore, "profiles"), where("user", "==", userDoc));
      const querySnapshot = await getDocs(q);

      if(querySnapshot.docs[0]) {

        let profile: IProfile = {
          template:querySnapshot.docs[0].data().template,
          id: querySnapshot.docs[0].id,
          name: querySnapshot.docs[0].data().name,
          description: querySnapshot.docs[0].data().description,
          data:querySnapshot.docs[0].data().data
        }

        return profile

      }
      
      return false

    } catch(error) {
      console.log(error)
    }


  }

  return getEmployeeProfiles
}
