// React
import React, { useEffect, useState, createContext, useContext, useCallback, Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Firebase
import { onAuthStateChanged, signInWithEmailAndPassword, User } from 'firebase/auth'
import { auth, firestore } from './Firebase'
import { doc, getDoc } from 'firebase/firestore'
// Interfaces
import { Company, Recruiting, CompanyAndRecruiting, CurrentUser, Employee, Candidate, RecruitingDemo } from './UserIntefaces'
import { useLocalStorage } from '../hooks/useLocalStorage'

//import LoadingScreen from "./../components/layout/LoadingScreen";


type G8tResultLocalesStrRisk = {
  strengthsAndRisksDE: Record<string, string>,
  strengthsAndRisksEN: Record<string, string>
}

type G8tResultLocalesStandard = {
  standardDE: Record<string, string>,
  standardEN: Record<string, string>
}

type G8tQuestions = Array<{
  questions: Array<{
    question: string;
    id: string;
  }>
}>

type ContextState = {
  employeeUser: Employee | Candidate | null,
  companyUser: Company | Recruiting | RecruitingDemo | CompanyAndRecruiting | null,
  currentUser: CurrentUser | null,
  user: User | null,
  logIn: (email: string, password: string) => Promise<boolean>,
  isLoggedIn: boolean,
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>
  loading: boolean,
  emailError: boolean
  passwordError: boolean
  errorEmailMsg: string
  errorPasswordMsg: string
  resultLocalesStrengthsAndRisks: G8tResultLocalesStrRisk | null
  resultLocalesStandard: G8tResultLocalesStandard | null
  questions: G8tQuestions | null
}

const FirebaseContext = createContext<ContextState | undefined>(undefined)

const FirebaseProvider: React.FC = ({ children }) => {

  //useCurrentUser hook
  const [user, setUser] = useState<User | null>(auth.currentUser)
  const [currentUser, setCurrentUser] = useState<CurrentUser>(null)
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage<boolean>('isLoggedIn', false)
  const [loading, setLoading] = useState(true)
  //const [emailError, setEmailError] = useState<boolean>(false)
  //const [passwordError, setPasswordError] = useState<boolean>(false)
  //const [errorEmailMsg, setErrorEmailMsg] = useState<string>('')
  //const [errorPasswordMsg, setErrorPasswordMsg] = useState<string>('')
  const [resultLocalesStrengthsAndRisks, setResultLocalesStrengthsAndRisks] = useState<G8tResultLocalesStrRisk | null>(null)
  const [resultLocalesStandard, setResultLocalesStandard] = useState<G8tResultLocalesStandard | null>(null)
  const [questions, setQuestions] = useState<G8tQuestions | null>(null)

  const { i18n } = useTranslation()

  const history = useHistory()

  let employeeUser: Employee | Candidate | null = null
  let companyUser: Company | Recruiting | RecruitingDemo | CompanyAndRecruiting | null = null

  //useLogin hook
  const logIn = async (email: string, password: string) => {

    try {
      const result = await signInWithEmailAndPassword(auth, email, password)
      await getCurrentUserByRole(auth.currentUser)
      setUser(auth.currentUser)
      const docRefUser = doc(firestore, 'users', result.user.uid)
      const docSnapUser = await getDoc(docRefUser)
        if (docSnapUser.exists()) {
          //await getCurrentUserByRole(auth.currentUser)
          switch (docSnapUser.data().role) {
            case 'EMPLOYEE':
              setIsLoggedIn(true)
              history.push('/employee')
              break
            case 'CANDIDATE':
              setIsLoggedIn(true)
              history.push('/candidate')
              break
            case 'COMPANY':
              setIsLoggedIn(true)  
              history.push('/company/dashboard')
              break
            case 'RECRUITING':
              setIsLoggedIn(true)  
              history.push('/recruiting/dashboard')
              break
            case 'RECRUITING_DEMO':
                setIsLoggedIn(true)  
                history.push('/recruiting/dashboard')
                break
            case 'COMPANY_AND_RECRUITING':
              setIsLoggedIn(true)  
              history.push('/company/dashboard')
              break
            case 'LUKSIT_ADMIN':
              setIsLoggedIn(true)  
              history.push('/luksit/dashboard')
              break
            default:
              //console.log("No role found for this user!")
          }
        }
        return true
    } catch(error) {
      setIsLoggedIn(false)
      setUser(null)
      return false
    }

  }

  //const getUserByRole

  //const getCurrentUserByRole = useCallback(async () => {
  const getCurrentUserByRole = async (user: any) => {
    let employee: any = null
    let candidate: any = null
    let company: any = null
    let recruiting: any = null
    let recruitingDemo: any = null
    let companyAndRecruiting: any = null
    let luksitAdmin: any = null

    if (user) {
      const docRefUser = doc(firestore, 'users', user.uid)
      const docSnapUser = await getDoc(docRefUser)
      if (docSnapUser.exists()) {
        switch (docSnapUser.data().role) {
          case 'EMPLOYEE':
            const docRefEmployee = doc(firestore, 'employees', docSnapUser.data().employee.id)
            const docSnapEmployee = await getDoc(docRefEmployee)
            if (docSnapEmployee.exists()) {
              employee = docSnapEmployee.data()
              setCurrentUser({
                ...employee,
                role: docSnapUser.data().role
              })
            }
            break
          case 'CANDIDATE':
            const docRefCandidate = doc(firestore, 'employees', docSnapUser.data().employee.id)
            const docSnapCandidate = await getDoc(docRefCandidate)
            if (docSnapCandidate.exists()) {
              candidate = docSnapCandidate.data()
              setCurrentUser({
                ...candidate,
                role: docSnapUser.data().role
              })
            }
            break
          case 'COMPANY':
            const docRefCompany = doc(firestore, 'companies', docSnapUser.data().company.id)
            const docSnapCompany = await getDoc(docRefCompany)
            if (docSnapCompany.exists()) {
              company = docSnapCompany.data()
              setCurrentUser({
                ...company,
                role: docSnapUser.data().role,
                companyId: docSnapUser.data().company.id
              })
            }
            break
          case 'RECRUITING':
            const docRefRecruiting = doc(firestore, 'companies', docSnapUser.data().company.id)
            const docSnapRecruiting= await getDoc(docRefRecruiting)
            if (docSnapRecruiting.exists()) {
              recruiting = docSnapRecruiting.data()
              setCurrentUser({
                ...recruiting,
                role: docSnapUser.data().role,
                companyId: docSnapUser.data().company.id
              })
            }
            break
          case 'RECRUITING_DEMO':
            const docRefRecruitingDemo = doc(firestore, 'companies', docSnapUser.data().company.id)
            const docSnapRecruitingDemo= await getDoc(docRefRecruitingDemo)
            if (docSnapRecruitingDemo.exists()) {
              recruitingDemo = docSnapRecruitingDemo.data()
              setCurrentUser({
                ...recruitingDemo,
                role: docSnapUser.data().role,
                companyId: docSnapUser.data().company.id
              })
            }
            break
          case 'COMPANY_AND_RECRUITING':
            const docRefCompanyRecruiting = doc(firestore, 'companies', docSnapUser.data().company.id)
            const docSnapCompanyRecruiting = await getDoc(docRefCompanyRecruiting)
            if (docSnapCompanyRecruiting.exists()) {
              companyAndRecruiting = docSnapCompanyRecruiting.data()
              setCurrentUser({
                ...companyAndRecruiting,
                role: docSnapUser.data().role,
                companyId: docSnapUser.data().company.id
              })
            }
            break
          case 'LUKSIT_ADMIN':
            setCurrentUser({
              ...luksitAdmin,
              role: docSnapUser.data().role
            })
            break
          default:
            //console.log('No role found for this user')
        }
      }
    }
  }
  //}, [user])

  const getResultLocalesStrengthsAndRisks = useCallback(async () => {
    if (user) {
      const docRefRoc = doc(firestore, 'result_locales', 'roc')
      const docSnapRoc = await getDoc(docRefRoc)
      if (docSnapRoc.exists()) {
        setResultLocalesStrengthsAndRisks({
          strengthsAndRisksDE: docSnapRoc.data().strengthsAndRisksDE,
          strengthsAndRisksEN: docSnapRoc.data().strengthsAndRisksEN
        })
      }
    }
  }, [user])

  const getResultLocalesStandard = useCallback(async () => {
    if (user) {
      const docRefRocStandard = doc(firestore, 'result_locales', 'roc')
      const docSnapRocStandard = await getDoc(docRefRocStandard)
      if (docSnapRocStandard.exists()) {
        setResultLocalesStandard({
          standardDE: docSnapRocStandard.data().standardDE,
          standardEN: docSnapRocStandard.data().standardEN
        })
      }
    }
  }, [user])

  const getQuestions = useCallback(async () => {
    if (user) {
      const docRefQuestions = doc(firestore, 'questions', 'roc')
      const docSnapQuestions = await getDoc(docRefQuestions)
      if (docSnapQuestions.exists()) {
        if (i18n.language.includes('de'))
          setQuestions(docSnapQuestions.data().g8tDE)
        else
          setQuestions(docSnapQuestions.data().g8tEN)
      }
    }
  }, [i18n.language, user])


  
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {

      const setUserByRole = async(user: any) => {
        await getCurrentUserByRole(user)
        setLoading(false)
        setUser(user)
      }
      setUserByRole(user)
      
      if (user) {
        //console.log('changed...')
        //console.log("USER LOGGED IN", user)
        //setUser(user)
        //getCurrentUserByRole(user)
        getResultLocalesStrengthsAndRisks()
        getResultLocalesStandard()
        getQuestions()
      }
      else {
        //console.log("USER LOGGED OUT")
        setUser(null)
        setCurrentUser(null)
      }
    })

    return () => {
      unsubscribe()
    }

    //Session timeout
    //https://stackoverflow.com/questions/58355878/firebase-auth-set-session-expiration
  }, [
    //getCurrentUserByRole,
    //currentUser
    getQuestions,
    getResultLocalesStandard,
    getResultLocalesStrengthsAndRisks
  ])



  if (currentUser) {
    switch (currentUser.role) {
      case 'EMPLOYEE':
        employeeUser = currentUser
        break
      case 'CANDIDATE':
        employeeUser = currentUser
        break
      case 'COMPANY':
        companyUser = currentUser
        break
      case 'RECRUITING':
        companyUser = currentUser
        break
      case 'RECRUITING_DEMO':
        companyUser = currentUser
        break
      case 'COMPANY_AND_RECRUITING':
        companyUser = currentUser
        break
      default:
        //console.log('No user role found')
    }
  }

  const value = {
    employeeUser,
    companyUser,
    currentUser,
    user,
    logIn,
    isLoggedIn,
    setIsLoggedIn,
    loading,
    //emailError,
    //passwordError,
    //errorEmailMsg,
    //errorPasswordMsg,
    resultLocalesStrengthsAndRisks,
    resultLocalesStandard,
    questions
  }
  /*if(!loading) {
    return (<><p>Loading...</p></>)
  }*/
  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  )
}

const useUserContext = () => {
  const context = useContext(FirebaseContext)

  if (!context) throw Error('must be used inside provider')

  return context
}

export { FirebaseProvider, useUserContext }

