import { SearchIcon } from '@heroicons/react/outline'
import React, { Dispatch, SetStateAction } from 'react'

export interface ITab {
  name: string,
  href: string,
  current: boolean,
  onClick: () => void
}

export interface ITabs {
  headline: string,
  tabs: ITab[]
  setSearch: Dispatch<SetStateAction<string>>
  setActive: Dispatch<SetStateAction<boolean[]>>
}

const EmployeeListHeadingCards: React.FC<ITabs> = ({ headline, tabs, setSearch, setActive }) => {

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (tabs[0].name === e.target.value)
      setActive([true, false, false])
    else if (tabs[1].name === e.target.value)
      setActive([false, true, false])
    else if (tabs[2].name === e.target.value)
      setActive([false, false, true])
  }

  return (
    <div className="pb-5 border-b border-gray-200">
      <h3 className="text-2xl leading-6 font-medium text-uw-teal lg:mt-2 lg:mb-6">{headline}</h3>
      <div className="mt-3 sm:mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            onChange={handleOptionChange}
          >
            {(tabs) ? tabs.map((tab) => (
              <option key={tab.name}>
                {tab.name}
              </option>
            )) : ''}
          </select>
        </div>
        <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <div className="hidden sm:block sm:col-start-1 sm:col-end-2 lg:col-start-1 lg:col-end-3">
            <nav className="flex space-x-8">
              {(tabs) ? tabs.map((tab) => (
                <div
                  key={tab.name}
                  onClick={() => tab.onClick()}
                  className={classNames(
                    tab.current
                      ? 'border-uw-teal text-uw-teal cursor-pointer pt-2'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer pt-2',
                    'whitespace-nowrap px-1 border-b-2 font-medium text-sm cursor-pointer pt-2'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </div>
              )) : ''}
            </nav>
          </div>
          <div className="pb-2">
            <label htmlFor="search" className="sr-only">
              Search projects
            </label>
            <div className="relative text-uw-teal">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                className="block w-full pl-10 pr-5 py-1 border rounded-md leading-5 focus:outline-none sm:text-sm"
                placeholder="Search Username or email"
                type="search"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeListHeadingCards
