import { useState } from 'react';
import { ValueType } from "react-select/lib/types";

export type OptionType = {
    value: string
    label: string
}

export const useFormSelectFieldState = (defaultValue: string): [string, (option: ValueType<OptionType>) => void] => {

    const [value, setValue] = useState('');
    const setter = (option: ValueType<OptionType>) => {
        const value = (option as OptionType).value;
        setValue(value);
    }

    return [value, setter];
}