import React, {useState} from 'react'
import EmployeeCardHead from "./EmployeeCardHead";
import EmployeeCardBody from "./EmployeeCardBody";
import EmployeeCardGraphs from "./EmployeeCardGraphs";
import { useTranslation } from "react-i18next";

interface IProps {
    cardPoints: number
    cardHeadTitle: string
    cardHeadText: string
    cardSubPartTexts: Array<Array<string>>
    chartExplanations: Array<string>
    chartPoints: Array<number>
    chartText: Array<string>
}

const EmployeeCard: React.FC<IProps> = (
    {
        cardPoints,
        cardHeadText,
        cardHeadTitle,
        cardSubPartTexts,
        chartExplanations,
        chartText,
        chartPoints
    }) => {

    const handleButtonClick = () => {
        setCardOpen(!cardOpened);
    }

    const [cardOpened, setCardOpen] = useState(false)
    const { t } = useTranslation('employeeResults')

    return (
        <>
            <div className="bg-uw-lightTeal rounded-lg shadow-cardShadow px-2 py-2">
                <EmployeeCardHead cardPoints={cardPoints} cardOpened={cardOpened} setCardOpened={handleButtonClick} title={cardHeadTitle} text={cardHeadText}/>
                <EmployeeCardGraphs chartExplanations={chartExplanations} chartHeadlines={t('card.chartHeadline')} chartPoints={chartPoints} chartText={chartText} hidden={!cardOpened}/>
                <EmployeeCardBody mainTitles={t('card.strengthsAndRisksTitles')} showCard={cardOpened} titles={t('card.strengthsAndRisksSubPartTitle')} texts={cardSubPartTexts}/>
            </div>
        </>
    );
}

export default EmployeeCard