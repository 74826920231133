import React from 'react'
import Container from "../components/layout/Container";
import H1 from "../components/textStyling/H1";
import FaqDropdown from "../components/layout/FaqDropdown";
import H2 from "../components/textStyling/H2";
import {useTranslation} from "react-i18next";

const Faq: React.FC = () => {

    window.scrollTo({top: 0, behavior: 'smooth'});

    const {t} = useTranslation(['employeeFaq', 'basics'])
    const titlesPartOne = t('questionsPartOne') as Array<string>
    const textsPartOne = t('answersPartOne') as Array<Array<string>>
    const subtitlesPartOne = t('subTitlesPartOne') as Array<Array<string>>
    const titlesPartTwo = t('questionsPartTwo') as Array<string>
    const subtitlesPartTwo = t('subtitlesPartTwo') as Array<Array<string>>
    const textsPartTwo = t('answersPartTwo') as Array<Array<string>>

    return (
        <>
            <Container background="white" headerFooter login logout>
                <div className="mb-10 mt-12 lg:mt-32 xl:mt-32 lg:mb-24 xl:mb-24 lg:w-2/3 xl:w-2/3">
                    <H1 color="uw-teal">
                        {t('headline')}
                    </H1>
                </div>
                <div className="lg:flex xl:flex lg:flex-col xl:flex-col">
                    <div className="lg:flex xl:flex lg:flex-row xl:flex-row lg:mb-16 xl:mb-16">
                        <div className="mb-8 lg:mb-0 xl:mb-0 lg:pt-4 xl:pt-4 lg:w-4/12 xl:w-4/12">
                            <H2 color="uw-teal">
                                {t("questionsPartOneHeadline")}
                            </H2>
                        </div>
                        <div
                            className="lg:flex xl:flex lg:flex-col xl:flex-col lg:flex-grow xl:flex-grow lg:w-8/12 xl:w-8/12">
                            <hr className="border-uw-teal border w-full"/>
                            {titlesPartOne.map((title, index) => (
                                <FaqDropdown key={index} headline={title} subTitles={subtitlesPartOne[index]} texts={textsPartOne[index]}/>
                            ))}
                        </div>
                    </div>
                    <div className="lg:flex xl:flex lg:flex-row xl:flex-row">
                        <div className="my-8 lg:my-0 xl:my-0 lg:pt-4 xl:pt-4 lg:w-4/12 xl:w-4/12">
                            <H2 color="uw-teal">
                                {t("questionsPartTwoHeadline")}
                            </H2>
                        </div>
                        <div
                            className="lg:flex xl:flex lg:flex-col xl:flex-col lg:flex-grow xl:flex-grow lg:w-8/12 xl:w-8/12 mb-24">
                            <hr className="border-uw-teal border w-full"/>
                            {titlesPartTwo.map((title, index) => (
                                <FaqDropdown key={index} headline={title} subTitles={subtitlesPartTwo[index]} texts={textsPartTwo[index]}/>
                            ))}
                        </div>
                    </div>
                </div>
            </Container> 
        </>
    )
}

export default Faq;