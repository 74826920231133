import React, {useState} from 'react'
import Button from "./Button"
import Container from "./Container"
import H1 from "../textStyling/H1"
import Text from "../textStyling/Text"
import {Redirect} from "react-router";
import {useTranslation} from "react-i18next";

interface IProps {
    buttonLink?: Array<string>
    buttonTitle?: Array<string>
    errorMsg: string
    headerFooter?: boolean,
    header?: string
}

const ErrorComponent: React.FC<IProps> = ({buttonLink, buttonTitle, errorMsg, headerFooter, header = null}) => {

    const [buttonClicked, setButtonClicked] = useState(false)
    const [buttonTwoClicked, setButtonTwoClicked] = useState(false)
    const {t} = useTranslation('errorComponent')
    const uuidv1 = require('uuid/v1')

    return (
        <>
            {(buttonTitle && buttonLink) && buttonClicked && <Redirect push to={buttonLink[0]}/>}
            {buttonLink !== undefined && buttonLink.length > 1 && buttonTwoClicked &&
            <Redirect push to={buttonLink[1]}/>}
            <Container background="white" headerFooter={headerFooter === undefined ? false : headerFooter} login logout>
                <div className="lg:flex xl:flex lg:justify-center xl:justify-center h-screen">
                    <div className="lg:w-2/3 xl:w-2/3">
                        <div className="flex flex-col justify-center content-center align-middle lg:px-40 xl:px-40">
                            <div className="mb-10 mt-12 lg:mt-32 xl:mt-32 lg:mb-12 xl:mb-12 text-center">
                                <H1 color="uw-teal">
                                    {(header===null)?t('error.headline'):header}
                                </H1>
                            </div>
                            <div className="hidden lg:mb-6 xl:mb-6 lg:text-center xl:text-center lg:flex xl:flex">
                                <Text color="text-uw-grey" one>
                                    {errorMsg}
                                </Text>
                            </div>
                            <div className="mb-6 text-center lg:hidden xl:hidden">
                                <Text color="text-uw-grey">
                                    {errorMsg}
                                </Text>
                            </div>
                            {(buttonLink && buttonTitle) &&
                            <>
                                <div
                                    className="flex flex-col lg:flex-row xl:flex-row lg:justify-between xl:justify-between items-center">
                                    <div className="mb-8 lg:mb-0 xl:mb-0 lg:w-1/2 xl:w-1/2 lg:flex lg:justify-center xl:flex xl:justify-center lg:mr-8 xl:mr-8" key={uuidv1()}>
                                        <Button className="py-2" onClick={setButtonClicked} title={buttonTitle[0]}
                                                type="button"/>
                                    </div>
                                    {buttonLink[1] &&
                                    <div className="lg:w-1/2 xl:w-1/2 lg:flex lg:justify-center xl:flex xl:justify-center" key={uuidv1()}>
                                        <Button backgroundWhite borderColor className="py-2"
                                                onClick={setButtonTwoClicked} title={buttonTitle[1]}
                                                type="button"/>
                                    </div>}
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ErrorComponent