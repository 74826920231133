// Firebase
import { firestore } from "../firebase/Firebase"
import { doc, deleteDoc  } from 'firebase/firestore'

export interface IUserData {
  [index: string]: number
}

export interface IProfile {
  company?: any,
  name: string,
  description: string,
  data: IUserData
}


export const useDeleteProfile = () => {

  const deleteProfile =  async (profileId : string) => {

    const docRef = doc(firestore, "profiles", profileId);
    await deleteDoc(docRef);

  }

  return deleteProfile
}
