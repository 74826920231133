import React, {useCallback, useState} from 'react'
import classnames from "classnames"
import EmployeeChart from "./EmployeeChart";
import Text from "../../textStyling/Text";
import IconRectangle from "../../design/IconRectangle";

interface Props {
    chartExplanations: string
    chartPoints: number
    chartText: string
}

const EmployeeGraphCard: React.FC<Props> = ({chartExplanations, chartText, chartPoints}) => {

    const [cardOpened, setCardOpened] = useState(false)
    const toggleExpand = useCallback(() => setCardOpened(!cardOpened), [setCardOpened, cardOpened])

    return (
        <>
            <div className="bg-white rounded-15px shadow-cardShadow px-6 py-6">
                <div className="flex flex-row justify-between" onClick={toggleExpand}>
                    <div className={classnames("flex flex-1 justify-start",
                        {"mb-4" : cardOpened}
                        )}>
                        <Text fat color="text-uw-teal">
                            {chartExplanations}
                        </Text>
                    </div>
                    <div className='flex justify-end pt-rectangleOpen'>
                        <div>
                            {cardOpened &&
                                <div className="rotate-180">
                                    <IconRectangle/>
                                </div>
                            }
                            {!cardOpened && <IconRectangle/>}
                        </div>
                    </div>
                </div>
                <div className={classnames({
                    'hidden' : !cardOpened
                })}>
                    <div className="flex justify-center">
                        <EmployeeChart points={chartPoints}/>
                    </div>
                    <div className="flex justify-center">
                        <Text color="text-uw-grey">
                            {chartText}
                        </Text>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeGraphCard
