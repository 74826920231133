import React from "react";
import classnames from 'classnames'
import Text from "../../textStyling/Text";

interface Props {
    clicked: boolean
    lastElement: boolean
    selectedIndex: number
    strengthsAndRisks: boolean
    title: string
    onClick: () => void
}

const EmployeeInnerCardLeftTitleCard: React.FC<Props> = ({ clicked, lastElement, onClick, selectedIndex, strengthsAndRisks, title}) => {

    return (
        <>
            <div className={classnames("bg-white px-4 py-6 cursor-pointer",
                {
                    'rounded-15px shadow-cardShadow' : !clicked
                },
                {
                    'rounded-l-15px -mr-8 shadow-desktopResultsRightCard relative z-10' : clicked
                })} onClick={onClick}>
                {clicked && <div className={title.length < 26 ? "pointCardEmployee" : "pointCardEmployeeBig"}/>}
                {clicked && !strengthsAndRisks && <div className={
                    lastElement ?
                        (selectedIndex < 5 ?
                                (title.length > 26 ? "pointCardSecondDownEmployeeCompetences" : "pointCardSecondDownEmployeeCompetences")
                        : (title.length > 26 ? "pointCardSecondDownEmployeeComptencesLargeEnd" : "pointCardSecondDownEmployeeStrengthsAndRisksEnd")
                        ) : (title.length > 26 ? "pointCardSecondDownEmployeeCompetencesBig" : "pointCardSecondDownEmployeeCompetences")}/>}
                {clicked && strengthsAndRisks && <div className={lastElement ? "pointCardSecondDownEmployeeStrengthsAndRisksEnd" : "pointCardSecondDownEmployeeCompetences"}/>}
                {clicked && !strengthsAndRisks && selectedIndex !== 0 && <div className="pointCardThirdEmployee"/>}
                {clicked && strengthsAndRisks && selectedIndex !== 0 && <div className="pointCardThirdEmployeeStrengthsAndRisks"/>}
                <div className="mx-3 items-center">
                    <div className="flex flex-row">
                        <div className="flex flex-1 justify-start">
                            <Text color="text-uw-teal" four headlines>
                                {title}
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployeeInnerCardLeftTitleCard;