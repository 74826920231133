// Firebase
import { useUserContext } from "../firebase/FirebaseContext";
import { firestore } from "../firebase/Firebase"

import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { EStatus } from "./useGetJobStats";
//import { array } from "@hapi/joi";

export interface IUserData {
  [index: string]: number
}

export interface IMatchingCandidate {
  id: string,
  score?: number,
  firstName: string,
  lastName: string,
  email?: string,
  data: IUserData,
  uid: string,
  jobTitle?: string,
  department?: string | null,
  testGroup?: any,
  job:any,
  applyDate: Timestamp,
  status?: EStatus
}


export const UseGetMatchingCandidates = (): any => {

  const {companyUser} = useUserContext()

  const getMatchingCandidates = async (jobId: string) => {
    let allEmployees: IMatchingCandidate[] = [];

    try {

      //const jobRef = await doc(firestore, 'jobs', jobId)
      const q = query(collection(firestore, "employees"), where("testGroups", "==", companyUser!.testGroups), where("jobs."+jobId+".jobId", "==", jobId), where("test.receivedResults", "==", true), where('role', '==', 'CANDIDATE'));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        //console.log('#######', doc.data())
        if(doc.data().test.results && doc.data().test.results.errors) {
          return
        }

        /*let job = null;
        if(doc.data().jobs && doc.data().jobs[0] && doc.data().jobs[0].jobRef) {
          job = [{ job: await (await getDoc(doc.data().jobs[0].jobRef)).data(), date: doc.data().jobs[0].date}]
        }*/

        let status: EStatus = EStatus.NEW

        if(doc.data().jobs && doc.data().jobs[jobId] && doc.data().jobs[jobId].status && doc.data().jobs[jobId].status[0]) {
          status = doc.data().jobs[jobId].status[doc.data().jobs[jobId].status.length-1].status
        }

        let employee: IMatchingCandidate = {
          id: doc.id,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          email: doc.data().email,
          data: doc.data().test.results,
          uid: doc.data().uid,
          jobTitle: doc.data().jobTitle,
          department: doc.data().department,
          job: doc.data().jobs[jobId],
          applyDate: doc.data().jobs[jobId].date,
          status: status
        }

        allEmployees.push(employee)

      });

    } catch(err) {
      console.log('err:', err)
    }

    return allEmployees;

  }

  return getMatchingCandidates
}
