import { useState } from 'react';

export const useFormFieldState = (defaultValue: string): [string, (e: React.ChangeEvent<HTMLInputElement> | string) => void] => {
    const [value, setValue] = useState(defaultValue);

    const setter = (e: React.ChangeEvent<HTMLInputElement> | string) => {
        if(typeof e === 'object')
            setValue(e.currentTarget.value);
        else if(typeof e === 'string')
            setValue(e);
    }

    return [value, setter];
}