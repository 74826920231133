import React from 'react'
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid'
import { INavigationMainItem } from './CustomMenu'
import { Link } from 'react-router-dom'

export interface IBreadCrumpNavigation {
  homePath: INavigationMainItem,
  currentPaths: INavigationMainItem[]
}

const BreadcrumbNavigation: React.FC<IBreadCrumpNavigation> = ({ homePath, currentPaths }) => {

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to={homePath.href} className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">{homePath.name}</span>
            </Link>
          </div>
        </li>
        {currentPaths.map((currentPath, i) => (
          <li key={currentPath.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
              <Link
                to={currentPath.href}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={currentPath.current ? 'page' : undefined}
              >
                {currentPath.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default BreadcrumbNavigation
