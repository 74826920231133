import React from 'react'
import classnames from "classnames"
import EmployeeGraphCard from "./EmployeeGraphCard";

interface IProps {
    chartExplanations: Array<string>
    chartHeadlines: string
    chartPoints: Array<number>
    chartText: Array<string>
    hidden: boolean

}

const EmployeeCardGraphs: React.FC<IProps> = ({chartExplanations, chartPoints, chartText, hidden}) => {

    const uuidv1 = require('uuid/v1')

    const generateCharts = () => {
        let chartArray: Array<object> = []

        for(let i = 0; i < chartPoints.length; i++) {
            chartArray.push(
                <div key={uuidv1()} className="mb-4">
                    <EmployeeGraphCard
                        key={uuidv1()}
                        chartExplanations={chartExplanations[i]}
                        chartPoints={chartPoints[i]}
                        chartText={chartText[i]}
                    />
                </div>
            )
        }
        return chartArray
    }

    return (
        <>
            <div className={classnames(
                {
                    "hidden" : hidden
                }
            )}>
                {generateCharts()}
            </div>
        </>
    )
}

export default EmployeeCardGraphs