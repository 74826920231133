//React
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// Firebase
import { signOut } from 'firebase/auth'
import { auth } from '../firebase/Firebase'
import { useUserContext } from '../firebase/FirebaseContext'


const Logout: React.FC = () => {
  const { setIsLoggedIn } = useUserContext()
  const history = useHistory();
  useEffect(() => {
    (async () => {
      await signOut(auth)
      setIsLoggedIn(false)
      localStorage.clear()
      history.push('/login')
    })()
  })
  return <div />
}

export default Logout;
