import React from 'react'
import CompanyFaqView from "../components/companyDashbaord/CompanyFaqView"
import { INavigation } from "../components/layout/Menu/CustomMenu"


const CompanyFaq: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

    return (
        <>
            <CompanyFaqView mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
        </>
    )
}

export default CompanyFaq