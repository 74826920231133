export interface ICompetenceData {
    [index: string]: {
        [index: number]: {
            [index: string]: {
                element?: string,
                [index: number]: {
                    string: string
                } | string
            }
        }
    }
}

function transformToDataObject(data: Record<string, string>) : ICompetenceData {

    let competenceData: ICompetenceData = {}

    //console.log(data)

    for(const key in data) {

        let allKeys = key.split('.')

        if(allKeys[1]) {

            let type    = allKeys[1].replace(/[0-9]/g, '')
            let index   = Number(allKeys[1].replace(/[^\d]/g, ''))

            if(!competenceData[type]) {
                competenceData[type] = {}
            }

            if(!competenceData[type][index]) {
                competenceData[type][index] = {element: allKeys[1]}
            }

            if(allKeys[2] && !allKeys[3]) {
                if(type === 'trait') {
                    let subType    = allKeys[2].replace(/[0-9]/g, '')
                    let subIndex   = Number(allKeys[2].replace(/[^\d]/g, ''))
                    if(subType === 'level') {
                        if(!competenceData[type][index][subType]) {
                            competenceData[type][index][subType] = {}
                        }
                        competenceData[type][index][subType][subIndex] = data[key]
                    } else {
                        competenceData[type][index][allKeys[2]] = data[key]
                    }
                } else {
                    competenceData[type][index][allKeys[2]] = data[key]
                }
            }

        }

    }

    return competenceData;
}

function transformStrenghAndRisksToDataObject(data: Record<string, string>) : ICompetenceData {

    let competenceData: ICompetenceData = {}

    //console.log(data)

    for(const key in data) {

        let allKeys = key.split('.')

        if(allKeys[1]) {

            let type    = allKeys[1].replace(/[0-9]/g, '')
            let index   = Number(allKeys[1].replace(/[^\d]/g, ''))

            if(type === 'category') {
                if(!competenceData[type]) {
                    competenceData[type] = {}
                }
                if(!competenceData[type][index]) {
                    competenceData[type][index] = {element: allKeys[1]}
                }

                if(allKeys[2] === 'paragraphs' && allKeys[3] && allKeys[4]) {
    
                    if( allKeys[3] === 'general') {
                        competenceData[type][index]['general'] = data[key]
                    } else {
                        let subType    = allKeys[3].replace(/[0-9]/g, '')
                        let subIndex   = Number(allKeys[4].replace(/[^\d]/g, ''))
                        let indexName   = allKeys[4].replace(/[0-9]/g, '')
                        //console.log(name, index,indexName, data[key])
        
                        
                        if(!competenceData[type][index][subType+'_'+indexName]) {
                            competenceData[type][index][subType+'_'+indexName] = {}
                        }
                        if(indexName === 'strengths' || indexName === 'risks') {
                            competenceData[type][index][subType+'_'+indexName][subIndex] = data[key]
                        } else if(indexName === 'text') {
                            if(!competenceData[type][index][subType+'_strengths']) {
                                competenceData[type][index][subType+'_strengths'] = {}
                            }
                            if(!competenceData[type][index][subType+'_risks']) {
                                competenceData[type][index][subType+'_risks'] = {}
                            }
                            competenceData[type][index][subType+'_strengths'][subIndex] = data[key]
                            competenceData[type][index][subType+'_risks'][subIndex] = data[key]
                        }
                    }

                } else {
                    competenceData[type][index][allKeys[2]] = data[key]
                }
                
            }


        }

    }

    return competenceData;
}


export { transformToDataObject, transformStrenghAndRisksToDataObject }