// Firebase
import { useUserContext } from "../firebase/FirebaseContext";
import { firestore } from "../firebase/Firebase"

import { doc, addDoc, collection, Timestamp, query, where, getDocs, updateDoc } from 'firebase/firestore'

export interface IUserData {
  [index: string]: number
}

export interface IProfile {
  template?: string,
  company?: any,
  user?: any,
  name: string,
  description: string,
  data: IUserData,
  updated: number | Timestamp
}


export const useSaveEmployeeProfile = () => {

  const {companyUser} = useUserContext()

  const saveEmployeeProfile =  async (profile : IProfile, uid : string) => {

    try {

      // get company reference
      const companyDoc = doc(firestore, 'companies', companyUser!.companyId)
      const userDoc = doc(firestore, 'user', uid)

      const q = query(collection(firestore, "profiles"), where("user", "==", userDoc));
      const querySnapshot = await getDocs(q);

      // Add company reference to profile
      profile.user = userDoc;
      profile.company = companyDoc;

      // Add Timestamp
      profile.updated = new Timestamp(Math.floor(Number(profile.updated) / 1000), 0)

      if(querySnapshot.docs[0]) {
        const docRef = doc(firestore, "profiles", querySnapshot.docs[0].id);
        await updateDoc(docRef, profile);
      } else {
        await addDoc(collection(firestore, 'profiles'), profile);
      }

    } catch(error) {
      console.log(error)
    }
  }
  return saveEmployeeProfile
}
