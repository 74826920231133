import React, {RefObject, useEffect, useState} from 'react'
import Text from "../textStyling/Text"
import classnames from "classnames"
import { useTranslation } from "react-i18next";

interface shadowObject {
    shadow: string
}

interface Props {
    autoFocus?: boolean
    color?: "text-white" | "text-uw-grey"
    disabled?: boolean
    error?: boolean
    errorMsg?: string
    formSubmitted?: boolean
    inputName?: string
    nameSpace?: string
    onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    opacity?: boolean
    style?: shadowObject
    tabIndex?: number
    type: "email" | "number" | "password" | "text"
    value: string
}

const InputField: React.FC<Props> = ({autoFocus, children, color, disabled, error, errorMsg, formSubmitted, inputName, nameSpace, onChangeHandler, opacity, style, tabIndex, type, value}) => {
    const fieldState = {value, onChange: onChangeHandler};
    const textColor: "text-white" | "text-uw-grey" = color !== undefined ? color : "text-white"
    const inputTextColor = disabled ? "text-gray-600" : "text-uw-grey"
    const borderColor = error ? "border-uw-red" : "border-uw-teal"
    const nameSpaceText = nameSpace ? nameSpace :  "companyRegistration"
    const localRef = React.createRef() as RefObject<HTMLInputElement>
    const [lastFormSubmitted, setLastFormSubmitted] = useState(false)

    const { t } = useTranslation(nameSpaceText)

    useEffect(() => {
        if (autoFocus && error) {
            if(lastFormSubmitted !== formSubmitted) {
                localRef.current!.focus()
                setLastFormSubmitted(!lastFormSubmitted)
            }
        }
    }, [children, formSubmitted, lastFormSubmitted, autoFocus, error, localRef])

    return (
        error ?
            <>
                <div className="lg:hidden xl:hidden mb-1">
                    <Text color={textColor} small>
                        {children}
                    </Text>
                </div>
                <div className="hidden lg:block xl:block lg:mb-1 xl:mb-1">
                    <Text color={textColor} four>
                        {children}
                    </Text>
                </div>
                <div className="mb-3">
                    <input
                        autoComplete={inputName}
                        ref={localRef}
                        className={classnames(
                            style !== undefined && style.shadow, borderColor, opacity && "opacity-80",
                            "bg-white border-2 flex w-full max-w-md p-2 rounded-10px font-text leading-text font-normal text-base text-uw-grey",
                        )}
                        disabled={disabled}
                        tabIndex={tabIndex}
                        type={type} {...fieldState} required
                    />
                    <div className="mt-3">
                        <div className="relative">
                            <div className=" ml-4 arrow-up absolute top-minus11"/>
                        </div>
                        <p
                            className={classnames(
                                "opacity-100 text-xs z-10 bg-uw-errorRed flex w-full max-w-md p-2 rounded-10px font-text leading-text font-normal text-uw-errorRedText",
                                )}
                            defaultValue={t(`${errorMsg}`)}
                        >{t(`${errorMsg}`)}</p>
                    </div>
                </div>
            </>
            :
            <>
                <div className="lg:hidden xl:hidden mb-1">
                    <Text color={textColor} small>
                        {children}
                    </Text>
                </div>
                <div className="hidden lg:block xl:block lg:mb-1 xl:mb-1">
                    <Text color={textColor} four>
                        {children}
                    </Text>
                </div>
                <div className="mb-3">
                    <input
                        autoComplete={inputName}
                        className={classnames(
                            style !== undefined && style.shadow, borderColor, opacity && "opacity-80", inputTextColor,
                            "bg-white border-2 flex w-full max-w-md p-2 rounded-10px font-text leading-text font-normal text-base shadow-inputShadow"
                        )}
                        disabled={disabled}
                        tabIndex={tabIndex}
                        type={type} {...fieldState} required
                    />
                </div>
            </>

    )
}

export default InputField