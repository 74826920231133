import React from 'react'
import EmployeeRequirementProfileNew from "../components/companyDashbaord/CompanyEmployeeRequirementProfile"
import { INavigation } from "../components/layout/Menu/CustomMenu"


const CompanyRequirementProfileNew: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  return (
    <>
      <EmployeeRequirementProfileNew mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
    </>
  )
}

export default CompanyRequirementProfileNew