//React
import React, { useEffect, useState } from 'react'
//import { useParams } from "react-router"
import { useTranslation } from "react-i18next";
import { UseGetJobs, IJob } from '../../hooks/useGetJobs'
import { useUserContext } from "../../firebase/FirebaseContext"
import { CalendarIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { EJobStatus } from '../../hooks/useSaveJob';

interface IJobsStats {
    ALL: number,
    PUBLISHED: number,
    DRAFT: number,
    DEACTIVATED: number,
    HIRED: number,
    NOTHING: number
}

interface ITab {
  id: EJobStatus,
  name: string,
  href: string,
  current: boolean,
  onclick: any
}

const RecruitingRequirementProfileItems: React.FC = () => {

  const { t } = useTranslation(['companyRecruitingDashboard'])

  


  const [allProfiles, setAllProfiles] = useState<IJob[]>([])
  const [jobStats, setJobStats] = useState<IJobsStats>({ALL: 0, PUBLISHED: 0, DRAFT: 0, DEACTIVATED: 0, HIRED: 0, NOTHING: 0})
  const [filteredProfiles, setFilteredProfiles] = useState<IJob[]>([])
  const {companyUser} = useUserContext()
  const [filter, setFilter] = useState<EJobStatus>(EJobStatus.ALL)
  //const [filteredStatus, setFilteredStatus] = useState<EJobStatus>(EJobStatus.ALL)

  const initTabs: ITab[] = [
    { id: EJobStatus.ALL, name: t('companyRecruitingDashboard:requirementProfileItems:status:ALL'), href: '#', current: true, onclick: () => { filterProfiles(EJobStatus.ALL) } },
    { id: EJobStatus.DRAFT, name: t('companyRecruitingDashboard:requirementProfileItems:status:DRAFT'), href: '#', current: false, onclick: () => { filterProfiles(EJobStatus.DRAFT) } },
    { id: EJobStatus.PUBLISHED, name: t('companyRecruitingDashboard:requirementProfileItems:status:PUBLISHED'), href: '#', current: false, onclick: () => { filterProfiles(EJobStatus.PUBLISHED) } },
    { id: EJobStatus.HIRED, name: t('companyRecruitingDashboard:requirementProfileItems:status:HIRED'), href: '#', current: false, onclick: () => { filterProfiles(EJobStatus.HIRED) } },
    { id: EJobStatus.DEACTIVATED, name: t('companyRecruitingDashboard:requirementProfileItems:status:DEACTIVATED'), href: '#', current: false, onclick: () => { filterProfiles(EJobStatus.DEACTIVATED) } },
  ]
  const [tabs, setTabs] = useState<ITab[]>(initTabs)

  const countJobStats = (status: EJobStatus): number => {
    if(status === EJobStatus.ALL)
      return allProfiles.length
    let count: number = allProfiles.filter(function(element){
      return element.status === status;
      }).length
    return count
  }

  const calcAndSetJobStats = () => {
    let tempStats: IJobsStats = {
      ALL: countJobStats(EJobStatus.ALL),
      PUBLISHED: countJobStats(EJobStatus.PUBLISHED),
      DRAFT: countJobStats(EJobStatus.DRAFT),
      DEACTIVATED: countJobStats(EJobStatus.DEACTIVATED),
      HIRED: countJobStats(EJobStatus.HIRED),
      NOTHING: 0
    }
    setJobStats(tempStats)
  }

  useEffect(() => {
    const requestProfiles = async () => {
      const getProfiles = UseGetJobs()
      let allProfilesDB = await getProfiles(companyUser!.companyId)
      //console.log(allProfiles)
      if(allProfilesDB) {
        setAllProfiles(allProfilesDB)
        calcAndSetJobStats()
      }
    }
    requestProfiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[companyUser])

  useEffect(() => {
    filterProfiles(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[allProfiles, filter])

  //console.log(allProfiles)

  const filterProfiles = (status: EJobStatus) => {
    setFilter(status)
    calcAndSetJobStats()
    if(status === EJobStatus.ALL) {
      setFilteredProfiles(allProfiles)
    } else {
      let tempProfiles: IJob[] = Object.assign(allProfiles)
      tempProfiles = allProfiles.filter((element) => { return element.status === status })
      setFilteredProfiles(tempProfiles)
    }

    let tempTabs = tabs.filter((element) => {
      if(element.id === status)
        element.current = true
      else
        element.current = false
      return element })
      setTabs(tempTabs)
  }

  //console.log(jobStats)

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }
  

  return (
<>
    <div className="px-4 sm:px-0 -mt-2">

    {/* Tabs */}
    <div className="sm:hidden">
      <label htmlFor="tabs" className="sr-only">
        Select a tab
      </label>
      {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
      <select
        id="tabs"
        name="tabs"
        className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
        defaultValue={tabs.find((tab) => tab.current).name}
      >
        {tabs.map((tab) => (
          <option key={tab.id}>{tab.name}</option>
        ))}
      </select>
    </div>

    
    <div className="hidden sm:block">
      <div className="">
        <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab: ITab) => (
            <a
              key={tab.id}
              href={tab.href}
              onClick={tab.onclick}
              className={classNames(
                tab.current
                  ? 'border-uw-teal text-uw-teal'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
              )}
            >
              {tab.name}
                <span
                  className={classNames(
                    tab.current ? 'bg-gray-200 text-uw-teal' : 'bg-gray-100 text-gray-600',
                    'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                  )}
                >
                  {jobStats[tab.id]}
                </span>
            </a>
          ))}
        </nav>
        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 mb-4 -mt-16">
            <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
              <div className="ml-4 mt-4">
              </div>
              <div className="ml-4 flex-shrink-0">
              { ((companyUser?.role !== 'RECRUITING_DEMO') || (companyUser?.role === 'RECRUITING_DEMO' && countJobStats(EJobStatus.ALL)<1) || (companyUser?.role === 'RECRUITING_DEMO' && companyUser?.demoAllowedProfiles && countJobStats(EJobStatus.ALL) < companyUser?.demoAllowedProfiles))?
                <a href='/recruiting/dashboard/createjob'>
                  <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-uw-teal hover:bg-gray-600 focus:outline-none"
                  >
                    {t('button')}
                  </button>

                </a>
              :   <button
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-300 focus:outline-none cursor-not-allowed"
                  >
                    {t('button')}
                  </button> }
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  
      
      <div className="bg-white shadow overflow-hidden sm:rounded-md mt-8">
      <ul className="divide-y divide-gray-200"> 
        {filteredProfiles.map((profile) => (
          <li key={profile.id}>
            {/* <a href={'/company/requirementprofiles/editprofile/'+profile.id} className="block hover:bg-gray-50"> */}
            <a href={'/recruiting/jobprofile/'+profile.id} className="block hover:bg-gray-50"> 

              <div className="flex items-center px-4 py-4 sm:px-6"> 
                <div className="min-w-0 flex-1 flex items-center"> 
 
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-6 md:gap-8"> 
                    <div className='col-span-2 self-center'>
                      <p className="text-lg font-medium text-uw-teal items-center">{profile.name}</p>
                      <div className="flex items-center text-sm text-gray-500">
                        <CalendarIcon className="flex-shrink-0 mt-0.5 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                        <p className="mt-1 flex items-center text-xs text-gray-500">
                          { (profile.status === EJobStatus.PUBLISHED)? String(t('companyRecruitingDashboard:requirementProfileItems:publishingDate')+' '+profile.published?.toDate().toLocaleDateString('de-DE')) : String('Updated: '+profile.updated.toDate().toLocaleDateString('de-DE')) }
                        </p>
                      </div>
                    </div>

                    <div className="hidden md:block col-span-3 self-center">
                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm bg-gray-100 text-uw-teal">
                          {t('companyRecruitingDashboard:requirementProfileItems:status:'+profile.status)}
                        </span>
                    </div> 
                    <div className="hidden md:block col-span-1 self-center flex">
                        {/*<span className="mt-1 flex items-center text-sm text-gray-500">
                          113 {t('companyRecruitingDashboard:requirementProfileItems:candidates')}
                        <div className="flex-shrink-0 flex items-center justify-center ml-3">
                          <span className="h-2 w-2 rounded-full bg-green-500" aria-hidden="true" />
                        </div>
                        </span>*/}
                    </div> 
                    
                  </div>
                </div>
                <div>
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </a>
          </li>
        ))}
        </ul>
    </div>
    </>
  )
}


export default RecruitingRequirementProfileItems
