import React from 'react'

interface IProps {
    color: "white" | "uw-teal" | "uw-grey"
}

const H4: React.FC<IProps> = ({children, color}) => {
    return (
        <>
            <div className="lg:hidden xl:hidden">
                <h4 className={`font-headlines font-medium leading-h4Mobile text-h4Mobile text-${color}`}>{children}</h4>
            </div>
            <div className="hidden lg:block xl:block">
                <h4 className={`font-headlines font-medium leading-h3h4Desktop text-h3h4Desktop text-${color}`}>{children}</h4>
            </div>
            </>
        )
}

export default H4;