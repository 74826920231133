import { useState } from 'react';

export const useFormTextareaFieldState = (defaultValue: string): [string, (e: React.ChangeEvent<HTMLTextAreaElement>) => void] => {
    const [value, setValue] = useState(defaultValue);

    const setter = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.currentTarget.value);
    }

    return [value, setter];
}