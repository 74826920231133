import React from 'react'
import H5 from "../../textStyling/H5";
import H4 from "../../textStyling/H4";
import Text from "../../textStyling/Text";
import EmployeeInnerCompetencesCard from "./EmployeeInnerCompetencesCard";
import EmployeeCardPoint from "./EmployeeCardPoint";
import EmployeeInnerStrengthsAndRisksCard from "./EmployeeInnerCardStrengthsAndRisksCard";
import H3 from "../../textStyling/H3";
import {useTranslation} from "react-i18next";
import Button from "./../../layout/Button";

interface Props {
    cardTitle: string
    innerCompetencesData: Array<IState>
    innerStrengthsAndRisksData: Array<IState>
    points: number
    titleDescription: string
}

export interface IState {
    points?: number
    title: string
    explanationText: string | Array<string>
    traitLevelHigh?: string 
    traitLevelLow?:string   
}


const EmployeeOuterCard: React.FC<Props> = ({innerCompetencesData, innerStrengthsAndRisksData, cardTitle, points, titleDescription}) => {

    const uuidv1 = require('uuid/v1')
    const { t } = useTranslation('resultsEmployeeOverallScreen')
    const [expanded, setExpanded] = React.useState<boolean>(false)

    const generateCardPoints = () => {
        let cardPointsArray: Array<object> = []
        while (cardPointsArray.length < points) {
            cardPointsArray.push(
                <div key={uuidv1()} className="mr-1 lg:mr-2 xl:mr-2">
                    <EmployeeCardPoint opacity={false}/>
                </div>
            )
        }
        while (cardPointsArray.length < 5) {
            cardPointsArray.push(
                <div key={uuidv1()} className="mr-1 lg:mr-2 xl:mr-2">
                    <EmployeeCardPoint opacity/>
                </div>
            )
        }

        return cardPointsArray
    }

    const handleClick = () => {
        setExpanded(!expanded)
    }


    return (
        <>
            <div className="bg-uw-lightTeal rounded-lg shadow-cardShadow px-12 py-8 relative z-10">
                <div className="flex flex-row my-2">
                    {generateCardPoints()}
                </div>
                <div className="mt-4 flex flex-row justify-between mb-8">
                    <div className="flex-col" >
                        <H3 color="uw-teal">{cardTitle}</H3>
                            <div className="my-4"> 
                                <Button onClick={handleClick} title={expanded ? t('buttonOpen') : t('buttonClosed')} testSize backgroundWhite borderColor={false} type="button"/> 
                            </div>
                    </div>
                    <div className="w-58%">
                        <Text color="text-uw-teal" one>{titleDescription}</Text>
                    </div>
                </div>
                {expanded && <div>
                    <div className="ml-4 mb-12">
                        <H4 color="uw-teal">
                        {t('strengthsAndRisks')}
                        </H4>
                    </div>
                    <div>
                        <EmployeeInnerStrengthsAndRisksCard data={innerStrengthsAndRisksData}/>
                    </div>
                    <div className="ml-8 my-12">
                        <H5 color="uw-teal">
                         {t('competences')}
                        </H5>
                    </div>
                    <div className="mb-12">
                         <EmployeeInnerCompetencesCard data={innerCompetencesData}/>
                    </div>
               </div>}
               
            </div>
        </>
    )
}

export default EmployeeOuterCard;