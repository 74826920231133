
import { firestore } from "../firebase/Firebase"
import { collection, query, where, getDocs } from "firebase/firestore";
import { useUserContext } from "../firebase/FirebaseContext"
import { EStatus } from "./useGetJobStats";

export interface IUserData {
  [index: string]: number
}

export interface IMatchingCandidate {
  id: string,
  score?: number,
  firstName: string,
  lastName: string,
  email?: string,
  data: IUserData,
  jobTitle?: string,
  department?: string | null,
  jobs?: any,
  status?: EStatus,
  statusArr: any[]
}


export const UseGetMatchingCandidate = (): any => {

  const {companyUser} = useUserContext()

  const getMatchingCandidate = async (uid: string, testGroups: any, jobId: string) => {
    let employee: IMatchingCandidate

    try {

      const q = query(collection(firestore, "employees"), where("testGroups", "==", testGroups), where("test.testDone", "==", true), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {

        if(doc.data().test.results && doc.data().test.results.errors) {
          return
        }

        if(testGroups[0].id !== companyUser?.testGroups[0].id) {
          return false
        }

        let status: EStatus = EStatus.NEW
        let statusArr: any = []

        if(doc.data().jobs && doc.data().jobs[jobId] && doc.data().jobs[jobId].status && doc.data().jobs[jobId].status[0]) {
          status = doc.data().jobs[jobId].status[doc.data().jobs[jobId].status.length-1].status
          statusArr = doc.data().jobs[jobId].status
        }

        

        employee = {
          id: doc.id,
          firstName: doc.data().firstName,
          lastName: doc.data().lastName,
          email: doc.data().email,
          data: doc.data().test.results,
          jobTitle: doc.data().jobTitle,
          department: doc.data().department,
          jobs: doc.data().jobs,
          status: status,
          statusArr: statusArr
        }

        

      });

      return employee

    } catch(err) {
      console.log('err:', err)
    }


  }

  return getMatchingCandidate
}
