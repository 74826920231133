import { firestore } from "../firebase/Firebase"
import { doc, updateDoc, Timestamp } from 'firebase/firestore'
import { EJobStatus } from "./useSaveJob";

export interface IUserData {
  [index: string]: number
}

interface IJobStatus {
  date: number | Timestamp,
  status: EJobStatus
}


export const useChangeJobStatus = () => {

  const changeJobStatus =  async (profileId : string, jobStatusArr: IJobStatus[]) => {

    const docRef = doc(firestore, "jobs", profileId);
    await updateDoc(docRef, { status: jobStatusArr });

  }

  return changeJobStatus
}
