import React from 'react'
import classnames from 'classnames';

interface Props {
    value: boolean;
    setValue: (x: boolean) => void;
    negative?: boolean;
    className?: string;
}

const TestCheckbox: React.FC<Props> = ({value, setValue, negative, className}) => {
    let bg = '';
    if (negative && value) bg = 'bg-uw-red';
    if (!negative && value) bg = 'bg-uw-green';
    return (
        <div onClick={() => setValue(!value)}
             className={classnames("cursor-pointer border-2 border-white rounded-lg w-8 h-8 lg:w-12 xl:w-12 lg:h-12 xl:h-12 lg:border-3.5 xl:border-3.5",
                 bg, className)}
        />
    )
}

export default TestCheckbox