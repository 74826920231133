
//React
import React from 'react'
//Components
import CompanyDashboardView from "../components/companyDashbaord/CompanyDashbaordView"
import { INavigation } from "../components/layout/Menu/CustomMenu"


const CompanyDashboard: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  return (
    <CompanyDashboardView mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
  )
}

export default CompanyDashboard