import { firestore } from "../firebase/Firebase"
import { doc, updateDoc, Timestamp } from 'firebase/firestore'
import { EProfileStatus } from "./useGetProfiles";

export interface IUserData {
  [index: string]: number
}

interface IProfileStatus {
  date: number | Timestamp,
  status: EProfileStatus
}


export const useChangeProfileStatus = () => {

  const changeJobStatus =  async (profileId : string, jobStatusArr: IProfileStatus[]) => {

    const docRef = doc(firestore, "profiles", profileId);
    await updateDoc(docRef, { status: jobStatusArr });

  }

  return changeJobStatus
}
