import React from 'react'
import CustomMenu from '../layout/Menu/CustomMenu'
import Footer from "../layout/FooterNew"
import { INavigation, setMainMenuIdActive, MAIN_MENU_IDS } from "../layout/Menu/CustomMenu"
import RecruitingRequirementJobItems from "../layout/RecruitingRequirementJobItems"
import { useTranslation } from "react-i18next"
import RecruitingStatItems from "../layout/RecruitingStatItems"
import { useUserContext } from "../../firebase/FirebaseContext"

import CompanyRegistrationConfirmationScreen from '../../screens/CompanyRegistrationConfirmationScreen';


//import {useTranslation} from "react-i18next"



const RecruitingDashbaordView: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

    //const { t } = useTranslation(['companyFaq', 'basics'])

    setMainMenuIdActive(mainNavItems, MAIN_MENU_IDS.DASHBOARD_RECRUITING)

    const { t } = useTranslation(['companyRecruitingDashboard']);

    const {companyUser} = useUserContext()
    //console.log(companyUser)
    if(companyUser && companyUser!.active !== true) {
      return <CompanyRegistrationConfirmationScreen />
    }

    return (
            <>
                <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} /> 
                <div className="py-10">
                    <header className="mt-16">
                        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-4">
                            <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal">{t('headline')}</h1>
                        </div>
                    </header>
                    <main>
                      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="px-4 py-4 mb-6 sm:px-0">
                          <RecruitingStatItems />
                        </div>
                        <h2 className="text-2xl leading-6 font-medium text-uw-teal lg:mb-6">Jobs</h2>
                        
                        <RecruitingRequirementJobItems />
                      </div>
                    </main>
                </div>
                <Footer />
            </>
            )
}

export default RecruitingDashbaordView;