//React
import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import useCollapse from 'react-collapsed';
import EmployeeCardPoint from "../employeeResults/desktopResults/EmployeeCardPoint";


//Hooks


interface Props {
  competenceData: IState,
  userData: any,
  points: number
}
export interface IState {
  //points?: number
  description?: string,
  name?: string,
  high?: string,
  low?: string,
  level?: any,
  element?: string,
  points?: number,
  category?: any,
  general?: any
}


const EmployeeDevelopment: React.FC<Props> = ({competenceData, userData, points}) => {
  
  const { t } = useTranslation(['basic','employeeResults']); //@Martin hier müsstest du schauen, woher die Texte kommen
  const uuidv1 = require('uuid/v1')

  let localData: any = competenceData

  const [ isExpanded, setExpanded ] = useState(false);
  const [catId, setCatId] = useState(2)
  const [catTextId, setCatTextId] = useState('leading')
  const [active, setActive] = useState([true, false, false, false, false])
  const [strength, setStrength] = useState('')
  const [risk, setRisk] = useState('')
  const [localUserData, setLocalUserData] = useState(userData)
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
  function handleOnClick() {
      setExpanded(!isExpanded);
  }

 
  //console.log('+++++', localData[catTextId+'_risks'])

  useEffect(() => {
    if(Object.keys(userData).length>0) {
      setLocalUserData(userData)
      setRisk(localData[catTextId+'_risks'][localUserData['g8t_category'+catId]])
      setStrength(localData[catTextId+'_strengths'][localUserData['g8t_category'+catId]])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userData])


  const handleOnClickTabs = (index: any, catId: any, catTextId: any) => {
    setCatTextId(catTextId)
    setCatId(catId)
    let tempActive = [false, false, false, false, false]
    tempActive[index] = true
    setActive(tempActive)
    setRisk(localData[catTextId+'_risks'][userData['g8t_category'+catId]])
    setStrength(localData[catTextId+'_strengths'][userData['g8t_category'+catId]])
  }
 
  let boxCss = "bg-white shadow overflow-hidden sm:rounded-md mb-3 mt-6"

  //if(competenceData.level[profileData['g8t_'+competenceData.element]])
    //boxCss += " border-l-4 border-uw-teal"
  

    const tabs = [
      { catId: 2, catTextId: 'leading', id:0, name: t('employeeResults:card.strengthsAndRisksTitles')[0], current: active[0] },
      { catId: 3, catTextId: 'team', id:1, name: t('employeeResults:card.strengthsAndRisksTitles')[1], current: active[1] },
      { catId: 2, catTextId: 'learning', id:2, name: t('employeeResults:card.strengthsAndRisksTitles')[2], current: active[2] },
      { catId: 2, catTextId: 'motivation', id:3, name: t('employeeResults:card.strengthsAndRisksTitles')[3], current: active[3] },
      { catId: 2, catTextId: 'development', id:4, name: t('employeeResults:card.strengthsAndRisksTitles')[4], current: active[4] }
    ]
    
    function classNames(...classes: string[]) {
      return classes.filter(Boolean).join(' ')
    }

    const generateCardPoints = () => {
      let cardPointsArray: Array<object> = []
      while (cardPointsArray.length < points) {
          cardPointsArray.push(
              <div key={uuidv1()} className="mr-1 lg:mr-2 xl:mr-2">
                  <EmployeeCardPoint opacity={false}/>
              </div>
          )
      }
      while (cardPointsArray.length < 5) {
          cardPointsArray.push(
              <div key={uuidv1()} className="mr-1 lg:mr-2 xl:mr-2">
                  <EmployeeCardPoint opacity/>
              </div>
          )
      }

      return cardPointsArray
  }

  let multipleContent = <><div className="md:col-span-1">
                  <p className="px-6 text-sm font-medium text-uw-teal">{ t('basics:other.strength') }</p>
                </div>
                <div className="md:col-span-5">
                  <p className="px-6 text-sm font-small text-gray-600 ">{strength}</p>
                </div>
                <div className="md:col-span-1">
                  <p className="px-6 text-sm font-medium text-uw-teal">{ t('basics:other.risk') }</p>
                </div>
                <div className="md:col-span-5">
                  <p className="px-6 text-sm font-small text-gray-600 ">{risk}</p>
                </div></>

  let oneContent = <><><div className="md:col-span-1">
  <p className="px-6 text-sm font-medium text-uw-teal"></p>
</div>
<div className="md:col-span-5">
  <p className="px-6 text-sm font-small text-gray-600 ">{strength}</p>
</div>
<div className="md:col-span-1">
  <p className="px-6 text-sm font-medium text-uw-teal"></p>
</div>
<div className="md:col-span-5">
  <p className="px-6 text-sm font-small text-gray-600 "></p>
</div></>
            </>

  let content = multipleContent

  if(risk === strength)
    content = oneContent

  return (
      <div className={boxCss}>
        <div className="flex">
          <div className="flex py-4 px-4 sm:px-6 md:grid md:grid-cols-10 bg-gray-100">
            <div className="md:col-span-3 self-center">
              <div className="px-4 pt-1 text-left flex">
                <p className="text-lg font-medium text-uw-teal">{competenceData.name}</p>
                <div {...getToggleProps({onClick: handleOnClick})} className="flex justify-start pt-1.5 pl-2">
                  <QuestionMarkCircleIcon className="h-4 w-4 text-gray-300 mb-2" aria-hidden="true" />
                </div>
              </div>  
              <div {...getCollapseProps()}>
                <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3">
                  <div className="flex text-sm text-gray-600 sm:mt-0 sm:col-span-4 pl-4">
                    <span className="flex-grow">
                    {competenceData.general}
                    </span>
                  </div>
                </div>
              </div>  
            </div>
            <div className="md:col-span-5 ">
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                  id="tabs"
                  name="tabs"
                  className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                >
                  {tabs.map((tab) => (
                    <option key={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <nav className="flex space-x-3 pl-6" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <div
                      key={tab.name}
                      //href={tab.href}
                      onClick={() => handleOnClickTabs(tab.id, tab.catId, tab.catTextId)}
                      className={classNames(
                        tab.current ? 'matchingBackgroundNearMatch text-uw-teal' : 'text-gray-500 hover:text-gray-700 cursor-pointer ',
                        'px-3 py-2 font-medium text-sm rounded-md'
                      )}
                      aria-current={tab.current ? 'page' : undefined}
                    >
                      {tab.name}
                    </div>
                  ))}
                </nav>
              </div>
            </div>
            <div className="md:col-span-1 items-center mt-2 content-center">
              <div className="flex flex-row my-2 pl-4">
                {generateCardPoints()}
              </div>
            </div>    
          </div>
        </div>

        <div className="block flex-1 px-4 pb-4 pt-4 md:grid md:grid-cols-12" >
          { content }
        </div>
      </div>
  )}

export default EmployeeDevelopment

