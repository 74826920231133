import React, {useState} from "react";
import EmployeeInnerCardLeftTitleCard from "./EmployeeInnerCardLeftTitleCard";
import EmployeeInnerCardStrengthsAndRisks from "./EmployeeInnerCardStrengthsAndRisks";
import {useTranslation} from "react-i18next";

interface IState {
    title: string
    explanationText: string | Array<string>
}

interface Props {
    data: Array<IState>
}

const EmployeeInnerStrengthsAndRisksCard: React.FC<Props> = ({data}) => {

    const [selectedIndex, setSelectedIndex] = useState(0)
    const {t} = useTranslation('employeeResults')

    const onPressLeftCard = (key: number) => {
        setSelectedIndex(key)
    }

    return (
        <>
            <div className="flex flex-row justify-between">
                <div className="flex flex-col w-1/4 mr-4">
                    {data.map((dataSet, index) => (
                        <div className="mb-4" key={index}>
                            <EmployeeInnerCardLeftTitleCard
                                clicked={selectedIndex === index}
                                lastElement={data.length - 1 === index}
                                onClick={() => {
                                    onPressLeftCard(index)
                                }}
                                selectedIndex={selectedIndex}
                                strengthsAndRisks
                                title={dataSet.title}
                            />
                        </div>
                    ))}
                </div>
                <div className="flex w-3/4">
                    <EmployeeInnerCardStrengthsAndRisks
                        roundedFull={selectedIndex !== 0}
                        small={selectedIndex >= 3}
                        titles={t('card.strengthsAndRisksSubPartTitle')[selectedIndex] as unknown as string[]}
                        titleExplanations={data[selectedIndex].explanationText as unknown as string[]}
                    />
                </div>
            </div>
        </>
    )
}

export default EmployeeInnerStrengthsAndRisksCard;