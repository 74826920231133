import React from 'react'

export interface Props {
  greenBackgroundLessUpperPart?: boolean
}

const GreenBackground: React.FC<Props> = ({ children, greenBackgroundLessUpperPart }) => {
  return (!greenBackgroundLessUpperPart ?
    <>
      <div className="borderGreenBg bottom-minus1" />
      <div className="w-screen">
        <div className="relative">
          <div className="z-0 bg-uw-teal pb-16 mr-16">
            <div className="z-0 bg-uw-teal absolute rounded-full right-0 px-16 py-16" />
          </div>
        </div>
        <div className="relative bg-uw-teal -mt-1 -mb-1 w-screen flex justify-center">
          <div className="px-6 relative z-10 container">{children}</div>
        </div>
        <div className="relative z-0">
          <div className="bg-uw-teal pb-16 rounded-br-full relative z-10 bottom-minus1" />
          <div className="borderGreenBg rotate-90 left-minus1" />
        </div>
      </div>
    </> : <>
      <div className="w-screen">
        <div className="relative">
          <div className="z-0 bg-uw-teal pb-16 mr-16">
            <div className="z-0 bg-uw-teal absolute right-0 px-16 py-16" />
          </div>
        </div>
        <div className="relative bg-uw-teal -mb-1 w-screen flex justify-center">
          <div className="px-6 relative z-10 container">{children}</div>
        </div>
        <div className="relative z-0">
          <div className="bg-uw-teal pb-16 rounded-br-full relative z-10 bottom-minus1" />
          <div className="borderGreenBg rotate-90 left-minus1" />
        </div>
      </div>
    </>
  )
}

export default GreenBackground