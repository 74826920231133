
import React from 'react'
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import { useUserContext } from "../../../firebase/FirebaseContext"
import { MailIcon } from '@heroicons/react/solid' 

export enum TestStatus {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}
export interface IEmployee {
  id: string,
  firstName: string,
  lastName: string,
  language: string,
  role?: string,
  test?: any,
  done?: boolean,
  testStatus?: TestStatus,
  email?: string,
  loadingStatus?: boolean,
  found?: boolean,
  department?: string,
  jobTitle?: string
}

interface Props {
  employee: IEmployee,
  employeeIdx: any,
}

const Employee: React.FC<Props> = ({ employee, employeeIdx }) => {

  const {companyUser} = useUserContext()
  const { t } = useTranslation(['employeeList', 'departments'])

  const statusOpen = <span className="inline-flex items-center py-0.5 px-3 leading-5 rounded-full bg-red-100 text-red-800">{t('rowThree.testStatusToDo')}</span>
  const statusInProgress = <span className="inline-flex items-center py-0.5 px-3 leading-5 rounded-full bg-red-100 text-red-800">{t('rowThree.testStatusInProgress')}</span>
  const statusDone = <span className="inline-flex items-center py-0.5 px-3 leading-5 rounded-full bg-green-100 text-green-800">{t('rowThree.testStatusDone')}</span>

  let testStatus = statusOpen

  if (employee.testStatus === TestStatus.IN_PROGRESS) {
    testStatus = statusInProgress
  }

  if (employee.testStatus === TestStatus.DONE) {
    testStatus = statusDone
  }

  return (
    <tr key={employee.id} className={employeeIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>

      <td className="px-6 pl-8 py-4 whitespace-nowrap font-medium text-gray-800"> 
        <p className="font-medium text-md text-gray-600">{employee.lastName}, {employee.firstName}</p> 
        <p className="flex mt-0.5 items-center text-sm text-gray-500"> 
          <MailIcon className="flex-shrink-0 mr-1 h-3 w-3 text-gray-400" aria-hidden="true" /> 
          <span className="truncate text-xs text-gray-500">{employee.email}</span> 
        </p> 
      </td> 
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"> 
        <div> 
          <p className="text-sm mt-1 text-gray-600">
          {employee?.jobTitle}
          </p> 
          <p className="flex mt-0.5 items-center text-xs text-gray-500"> 
          {t('departments:'+String(employee?.department))}
          </p> 
        </div>   
      </td> 
      <td className="px-2 ml-4 mt-4 inline-flex text-xs flex items-center leading-5 font-semibold rounded-full"> 
        <div className="mt-2"> 
        {testStatus}
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        {employee.testStatus === TestStatus.DONE && companyUser && companyUser!.empoloyeeAccess === true ?
          <Link to={`/company/employee/comparison/${employee.id}`} className=" px-6 py-4 text-uw-teal hover:text-gray-600">
            {t('linkToResult')}
          </Link>
          :
          <span className=" px-6 py-4 text-gray-300">
            {t('linkToResult')}
          </span>
        }
      </td>
    </tr>
  )

}



export default Employee
