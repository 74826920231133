import React from 'react'
import EmployeeCardPoint from "./EmployeeCardPoint";
import IconClose from "../../design/IconClose";
import IconOpen from "../../design/IconOpen";
import classnames from "classnames";
import Text from "../../textStyling/Text";
import {useTranslation} from "react-i18next";

interface IProps {
    cardPoints: number
    cardOpened: boolean
    setCardOpened: () => void
    title: string
    text: string
}

const EmployeeCardHead: React.FC<IProps> = ({cardPoints, cardOpened, setCardOpened, title, text}) => {

    const {t} = useTranslation('employeeCard')
    const uuidv1 = require('uuid/v1')

    const generateCardPoints = () => {
        let cardPointsArray: Array<object> = []
        while (cardPointsArray.length < cardPoints) {
            cardPointsArray.push(
                <div key={uuidv1()} className="mr-1">
                    <EmployeeCardPoint opacity={false}/>
                </div>
            )
        }
        while (cardPointsArray.length < 5) {
            cardPointsArray.push(
                <div key={uuidv1()} className="mr-1">
                    <EmployeeCardPoint opacity/>
                </div>
            )
        }

        return cardPointsArray
    }

    const showText = "mb-2"

    return (
        <>
            <div className="items-center" onClick={setCardOpened}>
                <div className="flex flex-row my-2 ml-4">
                    {generateCardPoints()}
                </div>
                <div className="flex flex-row">
                    <div className={classnames("flex flex-1 justify-start ml-4", {
                        "mb-2": !cardOpened,
                        "mb-6": cardOpened
                    })}>
                        <Text fat color="text-uw-teal">
                            {title}
                        </Text>
                    </div>
                    <div className="flex flex-1 justify-end mr-4">
                        <div>
                            {cardOpened && <IconClose/>}
                            {!cardOpened && <IconOpen/>}
                        </div>
                    </div>
                </div>
            </div>
            <div className={classnames(showText, {
                    "hidden pb-2": !cardOpened
                },
                {
                    "pb-2": cardOpened
                }
            )}
            >
                <div className="mx-6 mb-4">
                    <Text color="text-uw-grey">
                        {text}
                    </Text>
                </div>
                <div className="mb-2 mx-4">
                    <Text fat color={"text-uw-teal"}>
                        {t('head.headline')}
                    </Text>
                </div>
            </div>
        </>
    )
}

export default EmployeeCardHead
