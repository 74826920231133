import { httpsCallable } from "firebase/functions"
import { useState, useEffect } from "react"
import { functions } from "../firebase/Firebase"

export const useCheckTestGroupLimit = (id: string) => {
    const [valid, setValid] = useState<boolean | null>(null)
    useEffect(() => {
        (async () => {
            let valid: any = null;
            const request = httpsCallable(functions,'checkTestgroupLimit');
            const result = await request({ id });
            valid = result.data;
            setValid(valid);
        })();
    }, [id])

    return valid;
}