// Firebase
import { useUserContext } from "../firebase/FirebaseContext";
import { firestore } from "../firebase/Firebase"

import { doc, addDoc, collection, Timestamp } from 'firebase/firestore'

import { EProfileStatus } from "./useGetProfiles";

export interface IUserData {
  [index: string]: number
}

export interface IProfile {
  template?: string,
  company?: any,
  name: string,
  description: string,
  data: IUserData,
  status: [
    {
      date: number | Timestamp,
      status: EProfileStatus
    }
  ],
  updated: number | Timestamp
}


export const useSaveProfile = () => {

  const {companyUser} = useUserContext()

  const saveProfile =  async (profile : IProfile) => {

    // get company reference
    const companyDoc = doc(firestore, 'companies', companyUser!.companyId)

    // Add company reference to profile
    profile.company = companyDoc;

    // Add Timestamp
    profile.updated = new Timestamp(Math.floor(Number(profile.updated) / 1000), 0)

    //const profileDoc = doc(firestore, 'profiles');
    await addDoc(collection(firestore, 'profiles'), profile);

  }

  return saveProfile
}
