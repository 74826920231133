import React from 'react'
import SkilldetektorLogo from '../../css/icons/Skilldetektor_Logo.svg'


const HeaderLogo: React.FC = () => {
    return (
        <img alt="Skilldetektor Logo" src={SkilldetektorLogo}/>
    )
}

export default HeaderLogo