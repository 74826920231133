import React from "react";  
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { useUserContext } from "../../firebase/FirebaseContext";


export interface IResultTabs {
  active: boolean[],
  employeeView?: boolean
}

const IndividualResultsBar: React.FC<IResultTabs> = ({active, employeeView = false}) => {

  interface ParamTypes {
    uid: string,
    profileid: string
  }

  const { uid } = useParams<ParamTypes>()

  const { t } = useTranslation(['employeeResults'])
  
  const { currentUser } = useUserContext()


  const tabs = [
    { id:0, name: t('employeeResults:individualResultsBar.results'), href: '/company/employee/comparison/'+uid, current: active[0] },
    { id:1, name: t('employeeResults:individualResultsBar.development'), href: '/company/employee/development/'+uid, current: active[1] }
  ]

  if(employeeView === true) {
    if(currentUser?.role === 'EMPLOYEE') {
      tabs[0].href='/employee/results'
      tabs[0].name = t('employeeResults:individualResultsBar.resultsEmployee')
      tabs[1].href='/employee/results/development/'
    } else if (currentUser?.role === 'CANDIDATE') {
      return (<></>)
    }
  }
  
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }
 
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:border-uw-teal border-gray-300 rounded-md"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="relative z-0 border border-gray-100 rounded-lg shadow-md flex divide-x divide-gray-200" aria-label="Tabs">
            {tabs.map((tab, tabIdx) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current ? 'text-gray-600' : 'text-gray-400 hover:text-gray-700',
                  tabIdx === 0 ? 'rounded-l-lg' : '',
                  tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                  'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.current ? 'bg-uw-teal' : 'bg-transparent',
                    'absolute inset-x-0 bottom-0 h-0.5'
                  )}
                />
              </a>
            ))}
          </nav>
        </div>
      </div>
    )
  };
  
  export default IndividualResultsBar;