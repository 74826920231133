//React
import React from 'react'
import { Link } from 'react-router-dom';
//Hooks 
//Components, Icons and Props
import CustomMenu from '../layout/Menu/CustomMenu'
import Footer from "../layout/FooterNew"
//import BreadcrumbNavigation from '../layout/Menu/BreadcrumbNavigation'
import { INavigation, setMainMenuIdActive, MAIN_MENU_IDS } from "../layout/Menu/CustomMenu"
import RequirementProfileItems from "../layout/RequirementProfileItems"
//useTranslation
import { useTranslation } from "react-i18next"
//import CompanySubHeader from '../layout/companySubHeader'

/*
enum EProfileStatus {
  ALL = "ALL",
  IN_WORK = "IN_WORK",
  HIRED = "HIRED",
  NOTHING = "NOTHING"
}


interface IProfileStats {
  ALL: number,
  IN_WORK: number,
  HIRED: number,
  NOTHING: number
}
interface ITab {
  id: EProfileStatus,
  name: string,
  href: string,
  current: boolean,
  onclick: any
}
*/

const CompanyRequirementProfilesView: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  setMainMenuIdActive(mainNavItems, MAIN_MENU_IDS.DASHBOARD_COMPANY)

  const { t } = useTranslation(['companyRequirementProfiles']);

  /*
  const breadCrumbHome = {
  
    name: 'home',
    href: '/company/matching',
    current: false
  }

  const breadCrumbPaths: INavigationMainItem[] = [
    {
      name: t('dashboard.headlineBreadcrumb'),
      href: '/company/matching',
      current: true
    }
  ]
  */

  
  return (
    <>
      <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
      <div className="py-10">
        <header className="mt-16">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 flex flex-row">
            <Link to="/company/dashboard">
              <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-gray-300 hover:text-uw-teal hover:underline">Dashboard</h1>
            </Link>
            <span className="lg:text-3xl text-2xl font-medium leading-tight text-uw-teal mr-2 ml-2">|</span>
            <Link to="/company/matching">
              <p className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal">{t('dashboard.headline')}</p>
            </Link>
          </div>
        </header>
        <main>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-12">
            <RequirementProfileItems />
          </div>
        </main>
      </div>
      <Footer />
    </>
  )
}

export default CompanyRequirementProfilesView