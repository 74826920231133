// Firebase
import { firestore } from "../firebase/Firebase"
import { collection, query, where, getDocs, doc, orderBy, Timestamp } from "firebase/firestore";

export interface IUserData {
  [index: string]: number
}

export enum EProfileStatus {
  ALL = "ALL",
  NEW = "NEW",
  IN_WORK = "IN_WORK",
  HIRED = "HIRED",
  NOTHING = "NOTHING"
}

export enum EStatus {
  ALL = "ALL",
  NOTHING = "NOTHING",
  PRE_SELECTED = "PRE_SELECTED",
  INTERVIEW = "INTERVIEW",
  SELECTED = "SELECTED",
  REJECTED = "REJECTED"
}
export interface IProfile {
  id: string,
  company?: any,
  name: string,
  description: string,
  data: IUserData,
  status?: string,
  statusArr: any[],
  updated: Timestamp
}


export const UseGetProfiles = (): any => {

  //const {companyUser} = useUserContext()

  const getProfiles = async (companyId: string) => {

    const companyRef = doc(firestore, 'companies', companyId)
    //const q = query(collection(firestore, "profiles"), where("company", "==", "/companies/"+companyUser!.companyId));
    const q = query(collection(firestore, "profiles"), where("company", "==", companyRef), orderBy("updated", "desc"));
    const querySnapshot = await getDocs(q);

    let allProfiles: IProfile[] = [];

    querySnapshot.forEach((doc) => {

      if(typeof doc.data().user === "undefined") {

        let status = EProfileStatus.NEW
        let statusArr: any = []

        if(doc.data().status && Array.isArray(doc.data().status)) {
          status = doc.data().status[doc.data().status.length-1].status
          statusArr = doc.data().status
        }

        let profile: IProfile = {
          id: doc.id,
          name: doc.data().name,
          description: doc.data().description,
          data: doc.data().data,
          status: status,
          statusArr: statusArr,
          updated: doc.data().updated
        }
  
        allProfiles.push(profile)
      }

    });

    return allProfiles;

  }

  return getProfiles
}
