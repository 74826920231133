// Firebase
import { useUserContext } from "../firebase/FirebaseContext";
import { firestore } from "../firebase/Firebase"

import { doc, updateDoc } from 'firebase/firestore'

export const useToggleCompanyPrivacy = () => {

  const {companyUser} = useUserContext()
  const toggleCompanyPrivacy =  async (empoloyeeAccess: boolean) => {
    const companyDoc = doc(firestore, 'companies', companyUser!.companyId)
    await updateDoc(companyDoc, { empoloyeeAccess: empoloyeeAccess })
  }

  return toggleCompanyPrivacy
}
