//React
import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { BriefcaseIcon, ShoppingCartIcon, UserIcon } from '@heroicons/react/outline'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'
//Hooks
import { useCompanyTestStats, ICompanyTestStats } from "../../hooks/useCompanyTestStats"
import { useGetJobOverviewStats } from "../../hooks/useGetJobOverviewStats"



const RecruitingStats: React.FC = () => {

  const jobOverviewStats = useGetJobOverviewStats()

  const testStats: ICompanyTestStats = useCompanyTestStats();
  const { t } = useTranslation(['companyRecruitingDashboard'])
  const [isCopied, setIsCopied] = useState<boolean>(false)

  

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCopied(false)
    }, 5000)
    return () => {
      clearTimeout(timer)
    }
  }, [isCopied])

  //console.log('jobOverviewStats', jobOverviewStats)


  const copyToClipboard = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    let link = '';
    if (testStats.invitationLink) {
      link = testStats.invitationLink;
    }
    navigator.clipboard.writeText(link);
    setIsCopied(true)
  }

  let copiedToClipboard: JSX.Element | undefined
  if (isCopied) {
    copiedToClipboard = <div className="flex justify-between">
      <p>{t('companyRecruitingDashboard:recruitingStats:link.linkText')}</p>
      <CheckCircleIcon className="h-5 w-5 text-green-400 animate-fade-out" />
    </div>
  } else {
    copiedToClipboard = <div>{t('companyRecruitingDashboard:recruitingStats:link.linkText')}</div>
  }

  let statItems = [ 
    { id: 1, name: t('companyRecruitingDashboard:recruitingStats:general.text'), stat: jobOverviewStats?.activeCandidates, icon: UserIcon, linkText: t('companyRecruitingDashboard:recruitingStats:general.linkText'), link: false, onClick: () => { } },
    { id: 2, name: t('companyRecruitingDashboard:recruitingStats:link.text'), stat: jobOverviewStats?.publishedJobs, icon: BriefcaseIcon, linkText: copiedToClipboard, link: false, onClick: copyToClipboard },
    { id: 3, name: t('companyRecruitingDashboard:recruitingStats:unlock.text'), stat: testStats.leftSize, icon: ShoppingCartIcon, linkText: t('companyRecruitingDashboard:recruitingStats:unlock.linkText'), link: '/company/contact', onClick: () => { } },
  ]

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {statItems.map((item) => (
          <div
            key={item.id}
            className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
          >
            <dt>
              <div className="absolute bg-uw-teal rounded-md p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">{item.name}</p>
            </dt>
            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
              <p className="text-2xl font-semibold text-gray-800">{item.stat}</p>
              <div className="absolute bottom-0 inset-x-0 bg-uw-lightTeal px-4 py-4 sm:px-6">
                <div className="text-sm cursor-default">
                  {
                    (item.link !== false && typeof item.link === 'string') ? <Link to={item.link} onClick={item.onClick} className="font-medium text-uw-teal hover:text-gray-600">{item.linkText}</Link> : <p>&nbsp;</p> 
                  }
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

export default RecruitingStats
