//React
import React from 'react'
import { useTranslation } from "react-i18next";
import { ChevronRightIcon, MailIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { IMatchingEmployee } from '../../hooks/useGetMatchingEmployees'
import { useUserContext } from "../../firebase/FirebaseContext"
//import { Link } from 'react-router-dom';
//Hooks

interface IMatchingEmployees{
  allMatches: IMatchingEmployee[] | null,
  profileid: string | null,
  showStatus: boolean
}



const generateLink = (profileId: string, matchUid: string, companyUser: any) => {
  if(companyUser && companyUser!.empoloyeeAccess === true)
    return "/company/matching/comparison/"+profileId+"/"+matchUid
  else
    return "#"
}

const generateClass = (companyUser: any) => {
  if(companyUser && companyUser!.empoloyeeAccess !== true)
    return " cursor-default text-gray-300 hover:none"
  return " hover:bg-gray-50"
}

const MatchingList: React.FC<IMatchingEmployees> = ({ allMatches, profileid, showStatus = false }) => {
  
  const { t } = useTranslation('departments')
  const {companyUser} = useUserContext()

  if(allMatches === null || profileid === null)
    return (<span></span>)

  let greenIcon = <CheckCircleIcon className="flex-shrink-0 ml-1.5 h-5 w-5 text-green-400" aria-hidden="true"></CheckCircleIcon>
  let yellowIcon = <CheckCircleIcon className="flex-shrink-0 ml-1.5 h-5 w-5 text-yellow-500" aria-hidden="true"></CheckCircleIcon>
  let redIcon = <ExclamationCircleIcon  className="flex-shrink-0 ml-1.5 h-5 w-5 text-red-500" aria-hidden="true"></ExclamationCircleIcon >

  return (
      <ul className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
        {allMatches!.map((match) => (
          <li key={match.id}>
            <a href={generateLink(profileid, match.uid, companyUser)} className={"block"+generateClass(companyUser)}>
              <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                <div className="min-w-0 flex-1 flex items-center">

                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-4">
                    <div className='col-span-1'>
                      <p className="text-lg font-medium text-uw-teal items-center">{match.firstName}, {match.lastName}</p>
                      <p className="flex mt-0.5 items-center text-sm text-gray-500">
                        <MailIcon className="flex-shrink-0 mt-0.5 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                        <span className="truncate text-xs text-gray-500">{match.email}</span>
                      </p>
                    </div>
                    
                    {/* Ausbaustufe 2 */}
                    <div className="hidden md:block col-span-1">
                      <div>
                      <p className="text-sm mt-2 text-gray-600">
                          {match?.jobTitle}
                        </p>
                        <p className="flex mt-0.5 items-center text-xs text-gray-500">
                          {t(String(match?.department))}
                        </p>
                      </div>
                    </div> 

                    <div className="hidden md:block col-span-1 self-center">
                    
                    {(showStatus===true && match?.status!=='NOTHING')?
                      <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm bg-gray-100 text-uw-teal">
                        {t('companyRecruitingJobProfile:candidateStatus:'+match?.status)}
                      </span>
                    :null}
                    
                  </div> 

                    {/* Ausbaustufe 5 oder so
                    <div className="hidden md:block col-span-1">
                      <div>
                        <p className="text-md text-gray-800">
                          {requirementProfile.profile.project}
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500">
                          {requirementProfile.profile.available}
                        </p>
                      </div>
                    </div> */}

                    <div className="flex justify-center">
                        <p className="mt-1 flex items-center text-xl col-span-1 text-gray-900 flex">
                          {match?.score}%
                          {(match.score && match?.score >= 50)?greenIcon:null}
                          {(match.score && match?.score < 50 && match?.score >= 20)?yellowIcon:null}
                          {((match.score && match?.score < 20) || !match.score)?redIcon:null}
                        </p>
                    </div>

                  </div>
                </div>
                <div>
                  <ChevronRightIcon className={(companyUser && companyUser!.empoloyeeAccess === true) ? "h-5 w-5 text-gray-400 mr-4" : "h-5 w-5 text-gray-300"} aria-hidden="true" />
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
  )
}


export default MatchingList
