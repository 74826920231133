
const scoreConfig: any = {
    g8t_trait1: {
        exact: 2,
        near: 1.75
    },
    g8t_trait2: {
        exact: 2,
        near: 1.75
    },
    g8t_trait3: {
        exact: 2,
        near: 1.75
    },
    g8t_trait4: {
        exact: 2,
        near: 1.75
    },
    g8t_trait5: {
        exact: 2,
        near: 1.75
    },
    g8t_trait6: {
        exact: 2,
        near: 1.75
    },
    g8t_trait7: {
        exact: 2,
        near: 1.75
    },
    g8t_trait8: {
        exact: 2,
        near: 1.75
    },
    g8t_trait9: {
        exact: 2,
        near: 1.75
    },
    g8t_trait10: {
        exact: 2,
        near: 1.75
    },
    g8t_trait11: {
        exact: 2,
        near: 1.75
    },
    g8t_trait12: {
        exact: 2,
        near: 1.75
    },
    g8t_trait13: {
        exact: 2,
        near: 1.75
    },
    g8t_trait14: {
        exact: 2,
        near: 1.75
    },
    g8t_trait15: {
        exact: 2,
        near: 1.75
    },
    g8t_trait16: {
        exact: 2,
        near: 1.75
    },
    g8t_trait17: {
        exact: 2,
        near: 1.75
    },
    g8t_trait18: {
        exact: 2,
        near: 1.75
    },
    g8t_trait19: {
        exact: 2,
        near: 1.75
    },
    g8t_trait20: {
        exact: 2,
        near: 1.75
    },
    g8t_trait21: {
        exact: 2,
        near: 1.75
    },
    g8t_trait22: {
        exact: 2,
        near: 1.75
    },
    g8t_trait23: {
        exact: 2,
        near: 1.75
    },
    g8t_trait24: {
        exact: 2,
        near: 1.75
    },
    g8t_trait25: {
        exact: 2,
        near: 1.75
    },
    g8t_trait26: {
        exact: 2,
        near: 1.75
    },
    g8t_trait27: {
        exact: 2,
        near: 1.75
    },
    g8t_trait28: {
        exact: 2,
        near: 1.75
    },
    g8t_trait29: {
        exact: 2,
        near: 1.75
    },
    g8t_trait30: {
        exact: 2,
        near: 1.75
    },
    g8t_trait31: {
        exact: 2,
        near: 1.75
    },
    g8t_trait32: {
        exact: 2,
        near: 1.75
    },
    g8t_trait33: {
        exact: 2,
        near: 1.75
    },
    g8t_trait34: {
        exact: 2,
        near: 1.75
    },
    g8t_trait35: {
        exact: 2,
        near: 1.75
    },
    g8t_trait36: {
        exact: 2,
        near: 1.75
    },
    g8t_trait37: {
        exact: 2,
        near: 1.75
    },
    g8t_trait38: {
        exact: 2,
        near: 1.75
    },
    g8t_trait39: {
        exact: 2,
        near: 1.75
    },
}

const nearConfig = 1

// Score Employees with profile
function scoreEmployees(profile: any, matchingEmployees: any) : any {

    // Go through all employees
    for(const employee of matchingEmployees) {

        let score = 0
        let profileElementsCount = 0

        // Go through testresult data of employee
        for(const data in employee.data) {
            
            // Check if we have a value in profile
            if(profile.data[data]) {

                profileElementsCount++

                // get absolut diff
                //console.log(profile.data[data], employee.data[data])
                let diff = Math.abs(profile.data[data] - employee.data[data])

                // exact match
                if(diff === 0) {
                    // add config for exact match for this node
                    score += scoreConfig[data].exact
                // near match
                } else if (diff - nearConfig === 0) {
                    // add config for near match for this node
                    score += scoreConfig[data].near
                }
                //console.log(diff)

            }

            employee.score = score
        }

        // profileElementsCount * 2 due to exact = 2, so 100% is double
        let scoreInPercent = Math.round((employee.score/((profileElementsCount*2)/100)))
        if(scoreInPercent>100)
            scoreInPercent = 100
        employee.score = scoreInPercent

    }

    // Sort by score
    matchingEmployees.sort(function(a: any, b: any){
        return parseInt(b.score) - parseInt(a.score)
    })

    return matchingEmployees
}

export { scoreEmployees }