//React
import React, { useState } from 'react'
//import { useTranslation } from "react-i18next";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid'
import useCollapse from 'react-collapsed';
//Hooks
interface Props {
  data: IState,
  onClick: any,
  state: IUserData
}

export interface IState {
  //points?: number
  description?: string,
  name?: string,
  high?: string,
  low?: string,
  level?: any,
  element?: string
}

export interface IUserData {
  [index: string]: any
}

const RequirementProfileCompetenciesActive: React.FC<Props> = ({data, onClick, state}) => {

  //console.log('###', data.level[state['g8t_'+data.element]]);
  //const { t } = useTranslation(['companyRequirementProfiles']);

  const [ isExpanded, setExpanded ] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
  function handleOnClick() {
      setExpanded(!isExpanded);
  }

  const getClasses = (stateElement:string, idElement:string, direction:string = '') => {
    let classes = 'col-span-1'

    if(stateElement === idElement)
      classes += ' bg-uw-teal cursor-pointer'
    else {
      classes += 'col-span-1 cursor-pointer'
    }

    if(direction === 'left')
      classes += ' rounded-l-md'
    if(direction === 'right')
      classes += ' rounded-r-md'

    return classes

  }

  let boxCss = "bg-white shadow overflow-hidden sm:rounded-md mb-3"

  if(state['g8t_'+data.element])
    boxCss += " border-l-4 border-uw-teal"

  //console.log(data);
  return (
      <div className={boxCss}>
      
        <div className="flex">
          <button {...getToggleProps({onClick: handleOnClick})} className="hover:bg-gray-50 flex-1" >
            <div className="flex py-4 px-4 sm:px-6 md:grid md:grid-cols-5">
              <div className="md:col-span-4">
                <div className="items-center px-4 text-left">
                    <p className="text-md font-medium text-uw-teal">{data.name}</p>
                </div>
              </div>
              <div className='items-center md:col-span-1 flex justify-end'>
                {isExpanded ? <ChevronUpIcon className="h-5 w-5 text-gray-400 " aria-hidden="true" /> : <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
              </div>
            </div>
          </button>
        </div>

        <div {...getCollapseProps()}>
          <div className="block flex-1 px-4 pb-4 md:grid md:grid-cols-5 md:gap-1" >
            <div className="md:col-span-2">
              <p className="px-6 text-sm font-small text-gray-600 ">{data.level[state['g8t_'+data.element]]}</p>
            </div>
            <div className="md:col-span-1">
              <p className="px-6 text-sm font-small text-uw-teal text-right">{data.low}</p>
            </div>
            <div className="md:col-span-1">
              <div className="grid grid-cols-5 h-7 box-border border-solid border-uw-teal border divide-x divide-uw-teal rounded-lg">
                <div className={getClasses(state['g8t_'+data.element], '1', 'left')} title={'g8t_'+data.element} id="1" onClick={onClick}></div>
                <div className={getClasses(state['g8t_'+data.element], '2')} title={'g8t_'+data.element} id="2" onClick={onClick}></div>
                <div className={getClasses(state['g8t_'+data.element], '3')} title={'g8t_'+data.element} id="3" onClick={onClick}></div>
                <div className={getClasses(state['g8t_'+data.element], '4')} title={'g8t_'+data.element} id="4" onClick={onClick}></div>
                <div className={getClasses(state['g8t_'+data.element], '5', 'right')} title={'g8t_'+data.element} id="5" onClick={onClick}></div>
              </div>
            </div>
            <div className="md:col-span-1">
              <p className="px-6 text-sm font-small text-uw-teal text-left">{data.high}</p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default RequirementProfileCompetenciesActive

