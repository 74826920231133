//React
import React from 'react'
import { Link } from 'react-router-dom'
import { useParams } from "react-router"
import { useTranslation } from "react-i18next"
//Componennts & Layouts
import CustomMenu from '../../components/layout/Menu/CustomMenu'
import Footer from "../layout/FooterNew"
import { IEmployee } from '../layout/Employee/Employee'
import { INavigation, INavigationMainItem } from "../layout/Menu/CustomMenu"
import BreadcrumbNavigation from '../layout/Menu/BreadcrumbNavigation'
//EmployeeResults
import EmployeeResultsCompanyDesktop from "../employeeResults/desktopResults/EmployeeResultsCompany"
import EmployeeResultsCompanyMobile from "../employeeResults/mobileResults/EmployeeResultsCompany"
//Hooks
import { useGetEmployee } from '../../hooks/useGetEmployee'

import SiteNotFoundScreen from "../../screens/SiteNotFoundScreen";
import { useUserContext } from "../../firebase/FirebaseContext";


const CompanyEmployeeDetailsView: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  const { uid } = useParams<Record<string, string | undefined>>();
  const employee: IEmployee | null = useGetEmployee(uid);
  const { t } = useTranslation(['employeeResults', 'resultsCompanyOverallScreen', 'basics'])

  const breadCrumbHome = {
    name: 'home',
    href: '/company/dashboard',
    current: false
  }


  const {companyUser} = useUserContext()

  if(companyUser && companyUser!.empoloyeeAccess !== true) {
    return (
      <>
        <SiteNotFoundScreen />
      </>
    )
  }

  let userName = employee?.firstName + ' ' + employee?.lastName

  const breadCrumbPaths: INavigationMainItem[] = [
    {
      name: userName,
      href: '/company/results/employee/' + uid,
      current: true
    }
  ]

  return (
    <>
      <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
      <div className="py-10">
        <header className="mt-16">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="pt-2 pb-4">
              <BreadcrumbNavigation homePath={breadCrumbHome} currentPaths={breadCrumbPaths}></BreadcrumbNavigation>
            </div>
            <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal pb-10">{t('companyEmployeeResults:headline.EmployeeResultsHeadline')}</h1>
          </div>
        </header>
        <main>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row justify-between mb-2 lg:mb-8">
              <div className="max-w-sm mr-12">
                <h2 className="lg:text-2xl font-medium text-uw-teal text-lg mb-4">{t('companyEmployeeResults:introText.headline')}</h2>
              </div>
              <div className="md:w-57%">
                <p className="font-regular lg:leading-textOneTwoDesktop sm:text-base text-uw-teal lg:text-2xl">{t('companyEmployeeResults:introText.introText')}</p>
              </div>
            </div>
          </div>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="px-4 py-8 sm:px-0 lg:hidden xl:hidden">
              <EmployeeResultsCompanyMobile />
            </div>
            <div className="px-4 py-8 sm:px-0 hidden lg:block xl:block">
              <EmployeeResultsCompanyDesktop />
            </div>
            <div className="px-4 py-8 sm:px-0">
              <button
                type="button"
                className="inline-flex items-center px-5 py-2 border border-transparent text-sm font-regular rounded-full shadow-sm text-white bg-uw-teal hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <Link to={`/company/dashboard`}>{t('basics:buttons.backToOverview')}</Link>
              </button>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  )
}

export default CompanyEmployeeDetailsView
