import React from 'react'
import classnames from 'classnames'

interface Props {
    opacity: boolean
}

const EmployeeCardPoint: React.FC<Props> = ({opacity}) => {

    return (
        <>
            <div className={classnames(
                "bg-uw-teal rounded-full h-1 w-4",
                {
                    "opacity-1" : opacity
                }
            )}
            />
        </>
    )
}

export default EmployeeCardPoint