import React, {useCallback, useState} from 'react'
import Text from "../textStyling/Text"
import H4 from "../textStyling/H4";
import IconRectangle from "../design/IconRectangle";
import classnames from "classnames";

interface IProps {
    headline: string
    subTitles: Array<string>
    texts: Array<string>
}

const FaqDropdown: React.FC<IProps> = ({headline, subTitles, texts}) => {

    const [subPartOpened, setSubPartOpen] = useState(false)
    const uuidv1 = require('uuid/v1')
    const toggleExpand = useCallback(() => setSubPartOpen(!subPartOpened), [setSubPartOpen, subPartOpened])
    const generateSubText = () => {
        let subText: Array<object> = []
        let counterForBr = 0
        for (let i = 0; i < subTitles.length; i++) {
            subText.push(
                <div key={uuidv1()}>
                    <div className="mb-2 pl-4">
                        <Text color="text-uw-grey" semiBold three>
                            {subTitles[i]}
                        </Text>
                    </div>
                    <div className="pl-4 pr-12">
                        <Text color="text-uw-grey" three>
                            {texts[i]}
                        </Text>
                    </div>
                    {counterForBr % 2 === 0 && subTitles.length > 1 && <br/>}
                </div>
            )
            counterForBr++
        }
        return subText
    }

    return (
        <>
            <div className="flex flex-row my-4 cursor-pointer" onClick={toggleExpand}>
                <div className="ml-4 flex w-3/4 justify-start">
                    <H4 color="uw-teal">
                        {headline}
                    </H4>
                </div>
                <div className="mr-4 flex flex-1 items-center justify-end">
                    <div>
                        {subPartOpened &&
                        <div className="rotate-180">
                            <IconRectangle/>
                        </div>}
                        {!subPartOpened && <IconRectangle/>}
                    </div>
                </div>
            </div>
            <div className={classnames("mb-4", {
                    "hidden pb-2": !subPartOpened
                },
                {
                    "mb-8": subPartOpened && subTitles.length <= 1
                },
                {
                    "mb-6": subPartOpened && subTitles.length > 1
                }
            )}
            >
                {generateSubText()}
            </div>
            <div>
                <hr className="border-uw-teal border"/>
            </div>
        </>
    )
}

export default FaqDropdown;