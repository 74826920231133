//React
import React from 'react'
//Components
//import MobileView from "../components/employeeResults/mobileResults/MobileView";
import DesktopView from "../components/employeeResults/desktopResults/DesktopDevelopmentView";

const ResultsEmployeeDevelopment: React.FC = () => {

  return (
    <>
      <DesktopView />
    </>
  )
}

export default ResultsEmployeeDevelopment