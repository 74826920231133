import { firestore } from "../firebase/Firebase"
import { doc, updateDoc, getDoc, Timestamp } from 'firebase/firestore'
import { EJobStatus } from "./useSaveJob";

export interface IUserData {
  [index: string]: number
}

interface ICandidateStatus {
  date: number | Timestamp,
  status: EJobStatus
}


export const useChangeCandidateStatus = () => {

  const changeCandidateStatus =  async (candidateId : string, candidateStatus: ICandidateStatus, jobId: string) => {

    try {

      const docRef = doc(firestore, `employees`, candidateId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {

        let jobs = docSnap.data().jobs
        
        if(jobs[jobId] && Array.isArray(jobs[jobId].status)) {
          jobs[jobId].status.push(candidateStatus)
        } else {
          return
        }

        await updateDoc(docRef, { jobs: jobs } );
      }

    } catch(error) {
      console.log(error)
    }

  }

  return changeCandidateStatus
}
