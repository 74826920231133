import React from 'react';

interface Props {
    color: "uw-teal" | "white"

}

const H1: React.FC<Props> = ({children, color}) => {

    return (
        <>
            <div className="lg:hidden xl:hidden">
                <h1 className={`font-headlines text-h1Mobile leading-h1Mobile font-semibold text-${color}`}>{children}</h1>
            </div>
            <div className="hidden lg:block xl:block">
                <h1 className={`font-headlines text-h1Desktop leading-h1Desktop font-semibold text-${color}`}>{children}</h1>
            </div>
        </>
    )
        ;
}

export default H1;
