//React
import React, { useEffect, useState } from "react"
import { Redirect } from "react-router"
//Styles
import Text from "../components/textStyling/Text"
import H2 from "../components/textStyling/H2"
import H1 from "../components/textStyling/H1"
import H3 from "../components/textStyling/H3";
//Container & Button & Iconright & classnames
import Container from "../components/layout/Container"
import Button from "../components/layout/Button"
import IconRight from "../components/design/IconRight"
import classnames from "classnames"
//UseTranslation
import { useTranslation } from "react-i18next"
//FirebaseContext
import { useUserContext } from "../firebase/FirebaseContext"
//import CurrentUser from "./CurrentUser"


const EmployeeDashboard: React.FC = () => {

  window.scrollTo({top: 0, behavior: 'smooth'});

  //useEmployeeName hook 
  const { employeeUser } = useUserContext()
  const [redirectToFaq, setRedirectToFaq] = useState(false)
  const [testDisabled, setTestDisabled] = useState<boolean>(false)
  const [resultDisabled, setResultDisabled] = useState<boolean>(true)

  useEffect(() => {
    if (employeeUser?.test === undefined)
      setTestDisabled(false)
    else {
      //useTestDone Hook
      if (employeeUser?.test.testDone)
        setTestDisabled(true)
      else
        setTestDisabled(false)
      //useRecievedResult hook
      if (employeeUser?.test.receivedResults)
        setResultDisabled(false)
      else
        setResultDisabled(true)
    }
  }, [employeeUser])

  const [redirectToTest, setRedirectToTest] = useState(false)
  const [redirectToResult, setRedirectToResult] = useState(false)

  const { i18n, t } = useTranslation(['employeeLandingPage', 'basics'])

  const bodyContentGreenBackground = (
    <>
      <div className="flex flex-col justify-center lg:justify-start xl:justify-start">
        <div className="lg:flex xl:flex lg:flex-row xl:flex-row lg:justify-between xl:justify-between">
          <div className="text-center mt-12 lg:text-left xl:text-left lg:mt-32 xl:mt-32 lg:mb-24 xl:mb-24">
            <H1 color="white">
              {`${t('Dashboard')}`}
            </H1>
          </div>
        </div>
        <div
          className="mt-12 mb-16 lg:mt-0 xl:mt-0 lg:mb-24 xl:mb-24 flex flex-col lg:flex-row xl:flex-row lg:justify-center xl:justify-center">
          <div
            className={classnames("bg-uw-teal border-uw-teal shadow-employeeLandingPage border " +
              "rounded-15px p-8 lg:p-0 xl:p-0 lg:h-20.5rem lg:w-27.5rem xl:h-20.5rem xl:w-27.5rem " +
              "lg:flex xl:flex lg:justify-center xl:justify-center lg:mr-24 xl:mr-24 mb-12 lg:mb-0 xl:mb-0",
              {
                "bg-uw-opacityTeal shadow-employeeLandingPageOpacity": testDisabled
              })}>
            <div
              className="flex flex-col justify-center lg:ml-15 xl:ml-15 items-center lg:items-start xl:items-start">
              <div className="flex flex-row justify-between items-center">
                <div className={classnames(
                  { "opacity-25": testDisabled },
                  { "mr-16": i18n.language.includes('de') },
                  { "mr-3": i18n.language.includes('en') }
                )}>
                  <H2 color="white">
                    {t('testCardHeadline')}
                  </H2>
                </div>
                {testDisabled && <IconRight height={'25.5px'} width={"27.5px"} />}
              </div>
              <div className={classnames("hidden lg:block xl:block my-8 w-4/5",
                {
                  "opacity-25": testDisabled
                }
              )}>
                <Text color="text-white" three>
                  {t('testCardText')}
                </Text>
              </div>
              <div className={classnames("lg:hidden xl:hidden my-8 flex justify-center",
                {
                  "opacity-25": testDisabled
                })}>
                <Text color="text-white">
                  {t('testCardText')}
                </Text>
              </div>
              <div className={classnames("w-56",
                {
                  "opacity-25": testDisabled
                })}>
                <Button
                  backgroundWhite
                  className={testDisabled !== null ? (testDisabled ? "cursor-not-allowed" : "") : ""}
                  disabled={testDisabled !== null && testDisabled}
                  onClick={() => {
                    setRedirectToTest(true)
                  }}
                  title={t('testCardButton')}
                  type="button" />
              </div>
            </div>
          </div>
          <div
            className={classnames("bg-uw-teal border-uw-teal shadow-employeeLandingPage border " +
              "rounded-15px p-8 lg:p-0 xl:p-0 lg:h-20.5rem lg:w-27.5rem xl:h-20.5rem xl:w-27.5rem " +
              "lg:flex xl:flex lg:justify-center xl:justify-center",
              {
                "opacity-25": resultDisabled
              })}>
            <div
              className="flex flex-col justify-center lg:ml-15 xl:ml-15 items-center lg:items-start xl:items-start">
              <div className="flex flex-col">
                <H2 color="white">
                  {t('resultCardHeadline')}
                </H2>
                <div className="hidden lg:block xl:block my-8 w-4/5">
                  <Text color="text-white" three>
                    {t('resultCardText')}
                  </Text>
                </div>
                <div className="lg:hidden xl:hidden my-8 flex justify-center">
                  <Text color="text-white">
                    {t('resultCardText')}
                  </Text>
                </div>
                <div className="w-56">
                  <Button
                    backgroundWhite
                    className={resultDisabled ? "cursor-not-allowed" : ""}
                    disabled={resultDisabled}
                    onClick={() => {
                      setRedirectToResult(true)
                    }}
                    title={t('resultCardButton')}
                    type="button" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const bodyContentSecond = (
    <>
         {/*Mobile*/}
         <div className="lg:hidden xl:hidden relative z-10">
            <H3 color="uw-teal">{t('basics:texts.stillQuestions')}</H3>
            <div className="m-8"/>
            <Text color="text-uw-grey">{t('basics:texts.faqText')}</Text>
            <div className="m-6"/>
            <Button backgroundWhite borderColor onClick={setRedirectToFaq} title={t('basics:buttons.buttonFaq')}
                    type="button"/>
            <div className="m-12"/>
        </div>
        {/*Desktop*/}
        <div className="hidden lg:block xl:block relative z-10 mt-12">
            <div className="flex flex-row justify-between mr-20 mb-8">
                <H3 color="uw-teal">{t('basics:texts.stillQuestions')}</H3>
                <div className="flex flex-col">
                    <div className="w-24rem">
                        <Text color="text-uw-grey" one>{t('basics:texts.faqText')}</Text>
                    </div>
                    <div className="m-4"/>
                    <div className="mb-16">
                        <Button backgroundWhite borderColor onClick={setRedirectToFaq}
                                title={t('basics:buttons.buttonFaq')}
                                type="button"/>
                    </div>
                </div>
            </div>
        </div>
    </>
  )


  return (
    <>
      {redirectToTest && <Redirect push to="/employee/test/intro" />}
      {redirectToResult && <Redirect push to="/employee/results" />}
      {redirectToFaq && <Redirect push to="/employee/faq"/>}
      <Container
        background="white"
        bodyContentGreenBackground={bodyContentGreenBackground}
        greenBackground
        bodyContentSecond={bodyContentSecond}
        greenBackgroundLessUpperPart
        headerFooter
        logout
      />
    </>
  )
}

export default EmployeeDashboard;