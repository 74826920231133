import React, {useEffect, useState} from 'react'
import Switch from 'react-switch'
import {useTranslation} from "react-i18next";
import classnames from "classnames"

interface Props {
    autoFocus?: boolean
    error?: boolean
    errorMsg?: string
    formSubmitted?: boolean
    link?: string;
    nameSpace?: string;
    text?: string;
    textFirst?: string;
    textLast?: string;
    textStyle?: string;
    underlinedText?: string;
    value: boolean;
    setValue: (x: boolean) => void;
    width?: number
}

const SwitchButton: React.FC<Props> = ({autoFocus, error, errorMsg, formSubmitted, nameSpace, text, textStyle, textFirst, value, setValue, width}) => {

    const {t} = useTranslation(nameSpace === undefined ? "companyRegistration" : nameSpace)
    const [lastFormSubmitted, setLastFormSubmitted] = useState(false)

    useEffect(() => {
        if (autoFocus === true && error) {
            if (lastFormSubmitted !== formSubmitted) {
                document.getElementById("switch")!.scrollIntoView(false)
                setLastFormSubmitted(!lastFormSubmitted)
            }
        }
    }, [formSubmitted, lastFormSubmitted, autoFocus, error,])

    return (
        <>

            <div className="flex flex-row my-2 items-center mr-6">
                <div className="ml-2 mr-4 mt-2">
                    <Switch
                        className="border border-white"
                        checked={value}
                        checkedIcon={false}
                        handleDiameter={21}
                        height={30}
                        offColor="#008080"
                        onColor="#7ED321"
                        onChange={() => setValue(!value)}
                        uncheckedIcon={false}
                        width={width !== null ? width : 60}
                    />
                </div>
                {textFirst === undefined ?
                    <p className={classnames("justify-around content-center items-center text-white font-text font-normal leading-snug text-button", textStyle)}>
                            {text}
                    </p> :
                    <p className="justify-around content-center items-center text-white font-text font-normal leading-snug text-button">
                        {textFirst}
                    </p>
                }
            </div>
            {error &&
            <div className="lg:w-full xl:w-full" id="switch">
                <div className="relative">
                    <div className="ml-6 arrow-up absolute top-minus11"/>
                </div>
                <p
                    className="max-w-md text-xs opacity-100 bg-uw-errorRed flex w-full p-2 rounded-15px font-text leading-text font-normal text-uw-errorRedText"
                >{t(`${errorMsg}`)}</p>
            </div>
            }
        </>
    )
}

export default SwitchButton