//React
import { useState, useEffect } from "react"
//Firebase
import { collection, getDoc, getDocs, query, where } from "firebase/firestore"
import { firestore } from "../firebase/Firebase"
import { useUserContext } from "../firebase/FirebaseContext"

export interface ICompanyTestStats {
  size : number,
  inProgress: number,
  done : number,
  leftSize : number,
  invitationLink: string | null | undefined,
  invitationLinkCandidate: string | null | undefined
}

export const useCompanyTestStats = () => {
  const {companyUser} = useUserContext()
  const [results, setResults] = useState<ICompanyTestStats>({ size : 0, inProgress: 0, done : 0, leftSize : 0, invitationLink: '', invitationLinkCandidate: '' })
  useEffect(() => {
    (async () => {
      let resultObj = { size : 0, inProgress: 0, done : 0, leftSize : 0, invitationLink: '', invitationLinkCandidate: '' }
      if (companyUser !== null && companyUser.testGroups) {
        var getUrl      = window.location;
        resultObj.size  = +companyUser.company.participators;
        resultObj       = { ...resultObj, ...{ invitationLink: getUrl.protocol + '//'+getUrl.host+'/employee/register/'+companyUser?.testGroups[0].id, invitationLinkCandidate: getUrl.protocol + '//'+getUrl.host+'/candidate/register/'+companyUser?.testGroups[0].id } };

        let usedLicences = 0

        if (companyUser.testGroups.length > 0) {
          const testGroupRef = companyUser.testGroups[0]
          const testGroup = await getDoc(testGroupRef)
          const employeesRef = collection(firestore, 'employees')
          const q = query(employeesRef, where('testGroups', '==', companyUser.testGroups))
          const allUserRelatedToTest = await getDocs(q)

          usedLicences = allUserRelatedToTest.size

          if (testGroup && testGroup.data() && testGroup.data()!.results) {
              resultObj.done = testGroup.data()!.results.g8t_category1.length;
          }
        }
        resultObj.leftSize = resultObj.size-usedLicences;
      }
      setResults(resultObj);
    })()
  }, [companyUser])
  return results;
}