import React from 'react';
import { useTranslation } from "react-i18next";
import FaqDropdown from '../layout/FaqDropdown';
import H2 from '../textStyling/H2';

const CompanyRegistrationFaqs: React.FC = () => {

  const { t } = useTranslation(['companyFaq', 'basics'])

  const titles = t('registrationFaq.questions') as Array<string>
  const texts = t('registrationFaq.answers') as Array<Array<string>>
  const subtitles = t('registrationFaq.subTitles') as Array<Array<string>>

  return (
    <div className="lg:block xl:block relative z-10 mt-12">
      <div className="justify-between lg:mr-20 mb-12">
        <div className="flex flex-col">
          <div className="lg:flex xl:flex lg:flex-row xl:flex-row lg:mb-16 xl:mb-16">
            <div className="mb-8 lg:mb-0 xl:mb-0 lg:mr-8 lg:pt-4 xl:pt-4 lg:w-4/12 xl:w-4/12">
              <H2 color="uw-teal">
                {t("registrationFaq.questionsHeadline")}
              </H2>
            </div>
            <div
              className="lg:flex xl:flex lg:flex-col xl:flex-col lg:flex-grow xl:flex-grow lg:w-8/12 xl:w-8/12">
              <hr className="border-uw-teal border w-full" />
              {titles.map((title, index) => (
                <FaqDropdown key={index} headline={title} subTitles={subtitles[index]} texts={texts[index]} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyRegistrationFaqs