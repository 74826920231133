import React from 'react'
import RecruitingIndividualComparisonView from "../components/recruitingDashboard/RecruitingIndividualComparisonView"
import { INavigation } from "../components/layout/Menu/CustomMenu"


const RecruitingIndividualComparison: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  return (
    <>
      <RecruitingIndividualComparisonView mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
    </>
  )
}

export default RecruitingIndividualComparison