//React
import React from 'react';
import { Route, Switch } from "react-router-dom"
import { isIE, isEdge } from 'react-device-detect'
//Components
import AuthRoute from './components/authRoutes/authRoutes'
import AdminDashboard from './components/admin/adminDashboard'
import AccountingDashboard from './components/admin/accountDashbaord'
//Firebase
import { userRole, UserRoles } from './firebase/UserIntefaces'
//Screens
import TestScreen from './screens/TestScreen'
import TestScreenCandidate from './screens/TestScreenCandidate'
import Login from "./screens/Login"
import CompanyRegistrationScreen from "./screens/CompanyRegistrationScreen"
import EmployeeRegistrationScreen from "./screens/EmployeeRegistrationScreen"
import CandidateRegistrationScreen from "./screens/CandidateRegistrationScreen"
import CompanyDashboard from "./screens/CompanyDashboard"
import RecruitingDashboard from "./screens/RecruitingDashboard"
import RecruitingJobProfile from "./screens/RecruitingJobProfile"
import RecruitingIndividualComparison from "./screens/RecruitingIndividualComparison"
//import CompanyDetails from "./screens/CompanyDetails"
import CompanyEmployeeDetails from "./screens/CompanyEmployeeDetails"
import CompanyRequirementProfiles from "./screens/CompanyRequirementProfiles"
import CompanyRequirementProfileNew from "./screens/CompanyRequirementProfileNew"
import CompanyRequirementJobNew from "./screens/CompanyRequirementJobNew"
import EmployeeRequirementProfileNew from "./screens/EmployeeRequirementProfileNew"
import CompanyRequirementProfileEdit from "./screens/CompanyRequirementProfileEdit"
import CompanyRequirementJobEdit from "./screens/CompanyRequirementJobEdit"
import CompanyMatching from "./screens/CompanyMatching"
import CompanyMatchingComparison from "./screens/CompanyMatchingComparison"
import ResultsEmployee from "./screens/ResultsEmployee"
import ResultsEmployeeDevelopment from "./screens/ResultsEmployeeDevelopment"
import ResultsCandidate from "./screens/ResultsCandidate"
//import ResultsCandidateDevelopment from "./screens/ResultsCandidateDevelopment"
import CompanyRegistrationConfirmationScreen from './screens/CompanyRegistrationConfirmationScreen';
import TestIntroScreen from './screens/TestIntroScreen';
import TestConfirmationScreen from './screens/TestConfirmationScreen';
import TestIntroScreenCandidate from './screens/TestIntroScreenCandidate';
import TestConfirmationScreenCandidate from './screens/TestConfirmationScreenCandidate';
import Logout from './screens/Logout';
import CompanyFaq from "./screens/CompanyFaq";
import EmployeeFaq from "./screens/EmployeeFaq";
import CandidateFaq from "./screens/CandidateFaq";
import CompanyContact from "./screens/CompanyContact";
import SiteNotFoundScreen from "./screens/SiteNotFoundScreen";
import EmployeeDashboard from "./screens/EmployeeDashboard";
import CandidateDashboard from "./screens/CandidateDashboard";
import CompanySettings from "./screens/CompanySettings";
import EdgeErrorScreen from "./screens/EdgeErrorScreen";
import LoadingScreen from "./components/layout/LoadingScreenNew";
import { useUserContext } from "./firebase/FirebaseContext";
import CompanyIndividualComparison from "./screens/CompanyIndividualComparison";
import CompanyIndividualDevelopment from "./screens/CompanyIndividualDevelopment";




const App: React.FC = () => {

	const {loading} = useUserContext()
	//const location = useLocation();

	if (isIE || isEdge) {
		return (
			<EdgeErrorScreen />
		)
	}

	
	if(loading) {
		return (<LoadingScreen />)
	}
	
	//console.log('app.tsx', currentUser)
	/*
	if(((isLoggedIn && location.pathname === '/login') || (isLoggedIn && location.pathname === '/')) && currentUser) {
		switch (currentUser!.role) {
			case 'EMPLOYEE':
			  return <Redirect to='/employee' />
			case 'COMPANY':
			  return <Redirect to='/company/dashboard' />
			case 'LUKSIT_ADMIN':
			  return <Redirect to='/luksit/dashboard' />
		  }
	}

	if(((!isLoggedIn && location.pathname === '/'))) {
		return <Redirect to='/login' />
	}
	*/

	//console.log(userRole.allCompanies)

	return (
		<Switch>
			
			<Route path="/company/register" exact component={CompanyRegistrationScreen} />
			<Route path="/company/success" exact component={CompanyRegistrationConfirmationScreen} />
			<Route path="/employee/register/:id" exact component={EmployeeRegistrationScreen} />
			<Route path="/candidate/register/:id/:jobId" exact component={CandidateRegistrationScreen} />
			<Route path="/login" exact component={Login} />
			<Route path="/" exact component={Login} />
			<Route path="/logout" exact component={Logout} />
			<Route path="/employee/faq" exact component={EmployeeFaq} />
			<Route path="/candidate/faq" exact component={CandidateFaq} />
			{/* Employee Routes */}
			{/* {isAuthed &&
				<> */}
			<AuthRoute
				path='/employee'
				exact
				Component={EmployeeDashboard}
				requiredRoles={userRole.employees
					/*[
					String(userRole.admins),
					String(userRole.employees)
					]*/
				}
			/>
			<AuthRoute
				path='/employee/results'
				exact
				Component={ResultsEmployee}
				requiredRoles={userRole.employees}
			/>
			<AuthRoute
				path='/employee/results/development'
				exact
				Component={ResultsEmployeeDevelopment}
				requiredRoles={userRole.employees}
			/>
			<AuthRoute
				path='/employee/test/intro'
				exact
				Component={TestIntroScreen}
				requiredRoles={userRole.employees}
			/>
			<AuthRoute
				path='/employee/test/'
				exact
				Component={TestScreen}
				requiredRoles={userRole.employees}
			/>
			<AuthRoute
				path='/employee/test/done'
				exact
				Component={TestConfirmationScreen}
				requiredRoles={userRole.employees}
			/>

			<AuthRoute
				path='/candidate'
				exact
				Component={CandidateDashboard}
				requiredRoles={userRole.candidates
					/*[
					String(userRole.admins),
					String(userRole.employees)
					]*/
				}
			/>
			<AuthRoute
				path='/candidate/results'
				exact
				Component={ResultsCandidate}
				requiredRoles={userRole.candidates}
			/>
			<AuthRoute
				path='/candidate/test/intro'
				exact
				Component={TestIntroScreenCandidate}
				requiredRoles={userRole.candidates}
			/>
			<AuthRoute
				path='/candidate/test'
				exact
				Component={TestScreenCandidate}
				requiredRoles={userRole.candidates}
			/>
			<AuthRoute
				path='/candidate/test/done'
				exact
				Component={TestConfirmationScreenCandidate}
				requiredRoles={userRole.candidates}
			/>


			{/* Company Routes */}
			<AuthRoute
				path='/company/dashboard'
				exact
				ComponentNav={CompanyDashboard}
				requiredRoles={
					[
						String(UserRoles.COMPANY),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
			<AuthRoute
				path='/recruiting/dashboard'
				exact
				ComponentNav={RecruitingDashboard}
				requiredRoles={
					[
						String(UserRoles.RECRUITING),
						String(UserRoles.RECRUITING_DEMO),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
			<AuthRoute
				path='/recruiting/jobprofile/:jobid'
				exact
				ComponentNav={RecruitingJobProfile}
				requiredRoles={
					[
						String(UserRoles.RECRUITING),
						String(UserRoles.RECRUITING_DEMO),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
						<AuthRoute
				path='/recruiting/jobprofile/:jobid/:uid'
				exact
				ComponentNav={RecruitingIndividualComparison}
				requiredRoles={
					[
						String(UserRoles.RECRUITING),
						String(UserRoles.RECRUITING_DEMO),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
			{/*<AuthRoute
				path='/company/results'
				exact
				ComponentNav={CompanyDetails}
				requiredRoles={userRole.companies}
			/>*/}
			<AuthRoute
				path='/company/results/employee/:uid'
				exact
				ComponentNav={CompanyEmployeeDetails}
				requiredRoles={
					[
						String(UserRoles.COMPANY),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
			<AuthRoute
				path='/company/contact'
				exact
				ComponentNav={CompanyContact}
				requiredRoles={userRole.allCompanies}
			/>
			<AuthRoute
				path='/company/faq'
				exact
				ComponentNav={CompanyFaq}
				requiredRoles={userRole.allCompanies}
			/>
			<AuthRoute
				path='/company/settings'
				exact
				ComponentNav={CompanySettings}
				requiredRoles={userRole.allCompanies}
			/>
			<AuthRoute
				path='/company/matching/'
				exact
				ComponentNav={CompanyRequirementProfiles}
				requiredRoles={
					[
						String(UserRoles.COMPANY),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
			<AuthRoute
				path='/company/requirementprofiles/createprofile'
				exact
				ComponentNav={CompanyRequirementProfileNew}
				requiredRoles={
					[
						String(UserRoles.COMPANY),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
			<AuthRoute
				path='/recruiting/dashboard/createjob'
				exact
				ComponentNav={CompanyRequirementJobNew}
				requiredRoles={
					[
						String(UserRoles.RECRUITING),
						String(UserRoles.RECRUITING_DEMO),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
			<AuthRoute
				path='/company/matching/editprofile/:uid'
				exact
				ComponentNav={CompanyRequirementProfileEdit}
				requiredRoles={
					[
						String(UserRoles.COMPANY),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
			<AuthRoute
				path='/recruiting/dashboard/editjob/:uid'
				exact
				ComponentNav={CompanyRequirementJobEdit}
				requiredRoles={
					[
						String(UserRoles.RECRUITING),
						String(UserRoles.RECRUITING_DEMO),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
			<AuthRoute
				path='/company/matching/:profileid'
				exact
				ComponentNav={CompanyMatching}
				requiredRoles={
					[
						String(UserRoles.COMPANY),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
			{/*<AuthRoute
				path='/company/matching'
				exact
				ComponentNav={CompanyMatching}
				requiredRoles={
					[
						String(UserRoles.COMPANY),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>*/}
			<AuthRoute
				path='/company/matching/comparison/:profileid/:uid'
				exact
				ComponentNav={CompanyMatchingComparison}
				requiredRoles={
					[
						String(UserRoles.COMPANY),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>	
			<AuthRoute
				path='/company/employee/comparison/:uid'
				exact
				ComponentNav={CompanyIndividualComparison}
				requiredRoles={
					[
						String(UserRoles.COMPANY),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
			<AuthRoute
				path='/company/employee/development/:uid'
				exact
				ComponentNav={CompanyIndividualDevelopment}
				requiredRoles={
					[
						String(UserRoles.COMPANY),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
			<AuthRoute
				path='/company/requirementprofiles/profile/:uid'
				exact
				ComponentNav={EmployeeRequirementProfileNew}
				requiredRoles={
					[
						String(UserRoles.COMPANY),
						String(UserRoles.COMPANY_AND_RECRUITING)
					]
				}
			/>
			{/* Luksit Admin Routes */}
			<AuthRoute
				path='/luksit/dashboard'
				exact
				ComponentNav={AdminDashboard}
				requiredRoles={userRole.luksitAdmins}
			/>
			<AuthRoute
				path='/luksit/accounting'
				exact
				ComponentNav={AccountingDashboard}
				requiredRoles={userRole.luksitAdmins}
			/>
			<Route path="*" exact component={SiteNotFoundScreen} />
			{/* ROUTES THAT ARE NOT YET DEFINED OF FIXED */}
			{/* <Route path="/error" exact component={ErrorComponent}/> */}
			{/* <Route path="/offer" exact component={OfferScreen}/> */}
		</Switch>
	)

}

export default App
