import React from 'react'
import Text from "../textStyling/Text";
import H4 from "../textStyling/H4";
import {useTranslation} from "react-i18next";
import H3 from "../textStyling/H3";

export interface Props {
    state: 'desktop' | 'tablet' | 'mobile'
}

const BodyHeader: React.FC<Props> = ({state}) => {

    const {t} = useTranslation('companyRegistration')

    return (state === 'mobile' || state === 'tablet' ?
            <>
                {/*Only for small devices*/}
                <div className="lg:hidden xl:hidden">
                    <div className="flex flex-row">
                        <div className="flex justify-start mr-4 mt-1px">
                            <Text color="text-uw-grey" small>1.</Text>
                        </div>
                        <div className="flex flex-col justify-center pr-2">
                            <H4 color="uw-teal">{t('texts.registration')}</H4>
                            <div className="mb-2"/>
                            <Text color="text-uw-grey" small>{t('texts.registrationExplanation')}</Text>
                        </div>
                    </div>
                    <div className="flex flex-row  mb-4 mt-6">
                        <div className="flex justify-start mr-4 mt-1px">
                            <Text color="text-uw-grey" small>2.</Text>
                        </div>
                        <div className="flex flex-col justify-center">
                            <H4 color="uw-teal">{t('texts.payment')}</H4>
                            <div className="mb-2"/>
                            <Text color="text-uw-grey" small>{t('texts.paymentExplanation')}</Text>
                        </div>
                    </div>
                    <div className="relative z-10 flex flex-row mt-6">
                        <div className="flex justify-start mr-4 mt-1px">
                            <Text color="text-uw-grey" small>3.</Text>
                        </div>
                        <div className="flex flex-col justify-center">
                            <H4 color="uw-teal">{t('texts.access')}</H4>
                            <div className="mb-2"/>
                            <Text color="text-uw-grey" small>{t('texts.accessExplanation')}</Text>
                        </div>
                    </div>
                </div>
            </>
            :
            <>
                <div className="hidden lg:block xl:block">
                    <div className="relative z-10">
                        <div className="flex flex-row lg:mb-6 xl:mb-12">
                            <div className="flex flex-col items-center">
                                <div
                                    className="bg-uw-desktopCircleTeal rounded-full h-8 w-8 flex items-center justify-center mb-8">
                                    <div>
                                        <Text color="text-uw-teal" four>
                                            1
                                        </Text>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <H3 color="uw-teal">
                                        {t('texts.registration')}
                                    </H3>
                                </div>
                                <div className="text-center w-280px">
                                    <Text color="text-uw-grey" three>
                                        {t('texts.registrationExplanation')}
                                    </Text>
                                </div>
                            </div>
                            <hr className="flex flex-1 justify-center border-05 bg-uw-teal border-uw-teal -mx-20 mt-4"/>
                            <div className="flex flex-col items-center">
                                <div className="bg-uw-desktopCircleTeal rounded-full h-8 w-8 flex items-center justify-center mb-8">
                                    <div>
                                        <Text color="text-uw-teal" four>
                                            2
                                        </Text>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <H3 color="uw-teal">
                                        {t('texts.payment')}
                                    </H3>
                                </div>
                                <div className="text-center w-280px">
                                    <Text color="text-uw-grey" three>
                                        {t('texts.paymentExplanation')}
                                    </Text>
                                </div>
                            </div>
                            <hr className="flex flex-1 justify-center border-05 bg-uw-teal border-uw-teal -mx-20 mt-4"/>
                            <div className="flex flex-col items-center">
                                <div className="bg-uw-desktopCircleTeal rounded-full h-8 w-8 flex items-center justify-center mb-8">
                                    <div>
                                        <Text color="text-uw-teal" four>
                                            3
                                        </Text>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <div className="">
                                        <H3 color="uw-teal">
                                            {t('texts.access')}
                                        </H3>
                                    </div>
                                </div>
                                <div className="text-center w-280px">
                                    <Text color="text-uw-grey" three>
                                        {t('texts.accessExplanation')}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
}

export default BodyHeader;