//React
import React, { useState, useEffect } from 'react'
//Hooks 
//Components, Icons and Props
import CustomMenu from '../layout/Menu/CustomMenu'
import Footer from "../layout/FooterNew"
import BreadcrumbNavigation from '../layout/Menu/BreadcrumbNavigation'
import { INavigation, INavigationMainItem, setMainMenuIdActive, MAIN_MENU_IDS } from "../layout/Menu/CustomMenu"
import RequirementProfileCompetenciesActive from "../layout/RequirementProfileCompetenciesActive"
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import useCollapse from 'react-collapsed'
import LoadingScreenNew from "../../components/layout/LoadingScreenNew"
import { UseGetIscoProfiles } from '../../hooks/useGetIscoProfiles'
import IscoProfileItems from "../layout/IscoProfileDropdown"
//useTranslation
import { useTranslation } from "react-i18next"
//Firebase
import { useUserContext } from '../../firebase/FirebaseContext'
import { transformToDataObject, ICompetenceData } from '../../helpers/transform'
import { useSaveJob, IJob, IUserData, EJobStatus } from '../../hooks/useSaveJob'
import { useFormFieldState } from '../../hooks/useFormFieldState'
import { useHistory } from "react-router-dom"
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { Timestamp } from 'firebase/firestore'


const CompanyRequirementJobNewView: React.FC<INavigation> = ({ mainNavItems, metaNavItems, userItems }) => {

  setMainMenuIdActive(mainNavItems, MAIN_MENU_IDS.DASHBOARD_RECRUITING)

  const { t, i18n } = useTranslation(['companyRequirementJobs']);
  const { resultLocalesStandard } = useUserContext()
  const standardLanguage = i18n.language.includes('de') ? 'standardDE' : 'standardEN'

  const breadCrumbHome = {
  
    name: 'home',
    href: '/recruiting/dashboard',
    current: false
  }

  const breadCrumbPaths: INavigationMainItem[] = [
    {
      name: t('newProfile.headlineBreadcrumbTwo'),
      href: '/company/requirementprofiles/createjob',
      current: true
    }
  ]

  let history = useHistory()

  const [userCompetenceData, setUserCompetenceData] = useState<IUserData>({})
  const [iscoProfileId, setIscoProfileId] = useState()
  const [profiles, setProfiles] = useState<any>()
  const [profileTemplate, setProfileTemplate] = useState('')
  const [profileName, setProfileName]               = useFormFieldState('')
  const [profileDescription, setProfileDescription] = useFormFieldState('')
  const [profileNameError, setProfileNameError] = useState(false)
  const [profileDescriptionError, setProfileDescriptionError] = useState(false)
  const [buttonYesActive, setButtonYesActive] = useState(false);
  const [buttonNoActive, setButtonNoActive] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [privacyError, setPrivacyError] = useState(false)
  const [privacyLink, setPrivacyLink] = useFormFieldState('')
  const [privacyLinkError, setPrivacyLinkError] = useState(false)

  const [filteredIscoProfiles, setFilteredIscoProfiles] = useState(profiles);

  const handleSearch = (event: any) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    result = profiles.filter((data:any) => {
      return data.name.toLowerCase().search(value) !== -1;
    });
    setFilteredIscoProfiles(result);
  }

  useEffect(() => {
    if(profiles) {
      let template = profiles.find((element:any) => element.id === iscoProfileId)
      let tempUserCompetenceData: IUserData = template?.data
      setUserCompetenceData(Object.assign({}, tempUserCompetenceData))
      
      /*
      if(template.id === 'EMPTY_PROFILE') {
        setProfileName('')
        setProfileDescription('')
      }
      else {
      */
      if(template.id !== 'EMPTY_PROFILE') {
        setProfileTemplate(template?.name)
        //setProfileName(template?.name)
      } else {
        setProfileTemplate('')
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[iscoProfileId])

  useEffect(() => {
    const requestProfile = async () => {
      try {
        const getIscoProfiles = UseGetIscoProfiles()
        const iscoLang = i18n.language.includes('de') ? 'de' : 'en'
        //console.log(iscoLang)
        let profiles = await getIscoProfiles(iscoLang)
        if(profiles) {
          profiles[0].name = t('general.truncateTemplate');
          profiles[0].description = t('general.truncateTemplateDescription');
          setProfiles(profiles)
          setFilteredIscoProfiles(profiles)
          //console.log(profiles)
        }
      } catch (err) {
        console.log(err)
      }
    }
    requestProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleCompetenceOnChange = (event: any) => {
    event.preventDefault()
    if(userCompetenceData[event.target.title] && userCompetenceData[event.target.title] === event.target.id) {
      let tempUserCompetenceData: IUserData = userCompetenceData;
      delete tempUserCompetenceData[event.target.title]
      setUserCompetenceData(Object.assign({}, tempUserCompetenceData))
    } else {
      let tempUserCompetenceData: IUserData = userCompetenceData;
      tempUserCompetenceData![event.target.title] = event.target.id;
      setUserCompetenceData(Object.assign({}, tempUserCompetenceData))
      //console.log('competenceData', userCompetenceData, tempUserCompetenceData)
      //console.log(userCompetenceData);
    }
  }

  const handleButtonYesClick = (button: any) => {
    setButtonYesActive(true)
    setPrivacy(true)
    setButtonNoActive(false)
  }

  const handleButtonNoClick = (button: any) => {
    setButtonNoActive(true)
    setPrivacy(false)
    setButtonYesActive(false)
  }

  // Toggle Reminder
  const saveJob = useSaveJob()
  const handleSaveProfile = async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()

      if(profileName !== '') {
        setProfileNameError(false)
      } else {
        setProfileNameError(true)
      }

      if(profileDescription !== '') {
        setProfileDescriptionError(false)
      } else {
        setProfileDescriptionError(true)
      }

      if (profileName === "" || profileDescription === "") {
        window.scrollTo({top: 0, behavior: 'smooth'});
        return
      }

      if(buttonYesActive === true || buttonNoActive === true) {
        setPrivacyError(false)
      } else {
        setPrivacyError(true)
        return
      }

      if(buttonYesActive === true && privacyLink !== '' && (String(privacyLink).substring(0,7) === 'http://' || String(privacyLink).substring(0,8) === 'https://')) {
        setPrivacyLinkError(false)
      } else {
        if(buttonNoActive === true) {
          setPrivacyLinkError(false)
        } else {
          setPrivacyLinkError(true)
          return
        }
      }

      

      let profile: IJob = {
        //company: 'company/'+companyUser!.companyId,
        template: profileTemplate,
        name: profileName,
        description: profileDescription,
        data: userCompetenceData,
        updated: Date.now(),
        status: [{date: new Timestamp(Math.floor(Number(Date.now()) / 1000), 0), status: EJobStatus.DRAFT}],
        privacy: privacy,
        privacyLink: privacyLink
      }
      try {
          await saveJob(profile)
          history.push('/recruiting/dashboard')
      } catch (err) {
          console.log(err)
          return
      }
  }


  const [ isExpanded, setExpanded ] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
  function handleOnClick() {
      setExpanded(!isExpanded);
  }

  let competenceData: ICompetenceData | null = null;

  if(resultLocalesStandard) {
    competenceData = transformToDataObject(resultLocalesStandard[standardLanguage])
    //console.log(competenceData)
  }

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }
 
  return competenceData ? (
    <>
      <CustomMenu mainNavItems={mainNavItems} metaNavItems={metaNavItems} userItems={userItems} />
      <div className="py-10">
        <header className="mt-16">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="pt-2 pb-4">
              <BreadcrumbNavigation homePath={breadCrumbHome} currentPaths={breadCrumbPaths}></BreadcrumbNavigation>
            </div>
            <h1 className="lg:text-3xl text-2xl font-bold leading-tight text-uw-teal pb-2">{t('newProfile.headline')}</h1>
          </div> 
        </header>
        <main>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

            {/* Input Felder*/}
            <div className="flex grid grid-cols-2 md:flex-row justify-between mb-2 lg:mb-8">
              
              <div className='w-5/6'>
                <div className="mt-6">
                  <label htmlFor="name" className="block text-md font-medium text-gray-600">
                  {t('newProfile.labelName')}
                  </label>
                  <div className="mt-2 border-b border-gray-300 focus-within:border-uw-teal">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={profileName}
                      onChange={setProfileName}
                      className="block w-full border-0 border-b text-uw-teal border-transparent focus:border-uw-teal focus:ring-0 sm:text-md text-xl"
                    />
                  </div>
                  <div className={(profileNameError === true ? 'absolute' : 'absolute hidden')}>
                    <ExclamationCircleIcon className="h-5 w-5 mt-4 text-red-500" aria-hidden="true" />
                  </div>
                  <div>
                    <p className={(profileNameError === true ? 'mt-4 ml-6 text-sm text-red-600' : 'text-sm mt-4 ml-6 text-red-600 hidden')} id="surname-error">
                      {t('errors.profileName')}
                    </p>
                  </div>
                </div>

                <div className="mt-6">
                  <label htmlFor="description" className="block text-md font-medium text-gray-600">
                  {t('newProfile.labelDescription')}
                  </label>
                  <div className="mt-2 border-b border-gray-300 focus-within:border-uw-teal">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      onChange={setProfileDescription}
                      value={profileDescription}
                      className="block w-full border-0 border-b text-uw-teal border-transparent focus:border-uw-teal focus:ring-0 sm:text-md text-xl"
                    />
                  </div>
                  <div className={(profileDescriptionError === true ? 'absolute' : 'absolute hidden')}>
                    <ExclamationCircleIcon className="h-5 w-5 mt-4 text-red-500" aria-hidden="true" />
                  </div>
                  <div>
                    <p className={(profileDescriptionError === true ? 'text-sm mt-4 ml-6 text-red-600' : 'text-sm mt-4 ml-6 text-red-600 hidden')} id="surname-error">
                      {t('errors.profileDescription')}
                    </p>
                  </div>
                </div>
              </div>
            



              <div className=''>
                <div className="mt-6 pr-8">
                <label htmlFor="description" className="block text-md font-medium text-gray-600">
                  {t('newProfile.labelTemplate')}
                  </label>
                  <div className="mt-6">
                    <IscoProfileItems profiles={filteredIscoProfiles} setIscoProfileId={setIscoProfileId} handleSearch={handleSearch}></IscoProfileItems>
                  </div>
                    
                </div>

                {/* Profiltemplates Beschreibungen
                <div className="mt-6">
                    <p className=" text-sm font-small text-gray-600">
                    Festlegung und Formulierung von Richtlinien und Erteilung der Gesamtausrichtung von Unternehmen oder Organisationen des privaten und öffentlichen Sektors im Rahmen von Richtlinien, die von einem Vorstand oder einem ähnlichen Leitungsgremium aufgestellt werden. Planen, leiten oder koordinieren Sie die operativen Aktivitäten auf der höchsten Führungsebene mit Hilfe der untergeordneten Führungskräfte und Personalchefs.
                    </p>
                  </div> */}
                </div>
                </div>

          


            <div className="py-4 sm:py-5 flex items-center">
              <h2 className="text-2xl font-medium text-uw-teal">{t('newProfile.headlineTwo')}</h2>
              <div {...getToggleProps({onClick: handleOnClick})} className="flex justify-start pl-2">
                <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </div>
            <div {...getCollapseProps()}>
              <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3">
                <div className="flex text-md text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">
                    {t('newProfile.infoText')}
                  </span>            
                </div>    
              </div>
            </div>
             
            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[2].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[25]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[2]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[35]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[5].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[19]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[6]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[38]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[29]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[7].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[27]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[20]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[3].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[9]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[3]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[37]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[31]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[26]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[21]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[17]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[13]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[15]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[4].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[4]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[36]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[5]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[30]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[23]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[34]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[8].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[10]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[33]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[28]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[24]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[14]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[12]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[6].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[7]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[39]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[8]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="pb-2 mb-4 pt-4">
              <h3 className="text-lg leading-6 font-medium text-uw-teal">{competenceData.category[1].name}</h3>
            </div>
              <RequirementProfileCompetenciesActive data={competenceData.trait[1]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[32]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[22]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[18]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[16]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
              <RequirementProfileCompetenciesActive data={competenceData.trait[11]} state={userCompetenceData} onClick={handleCompetenceOnChange} />
            <div className='pb-5 border-b border-gray-200 mb-4'></div>

          
            {/* Input Privacy Policy*/}
            <div>
              <div className="px-4 py-5 sm:p-6">
                <h3 className="mt-2 text-lg font-medium text-gray-600">{t('newProfile.privacyHeadline')}</h3>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                  <p>{t('newProfile.privacyInfoText')}</p>
                </div>
                <form className="mt-5 sm:flex flex-col">
                  <div>
                    <button
                      type="button"
                      onClick={handleButtonYesClick}
                      className={classNames(
                        buttonYesActive
                          ? 'bg-uw-teal text-white'
                          : 'bg-white text-gray-600',
                        'mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent px-4 py-2 font-medium shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-uw-teal focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                      )}
                    >
                      {t('newProfile.privacyInputYes')}
                    </button>
                    <button
                      type="button"
                      onClick={handleButtonNoClick}
                      className={classNames(
                        buttonNoActive
                          ? 'bg-uw-teal text-white'
                          : 'bg-white text-gray-600',
                        'mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent px-4 py-2 font-medium shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-uw-teal focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                      )}
                    >
                      {t('newProfile.privacyInputNo')}
                    </button>
                  </div>
                  
                  <div>
                    <div className={(privacyError === true ? 'absolute' : 'absolute hidden')}>
                      <ExclamationCircleIcon className="h-5 w-5 mt-4 text-red-500" aria-hidden="true" />
                    </div>
                    <p className={(privacyError === true ? 'mt-4 ml-6 text-sm text-red-600' : 'text-sm mt-4 ml-6 text-red-600 hidden')} id="privacy-error">
                      {t('errors.profilePrivacy')}
                    </p>
                  </div>

                  <div 
                  className={classNames(
                    buttonYesActive
                      ? ''
                      : 'hidden',
                    'flex grid grid-cols-2 md:flex-row justify-between lg:mb-6'
                  )}
                  >
                      <div className='w-5/6'>
                        <div className="mt-6">
                          <label htmlFor="url" className="block text-sm font-medium text-gray-600">
                          {t('newProfile.labelPrivacy')}
                          </label>
                          <div className="mt-4 border-b border-gray-300 focus-within:border-uw-teal">
                            <input
                              type="url"
                              name="url"
                              id="url"
                              value={privacyLink}
                              onChange={setPrivacyLink}
                              className="block w-full border-0 border-b text-uw-teal border-transparent focus:border-uw-teal focus:ring-0 sm:text-md text-xl"
                              placeholder="https://www.website.de/privacypolicy"
                            />
                          </div>
                          <div className={(privacyLinkError === true ? 'absolute' : 'absolute hidden')}>
                            <ExclamationCircleIcon className="h-5 w-5 mt-4 text-red-500" aria-hidden="true" />
                          </div>
                          <div>
                            <p className={(privacyLinkError === true ? 'mt-4 ml-6 text-sm text-red-600' : 'text-sm mt-4 ml-6 text-red-600 hidden')} id="privacyLink-error">
                              {t('errors.profilePrivacyInput')}
                            </p>
                          </div>
                        </div>
                      </div>  
                  </div>
                </form>
              </div>
            </div>

             <div className='pb-5 border-b border-gray-200 mb-4'></div>

            <div className="flex justify-end pt-8"> 
              <button
                type="button"
                className="items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-uw-teal hover:bg-gray-600 focus:outline-none"
                onClick={handleSaveProfile}
              >
                {t('newProfile.buttonSaveProfile')}
              </button>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  ) : (<><LoadingScreenNew /></>)
}

export default CompanyRequirementJobNewView