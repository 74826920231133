import React from 'react'
import ErrorComponent from "../components/layout/ErrorComponent";
import {useTranslation} from "react-i18next";

const SiteNotFoundScreenDS = () => {

    const { t } = useTranslation(['siteNotFound', 'basics'])

    return (
        <>
            <ErrorComponent buttonLink={["/company/faq","/"]} buttonTitle={[t('errorButtonTextDS'), t('basics:buttons.startButton')]} errorMsg={t('errorMsgDS')} headerFooter header={t('errorHeaderDS')}/>
        </>
    )
}

export default SiteNotFoundScreenDS