// Firebase
//import { useUserContext } from "../firebase/FirebaseContext";
import { firestore } from "../firebase/Firebase"
//import { useState } from "react"

import { getDoc, doc } from "firebase/firestore";

import { EJobStatus } from "./useSaveJob";

import { useUserContext } from "../firebase/FirebaseContext"

export interface IUserData {
  [index: string]: number
}

export enum EJobAction {
  EDIT = "EDIT",
  REGISTER = "REGISTER",
  LIST = "LIST",
  NOT_SPECIFIED = "NOT_SPECIFIED",
}

export interface IJob {
  template?: string,
  id: string,
  company?: any,
  name: string,
  description: string,
  data: IUserData,
  status?: string,
  statusArr: any[],
  companyPath?: string,
  privacy?: boolean,
  privacyLink?: string
}


export const UseGetJob = (): any => {

  const {companyUser} = useUserContext()

  const getJobs = async (jobId: string, testGroupId: string | false = false, action: EJobAction = EJobAction.NOT_SPECIFIED) => {

    const docRef = doc(firestore, "jobs", jobId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {

      if(testGroupId === false && String(docSnap.data().company.path) !== String('companies/'+companyUser!.companyId)) {
        //console.log('11111')
        return false
      }

      //console.log(jobId, testGroupId, docSnap.data().testGroup.path)

      if(testGroupId !== false && String(docSnap.data().testGroup.path) !== String('testGroups/'+testGroupId)) {
        //console.log('222222')
        return false
      }

      let status = EJobStatus.DRAFT
      let statusArr: any = []

      if(docSnap.data().status && Array.isArray(docSnap.data().status)) {
        status = docSnap.data().status[docSnap.data().status.length-1].status
        if(action !== EJobAction.LIST && testGroupId === false && status !== EJobStatus.DRAFT){
          //console.log('333333')
          return false
        }

        if(testGroupId !== false && status !== EJobStatus.PUBLISHED){
          //console.log('44444')
          return false
        }
          
        statusArr = docSnap.data().status
      }

      let profile: IJob = {
        template: docSnap.data().template,
        id: docSnap.id,
        name: docSnap.data().name,
        description: docSnap.data().description,
        data: docSnap.data().data,
        status: status,
        statusArr: statusArr,
        companyPath: docSnap.data().company.path,
        privacy: docSnap.data().privacy,
        privacyLink: docSnap.data().privacyLink
      }

      return profile

    }

    return false;

  }

  return getJobs
}
