// React
import React from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from "react-i18next"
// Components
import Container from "../components/layout/Container"
import H1 from "../components/textStyling/H1"
import Text from "../components/textStyling/Text"
import Button from "../components/layout/Button"
import IconRight from "../components/design/IconRight"
import IconWrong from "../components/design/IconWrong"
import H2 from "../components/textStyling/H2"

const TestIntroScreen: React.FC = () => {

  window.scrollTo({top: 0, behavior: 'smooth'});

  const history = useHistory()
  const { i18n, t } = useTranslation('testIntro')


  const bodyContentFirst = (
    <>
      <div className="lg:mt-32 xl:mt-32 my-16">
        <div className="lg:flex xl:flex lg:flex-row xl:flex-row lg:justify-between xl:justify-between">
          <div className="lg:w-1/3 xl:w-1/3 lg:mr-8 xl:mr-8 mb-8 lg:mb-0 xl:mb-0">
            <H1 color="uw-teal">{t('headline')}</H1>
          </div>
          <div className="hidden lg:block xl:block lg:max-w-2xl xl:max-w-2xl">
            <Text color="text-uw-grey" one>{t('testInformation')}</Text>
          </div>
          <div className="lg:hidden xl:hidden">
            <Text color="text-uw-grey">{t('testInformation')}</Text>
          </div>
        </div>
      </div>
      <div
        className="lg:flex xl:flex lg:flex-row xl:flex-row lg:justify-between xl:justify-between">
        <div className="mb-8 mb-8 lg:mb-0 xl:mb-0 lg:mr-16 xl:mr-16 lg:w-1/3 xl:w-1/3">
          <H2 color="uw-teal">
            {t('secondHeadline')}
          </H2>
        </div>
        <div className="lg:flex xl:flex lg:flex-col xl:flex-col">
          <div className="hidden lg:block xl:block lg:mb-16 xl:mb-16 lg:max-w-2xl xl:max-w-2xl">
            <Text color="text-uw-grey" three>
              {t('secondTestInformation')}
            </Text>
          </div>
          <div className="mb-8 lg:hidden xl:hidden">
            <Text color="text-uw-grey">
              {t('secondTestInformation')}
            </Text>
          </div>
          <div className="relative z-10 flex flex-row justify-center mt-8 lg:mt-0 xl:mt-0">
            <div className="flex flex-col mr-4 lg:mr-8 xl:mr-8 lg:mt-2 xl:mt-2 mt-1px lg:mr-7 xl:mr-7">
              <div className="lg:scale-1.5 xl:lg:scale-1.5">
                <IconRight />
              </div>
              <div className="mb-6" />
              <div className="lg:scale-1.5 xl:lg:scale-1.5 lg:mt-3 xl:mt-3 lg:mb-12 xl:lg:mb-12">
                <IconWrong />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="hidden lg:block xl:block">
                <Text color="text-uw-grey" three>{t('mostLikeMe')}</Text>
                <div className="mb-4" />
                <Text color="text-uw-grey" three>{t('leastLikeMe')}</Text>
              </div>
              <div className="lg:hidden xl:hidden">
                <Text color="text-uw-grey">{t('mostLikeMe')}</Text>
                <div className="mb-4" />
                <Text color="text-uw-grey">{t('leastLikeMe')}</Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const bodyContentGreenBackground = (
    <>
      {/*Desktop*/}
      <div className="hidden lg:block xl:block">
        <div className="mb-2 lg:mt-12 xl:mt-12 lg:mb-8 xl:mb-8">
          <H2 color="white">
            {t('exampleText')}
          </H2>
        </div>
        <div className="lg:mx-16 xl:mx-16">
          <div className="flex flex-row justify-end mb-6 lg:mb-12 xl:mb-12">
            <div className="mr-6 lg:scale-1.5 xl:scale-1.5 lg:mr-15 xl:mr-15">
              <IconRight />
            </div>
            <div className="mr-leastMe lg:scale-1.5 xl:scale-1.5 lg:mr-0.85rem xl:mr-0.85rem">
              <IconWrong />
            </div>
          </div>
          <div className="flex flex-row justify-between mb-8 items-center">
            <div className="items-center">
              <Text color="text-white" two>{t('exampleQuestion1')}</Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="border-3.5 border-white rounded-lg w-12 h-12 mr-3 lg:mr-8 xl:mr-8" />
              <div className="border-3.5 border-white rounded-lg w-12 h-12" />
            </div>
          </div>
          <div className="flex flex-row justify-between mb-8 items-center">
            <div className="items-center">
              <Text color="text-white" two>{t('exampleQuestion2')}</Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="border-3.5 border-white rounded-lg w-12 h-12 mr-3 lg:mr-8 xl:mr-8" />
              <div className="border-3.5 border-white rounded-lg w-12 h-12" />
            </div>
          </div>
          <div className="flex flex-row justify-between mb-8 items-center">
            <div className="items-center">
              <Text color="text-white" two>{t('exampleQuestion3')}</Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="border-3.5 border-white rounded-lg w-12 h-12 mr-3 lg:mr-8 xl:mr-8" />
              <div className="bg-uw-red border-3.5 border-white rounded-lg w-12 h-12" />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center mb-12">
            <div className="items-center">
              <Text color="text-white" two>{t('exampleQuestion4')}</Text>
            </div>
            <div className="flex flex-row items-center">
              <div
                className="bg-uw-green border-3.5 border-white rounded-lg w-12 h-12 mr-3 lg:mr-8 xl:mr-8" />
              <div className="border-3.5 border-white rounded-lg w-12 h-12" />
            </div>
          </div>
        </div>
      </div>
      {/*Mobile*/}
      <div className="lg:hidden xl:hidden">
        <div className="mb-2">
          <H2 color="white">
            {t('exampleText')}
          </H2>
        </div>
        <div className="">
          <div className="flex flex-row justify-end mb-6">
            <div className="mr-6 lg:mr-15 xl:mr-15">
              <IconRight />
            </div>
            <div className="mr-leastMe">
              <IconWrong />
            </div>
          </div>
          <div className="flex flex-row justify-between mb-8 items-center">
            <div className="items-center">
              <Text color="text-white" >{t('exampleQuestion1')}</Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="border-2 border-white rounded-lg w-8 h-8 mr-3" />
              <div className="border-2 border-white rounded-lg w-8 h-8" />
            </div>
          </div>
          <div className="flex flex-row justify-between mb-8 items-center">
            <div className="items-center">
              <Text color="text-white" >{t('exampleQuestion2')}</Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="border-2 border-white rounded-lg w-8 h-8 mr-3" />
              <div className="border-2 border-white rounded-lg w-8 h-8" />
            </div>
          </div>
          <div className="flex flex-row justify-between mb-8 items-center">
            <div className="items-center">
              <Text color="text-white" >{t('exampleQuestion3')}</Text>
            </div>
            <div className="flex flex-row items-center">
              <div className="border-2 border-white rounded-lg w-8 h-8 mr-3" />
              <div className="bg-uw-red border-2 border-white rounded-lg w-8 h-8" />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <div className="items-center">
              <Text color="text-white" >{t('exampleQuestion4')}</Text>
            </div>
            <div className="flex flex-row items-center">
              <div
                className="bg-uw-green border-2 border-white rounded-lg w-8 h-8 mr-3" />
              <div className="border-2 border-white rounded-lg w-8 h-8" />
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const bodyContentSecond = (
    <>
      {/*Mobile*/}
      <div className="lg:hidden xl:hidden">
        <div className="relative z-10 mb-8">
          <Text color="text-uw-grey">
            {t('finalTestText')}
          </Text>
        </div>
        <div className="mb-12">
          <Button backgroundWhite={false} borderColor={false}
            onClick={() => history.push('/employee/test')}
            title={t("buttonTitle")} type="button" />
        </div>
      </div>
      {/*Desktop*/}
      <div className="hidden lg:block xl:block">
        <div className="relative z-10 mt-12">
          <div className="flex flex-row justify-between mb-24">
            <div className="w-1/2 mr-24 h-full">

              {i18n.language.includes('de') ?
                <Text color="text-uw-grey" three>
                  {t('finalTestText').substr(0, 336)}
                </Text> :
                <Text color="text-uw-grey" three>
                  {t('finalTestText').substr(0, 413)}
                </Text>
              }
            </div>
            <div className="flex flex-col w-1/2 h-full">
              {i18n.language.includes('de') ?
                <Text color="text-uw-grey" three>
                  {t('finalTestText').substr(336, 185)}
                </Text> :
                <Text color="text-uw-grey" three>
                  {t('finalTestText').substr(413, 222)}
                </Text>
              }
              <div className="mt-12 max-w-sm">
                <Button backgroundWhite={false} borderColor={false}
                  className="lg:py-3 lg:px-16 xl:py-3 xl:px-16"
                  onClick={() => history.push('/employee/test')}
                  title={t("buttonTitle")} type="button" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <>
      <Container
        background="white"
        bodyContentFirst={bodyContentFirst}
        bodyContentGreenBackground={bodyContentGreenBackground}
        bodyContentSecond={bodyContentSecond}
        greenBackground
        headerFooter
        logout
      />
    </>
  )
}

export default TestIntroScreen