import React from 'react'
import classnames from 'classnames'

interface style {
    className: string
}

interface IProps {
    color: "text-uw-grey" | "text-white" | "text-uw-teal" | "text-uw-errorRedText" | "text-white"
    fat?: boolean
    four?: boolean
    headlines?: boolean
    one?: boolean
    opacity?: boolean
    semiBold?: boolean
    small?: boolean
    style?: style | undefined
    three?: boolean
    two?: boolean
    underlined?: boolean
}

const Text: React.FC<IProps> = ({children, color, fat, four, headlines, one, opacity, semiBold, small, style, three, two, underlined}) => {

    const styles = style !== undefined ? style.className : ""

    const fontFamily = headlines ? "font-headlines" : "font-text"

    return <p className={classnames(color, styles, opacity && "opacity-85", fontFamily,
        {
            "leading-textMobile font-normal text-base" : !fat && !small
        },
        {
            "font-normal underline leading-button text-textSmallMobile" : small && underlined
        },
        {
            "font-normal leading-button text-textSmallMobile" : small
        },
        {
            "font-medium leading-textFatMobile text-base" : fat
        },
        {
            "font-normal leading-textOneTwoDesktop text-textOneTwoDesktop" : one
        },
        {
            "font-medium font-normal leading-textOneTwoDesktop text-textOneTwoDesktop" : two
        },
        {
            "font-normal sm:text-base lg:leading-textThreeDesktop lg:text-textThreeDesktop" : three
        },
        {
            "font-normal leading-textFourDesktop text-textFourDesktop" : four
        },
        {
            "underline": underlined
        },
        {
            "font-semibold" : semiBold
        }
    )}>{children}</p>

}

export default Text;

