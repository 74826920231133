import React from 'react'
import TestQuestion from './TestQuestion';
import {QuestionState} from '../../screens/TestScreen';
import IconRight from "../design/IconRight";
import IconWrong from "../design/IconWrong";

interface Props {
    questions: { question: string, id: string }[];
    questionState: QuestionState;
    setQuestionState: React.Dispatch<React.SetStateAction<QuestionState>>;
}

const TestBlock: React.FC<Props> = ({questions, questionState, setQuestionState}) => {
    const ids = questions.map(x => x.id);
    const handleChange = (id: string, value: 1 | -1) => {
        const antiValue = value === 1 ? -1 : 1;
        const values = ids.reduce((a: any, x) => {
            if (questionState[x] !== antiValue) {
                a[x] = 0;
            }
            return a;
        }, {})
        setQuestionState((state) => ({...state, ...values, [id]: value}))
    }
    return (
        <>
            {/*Mobile*/}
            <div className="lg:hidden xl:hidden">
                <div className="flex flex-row justify-end mb-3">
                    <div className="mr-6">
                        <IconRight/>
                    </div>
                    <div className="mr-leastMe">
                        <IconWrong/>
                    </div>
                </div>
                {questions.map((question, index) => <TestQuestion {...question} key={index} handleChange={handleChange}
                                                                  questionState={questionState}/>)}
            </div>
            {/*Desktop*/}
            <div className="hidden lg:flex xl:flex lg:flex-col xl:flex-col">
                <div className="flex flex-row justify-end mb-8 lg:mb-12 xl:mb-12">
                    <div className="mr-11 scale-1.5 lg:mr-15 xl:mr-15">
                        <IconRight/>
                    </div>
                    <div className="mr-leastMe scale-1.5 lg:mr-0.85rem xl:mr-0.85rem">
                        <IconWrong/>
                    </div>
                </div>
                {questions.map((question, index) => <TestQuestion {...question} key={index} handleChange={handleChange}
                                                                  questionState={questionState}/>)}
            </div>
        </>
    )
}

export default TestBlock;