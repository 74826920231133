import useWindowSize from '@react-hook/window-size'
import {useEffect, useState} from "react";

export const useCheckWindowWidth = () => {
    
    const width = useWindowSize()
    const [widthState, setWidthState] = useState<'desktop' | 'mobile' | 'tablet'  >('mobile')

    useEffect(() => {
        if(width[0]  >= 1024) setWidthState('desktop')
        else if(width[0] < 768) setWidthState('mobile')
        else setWidthState('tablet')
    }, [width])
    return widthState
}