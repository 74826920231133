//React
import { useState, useEffect } from "react"
//Firebase
import { collection, getDocs, query, where } from "firebase/firestore"
import { firestore } from "../firebase/Firebase"
import { useUserContext } from "../firebase/FirebaseContext"
import { UseGetJob, IJob } from "../hooks/useGetJob"

export interface IJobStats {
  name: string,
  countStats: {
    ALL: number,
    INTERVIEW: number,
    OFFER: number,
    HIRED: number,
    FIRED: number,
    NOTHING: number
  } | any
}

export enum EStatus {
  ALL = "ALL",
  NEW = "NEW",
  APPLIED = "APPLIED",
  INTERVIEW = "INTERVIEW",
  OFFER = "OFFER",
  HIRED = "HIRED",
  FIRED = "FIRED"
}

export const useGetJobStats = (jobId: string) => {
  const {companyUser} = useUserContext()
  const [results, setResults] = useState<IJobStats | null>(null)
  const getJob = UseGetJob()
  useEffect(() => {
    (async () => {
      
      
      const job: IJob = await getJob(jobId, false, "LIST")
      //console.log('#####JOB', job)

      let resultObj: IJobStats = {
        name: job.name,
        countStats: {
          ALL: 0,
          NEW: 0,
          INTERVIEW: 0,
          OFFER: 0,
          HIRED: 0,
          FIRED: 0,
          NOTHING: 0
        }
      }
      
      
      const q = query(collection(firestore, 'employees'), where('testGroups', '==', companyUser!.testGroups), where("jobs."+jobId+".jobId", "==", jobId), where("test.receivedResults", "==", true))
      const allCandidatesForJob = await getDocs(q)
      //console.log('allCandidatesForJob', allCandidatesForJob)

      allCandidatesForJob.forEach((doc) => {
        resultObj.countStats.ALL++
        if(doc.data().jobs && doc.data().jobs[jobId] && doc.data().jobs[jobId].status && doc.data().jobs[jobId].status[0]) {
          let status = doc.data().jobs[jobId].status[doc.data().jobs[jobId].status.length-1].status
          switch (status) {
            case EStatus.NEW:
              resultObj.countStats.NEW++
              break;
            case EStatus.INTERVIEW:
              resultObj.countStats.INTERVIEW++
              break;
            case EStatus.OFFER:
              resultObj.countStats.OFFER++
              break;
            case EStatus.HIRED:
              resultObj.countStats.HIRED++
              break;
            case EStatus.FIRED:
              resultObj.countStats.FIRED++
              break;
          }
        }
        //console.log(doc.data().firstName)
      })
      
      setResults(resultObj);

    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyUser, jobId])
  return results;
}