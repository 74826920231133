import { firestore } from "../firebase/Firebase"
import { doc, updateDoc, getDoc, Timestamp, DocumentReference } from 'firebase/firestore'
import { EStatus } from "./useGetProfiles";

export interface IUserData {
  [index: string]: number
}



interface IProfileStatus {
  date: number | Timestamp,
  status: EStatus
}

export interface IProfile {
  [index: string]: {
      profileRef: DocumentReference,
      date: number | Timestamp,
      profileId: string,
      status: IProfileStatus[]
  }
}


export const useChangeEmployeeStatus = () => {

  const changeEmployeeStatus =  async (employeeId : string, employeeStatus: IProfileStatus, profileId: string) => {

    try {

      const docRef = doc(firestore, `employees`, employeeId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {

        let profiles: IProfile = docSnap.data().profiles

        if(typeof profiles === 'undefined') {
          profiles = {}
          profiles[profileId] = {
              profileRef: doc(firestore, 'profiles', profileId),
              profileId: profileId,
              date: employeeStatus.date,
              status: [employeeStatus]
          }
          //console.log('no profiles exists')
        }
        else if(profiles && !profiles[profileId]) {
          profiles[profileId] = {
              profileRef: doc(firestore, 'profiles', profileId),
              profileId: profileId,
              date: employeeStatus.date,
              status: [employeeStatus]
          }
          //console.log('profile with id '+profileId+' does not exists')
        }
        else if(profiles && profiles[profileId] && Array.isArray(profiles[profileId].status)) {
          profiles[profileId].status.push(employeeStatus)
          //console.log('profile with id '+profileId+' exists, change status')
        } else {
          //console.log('do noting')
          return
        }

        await updateDoc(docRef, { profiles: profiles } );
        console.log(profiles);
      }

    } catch(error) {
      console.log(error)
    }

  }

  return changeEmployeeStatus
}
